<template>
  <div class="blog-list-wrapper">
    <div class="blogs-title">Credence {{ $t("blogs") }}</div>
    <div class="wrapper">
      <div class="categories-wrapper">
        <div class="categories-title">
          {{ $t("Categories") }}
          <h3 style="width: 75%; height: 5px; background-color: #26bbc2"></h3>
        </div>
        <div class="categories-list" @click="setCategory()">
          <span>All</span>
          <div class="line-22"></div>
        </div>
        <div
          class="categories-list"
          v-for="category in categories"
          :key="category"
          @click="setCategory(category)"
        >
          <span>{{ category }}</span>
          <div class="line-22"></div>
        </div>
      </div>
      <div class="list">
        <div
          class="blog-card"
          v-for="blog in blogs"
          :key="blog.id"
          @click="redirectToBlog(blog)"
        >
          <div class="blog-info-wrapper">
            <img
              :src="
                this.$root.getCDNlink(blog.bannerImage) ||
                '../../assets/blogBanner1.webp'
              "
              class="blog-image"
            />
            <div class="blog-description-wrapper">
              <div class="blog-category">{{ blog.category }}</div>
              <div class="blog-title">{{ blog.title }}</div>
              <p class="blog-description">{{ blog.summary }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import config from "@/config.js";
import { GetBlog } from "@/api/APIs";

export default {
  name: "BlogsList",
  props: ["category"],
  components: {},
  data() {
    return {
      blogs: [],
      categories: null,
      selectedCategory: "",
    };
  },
  mounted() {
    this.setCategoryFromUrl();
  },
  methods: {
    async getBlogsList() {
      const payload = {
        category: this.selectedCategory,
      };

      try {
        const response = await GetBlog(payload);
        if (response.message === "SUCCESS") {
          this.blogs = response.blog;
          this.categories = response.blogCategories;
        } else {
          return this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    setCategory(category) {
      this.selectedCategory = category || "";
      this.getBlogsList();
      if (category) {
        this.$router.replace({
          name: "blogs-list",
          params: { category: this.selectedCategory },
        });
      } else {
        this.$router.replace({
          name: "blogs-list",
        });
      }
    },
    setCategoryFromUrl() {
      const categoryFromUrl = this.$route.params.category || "";
      this.selectedCategory = categoryFromUrl;
      this.getBlogsList();
    },
    redirectToBlog(blog) {
      this.$router.push(`/blog/${blog.slug}/${blog.id}`);
    },
  },
};
</script>

<style scoped>
.blog-list-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  gap: 25px;
}
.wrapper {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: flex-start;
  justify-content: center;
}
.line-22 {
  border-width: 1px 0 0 0;
  border-style: solid;
  border-image: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(191 191 191) 49.50000047683716%,
    rgba(134, 134, 134, 0) 100%
  );
  border-image-slice: 1;
  flex-shrink: 0;
  width: 95%;
  height: 0px;
  position: relative;
}
.categories-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* gap: 20px; */
  width: 30%;
  /* border: 1px solid rgb(216, 216, 216); */
  border-radius: 10px;
  padding: 20px;
  background-color: white;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.18),
    -4px -4px 8px 0px rgba(255, 255, 255, 1);
  position: sticky;
  top: 110px;
}
.categories-title {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  font-family: "Montserrat-Light", sans-serif;
  color: #11365a;
  line-height: 0;
  margin-top: 10px;
}
.categories-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* gap: 15px; */
  width: 100%;
}
.categories-list span {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  font-family: "Montserrat-Light", sans-serif;
  line-height: 50px;
  cursor: pointer;
  align-self: stretch;
  padding-left: 10px;
  border-radius: 8px;
}
.categories-list span:hover {
  background: #c5d3e02f;
}
.blogs-title {
  text-align: left;
  font-size: 30px;
  font-weight: 600;
  font-family: "Montserrat-Light", sans-serif;
  color: #11365a;
}
.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 30px;
}
.blog-card {
  /* border: 1px solid rgb(228, 228, 228); */
  border-radius: 13px;
  padding: 20px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.18),
    -4px -4px 8px 0px rgba(255, 255, 255, 1);
  background-color: white;
  display: flex;
  align-self: stretch;
  cursor: pointer;
}
.blog-info-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
}
.blog-image-wrapper {
  width: 40%;
  height: 100%;
}
.blog-image {
  width: 30%;
  /* height: 100%; */
  aspect-ratio: 1/1;
  border-radius: 10px;
  object-fit: cover;
}
.blog-description-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  width: 70%;
}
.blog-category {
  font-size: 16px;
  font-weight: 600;
  font-family: "Montserrat-Light", sans-serif;
  text-align: left;
  color: #26bbc2;
}
.blog-title {
  font-size: 25px;
  font-weight: 600;
  font-family: "Montserrat-Light", sans-serif;
  text-align: left;
}
.blog-description {
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat-Light", sans-serif;
  text-align: justify;
}

@media only screen and (max-width: 767px) {
  .blogs-title {
    font-size: 16px;
  }
  .wrapper {
    flex-direction: column;
  }
  .categories-wrapper {
    width: 100%;
    order: 2;
    padding: 0;
  }
  .list {
    width: 100%;
    order: 1;
    gap: 15px;
  }
  .categories-title {
    margin: 2rem 0 0 2rem;
  }
  .categories-list {
    margin: 0 0 2rem 2rem;
    gap: 10px;
  }
  .line-22 {
    width: 80%;
  }
  .blog-category {
    font-size: 12px;
  }
  .blog-title {
    font-size: 14px;
  }
  .blog-description {
    font-size: 12px;
    margin: 0;
  }
  .blog-info-wrapper {
    flex-direction: column;
  }
  .blog-image {
    width: 100%;
    aspect-ratio: 16/ 9;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .blog-card {
    padding: 0;
    border: 1px solid rgb(228, 228, 228);
  }
  .blog-description-wrapper {
    gap: 10px;
    width: fit-content;
    padding: 0 15px 15px 15px;
  }
  .categories-list {
    margin: 0px 0 0rem 1rem;
    gap: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
  .blogs-title {
    font-size: 25px;
  }
  .blog-title {
    font-size: 20px;
  }
  .blog-description {
    font-size: 14px;
  }
  .list {
    gap: 20px;
  }
}
@media only screen and (min-width: 1156px) {
}
</style>
