<template>
  <div class="bg-b-lue">
    <div class="popup-inner historyPopup">
      <div class="modal-container">
        <div class="modal-header">
          <h2 class="modal-title">{{ $t("select-language") }}</h2>
          <button @click="$emit('close')" class="close-button" aria-label="Close modal">
            &#x2715;
          </button>
        </div>
        <!-- <div class="modal-section">
          <div class="toggle-container">
            <span class="toggle-label"
              >{{ $t("translation") }}
              <svg
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
                fill="#11365a"
                stroke="#11365a"
                height="128"
                width="128"
                style="width: 20px; height: 20px"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    fill="var(--ci-primary-color, #11365a)"
                    d="M320,24H16V328H192V496H496V192H320ZM148.305,96,98.093,239.3H132l8.166-23.3H192v80H48V56H288V192H221.332L187.7,96Zm36.317,88H151.378L168,136.562ZM464,224V464H224V224Z"
                    class="ci-primary"
                  ></path>
                  <path
                    fill="var(--ci-primary-color, #11365a)"
                    d="M317.432,368.48A136.761,136.761,0,0,0,327.521,382.6q-17.4,9.384-39.521,9.4v32c24.141,0,45.71-6.408,64-18.824C370.29,417.592,391.859,424,416,424V392q-22.075,0-39.52-9.407a136.574,136.574,0,0,0,10.088-14.113A166.212,166.212,0,0,0,406.662,320H424V288H368V264H336v24H280v32h17.338A166.212,166.212,0,0,0,317.432,368.48ZM373.53,320a133.013,133.013,0,0,1-14.1,31.52A104.39,104.39,0,0,1,352,361.968a103.546,103.546,0,0,1-6.93-9.651A132.384,132.384,0,0,1,330.466,320Z"
                    class="ci-primary"
                  ></path>
                </g>
              </svg>
            </span>
            <button
              class="toggle-button"
              :class="[isTranslationEnabled ? 'toggle-on' : 'toggle-off']"
              @click="toggleTranslation"
              role="switch"
              :aria-checked="isTranslationEnabled"
            >
              <span
                class="toggle-thumb"
                :class="[isTranslationEnabled ? 'thumb-on' : 'thumb-off']"
              ></span>
            </button>
          </div>
          <p class="modal-description">
            {{ $t("automatic-translate") }}
          </p>
        </div> -->
        <div class="modal-section">
          <h2 class="section-title">{{ $t("suggested-lanugaues") }}</h2>
          <div class="language-grid">
            <button v-for="lang in suggestedLanguages" :key="lang.code" class="language-button" :class="{
              'language-selected': selectedLanguage.code === lang.code
            }" @click="selectLanguage(lang)">
              <div class="language-name">{{ lang.language }}</div>
              <div class="language-region"><span :class="`flag-icon flag-icon-${lang?.flag}`"></span>{{ lang.region }}
              </div>
            </button>
          </div>
        </div>
        <div class="modal-section">
          <h2 class="section-title">{{ $t("choose-language") }}</h2>
          <div class="language-grid three-columns">
            <button v-for="lang in this.$root.allLanguages" :key="lang.code" class="language-button" :class="{
              'language-bold':selectedLanguage.code === lang.code,
              'language-selected':
                selectedLanguage.code === lang.code,
            }" @click="selectLanguage(lang)">
              <div class="language-name">{{ lang.language }}</div>
              <div class="language-region"><span :class="`flag-icon flag-icon-${lang?.flag}`"></span>{{ lang.region }}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LanguageSelector",
  components: {},

  data() {
    return {
      isTranslationEnabled: true,
      suggestedLanguages: [
        { code: "en", language: "English", region: "Global", flag: "gb" },
      ],
    };
  },
  computed: {
    ...mapGetters(["selectedLanguage"])  // Access selectedLanguage from Vuex
  },
  methods: {
    toggleTranslation() {
      this.isTranslationEnabled = !this.isTranslationEnabled;
    },

    selectLanguage(lang) {
      this.$store.dispatch("updateSelectedLanguage", lang);
      this.$i18n.locale = lang.code;
      this.$emit("select", lang);
    },
  },
};
</script>
<style scoped>
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  z-index: 9999;
}

.historyPopup {
  width: -webkit-fill-available;
  max-width: 600px;
  height: auto;
  margin: auto;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.modal-container {
  max-height: 80vh;
  overflow-y: auto;
}

.modal-header {
  padding: 5px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.modal-title {
  font-size: 18px;
  font-weight: bold;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #11365a;
  width: auto;
}

.modal-section {
  padding: 16px 24px;
}

.toggle-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-description {
  text-align: left;
  margin: 0;
}

.section-title {
  text-align: left;
}

.toggle-label {
  font-size: 16px;
  font-weight: 600;
}

.toggle-button {
  width: 44px;
  height: 24px;
  border-radius: 12px;
  position: relative;
  cursor: pointer;
}

.toggle-on {
  background: #00bd00;
  border: 2px solid #00bd00;
}

.toggle-off {
  background: #ccc;
  border: 2px solid #ccc;
}

.toggle-thumb {
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 0;
  transition: 0.3s;
}

.thumb-on {
  left: 20px;
}

.thumb-off {
  left: 0;
}

.language-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.language-button {
  height: auto;
  padding: 5px 10px;
  border: none;
  border-radius: 8px;
  background: #fff;
  cursor: pointer;
  transition: 0.3s;
  color: #11365a;
}

.language-button:hover {
  background: #f5f5f5;
}

.language-selected {
  background: #11365a1f;
}

.language-bold {
  font-weight: bold;
}

.three-columns {
  grid-template-columns: repeat(3, 1fr);
}

@media only screen and (max-width: 767px) {
  .historyPopup {
    max-width: 350px;
  }
}
</style>
