<template>
  <div
    :class="[
      'table-container',
      {
        'logged-in': userTokenAvailable && !isMobile,
        'not-logged-in': !userTokenAvailable && isMobile,
      },
    ]"
  >
    <div class="hero-section">
      <div class="div">
        <div class="div-2">
          <div class="div-3">
            <div class="institutions">{{ $t("institutions")}}</div>
            <div class="empowering">
              {{ $t("institiutes-heading")}}
            </div>
            <div class="at-credence">
              {{ $t("institiutes-subheading")}}
            </div>
          </div>
          <div class="div-4">
            <div class="button" @click="navigateToFeatures">{{$t("learnMore")}}</div>
            <div
              class="button-2"
              v-if="!userTokenAvailable"
              @click="this.$root.showLogin('SignUp')"
            >
              {{$t("sign-up")}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <WhyCredence />
    <WhoWeServeNew id="who-we-serve"/>
    <div class="query">
      <div class="still-have-questions">{{$t("haveQuestions-heading")}}</div>
      <!-- <div class="contact-us-for-more-information">
        Contact us for more information.
      </div> -->
      <form class="button-group" role="group" aria-label="Action buttons">
      <button
        class="action-button primary-button"
        type="button"
        tabindex="0"
        aria-label="Contact Us"
        @click="redirectToContact"
      >
        {{$t("contact-us")}}
      </button>
      <button
        class="action-button secondary-button"
        type="button"
        tabindex="0"
        aria-label="Subscribe"
      >
      {{$t("Subscribe")}}
      </button>
    </form>
    </div>
    <Footer class="footer-instance"></Footer>
    <div v-show="showScrollToTop" class="scroll-to-top" @click="scrollToTop">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.0306 9.53073C16.9609 9.60046 16.8782 9.65578 16.7871 9.69352C16.6961 9.73127 16.5985 9.75069 16.4999 9.75069C16.4014 9.75069 16.3038 9.73127 16.2127 9.69352C16.1217 9.65578 16.039 9.60046 15.9693 9.53073L8.99993 2.56041L2.03055 9.53073C1.88982 9.67146 1.69895 9.75052 1.49993 9.75052C1.30091 9.75052 1.11003 9.67146 0.969304 9.53073C0.828573 9.39 0.749512 9.19912 0.749512 9.0001C0.749512 8.80108 0.828573 8.61021 0.969304 8.46948L8.4693 0.969476C8.53896 0.899744 8.62168 0.844425 8.71272 0.806682C8.80377 0.768939 8.90137 0.749512 8.99993 0.749512C9.09849 0.749512 9.19608 0.768939 9.28713 0.806682C9.37818 0.844425 9.4609 0.899744 9.53055 0.969476L17.0306 8.46948C17.1003 8.53913 17.1556 8.62185 17.1933 8.7129C17.2311 8.80395 17.2505 8.90154 17.2505 9.0001C17.2505 9.09866 17.2311 9.19626 17.1933 9.28731C17.1556 9.37836 17.1003 9.46107 17.0306 9.53073Z"
          fill="white"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import WhyCredence from "@/components/Institutions/WhyCredence.vue";
import WhoWeServeNew from "@/components/Institutions/WhoWeServe/WhoWeServeNew.vue";
import VueCookies from "vue-cookies";
import Footer from "@/components/HomePage/Footer/Footer.vue";

export default {
  name: "InstitutionsCorporate",
  components: {
    WhyCredence,
    WhoWeServeNew,
    Footer,
  },
  data() {
    return {
      userToken: null,
      isMobile: window.innerWidth < 1023,
      showScrollToTop: false,
    };
  },
  beforeMount() {
    this.userToken = VueCookies.get("token");
  },
  computed: {
    userTokenAvailable() {
      return this.userToken !== null;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    navigateToFeatures() {
      this.$router.push({ path: '/product', hash: '#features-of-reports' });
    },
    scrollToWhoWeServe() {
      const section = document.getElementById("who-we-serve");
      if (section) {
        const offset = 25;
        const topPosition =
          section.getBoundingClientRect().top + window.scrollY - offset;

        window.scrollTo({
          top: topPosition,
          behavior: "smooth",
        });
      }
    },
    redirectToContact() {
      this.$router.push("/contact");
    },
    handleScroll() {
      this.showScrollToTop = window.scrollY > 200;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.logged-in {
  margin-left: 18vw;
  padding-top: 55px !important;
}
.not-logged-in {
  width: 100%;
}
.table-container,
.table-container * {
  box-sizing: border-box;
}
.table-container {
  /* padding-top: 4rem; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  background: white;
}
.query {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 80px;
  font: 18px "Montserrat-Regular", sans-serif;
  width: 100%;
}
.hero-section {
  background-color: rgba(208, 243, 246, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Montserrat-Regular", sans-serif;
  text-align: center;
  padding: 80px 0;
}
.div {
  display: flex;
  width: 90%;
  flex-direction: column;
  font-size: 20px;
  color: #11365a;
  justify-content: center;
  padding: 0 40px;
}
.div-3 {
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 20px;
  color: #11365a;
  justify-content: center;
  gap: 16px;
}
.institutions {
  font-weight: 600;
  text-transform: uppercase;
  align-self: center;
  color: #26bbc2;
}
.at-credence {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
  align-self: stretch;
}
.empowering {
  font-size: 40px;
  font-weight: 800;
}
.div-4 {
  display: flex;
  margin-top: 24px;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.18px;
}
.button {
  align-self: stretch;
  /* flex: 1; */
  border-radius: 13px;
  background-color: #11365a;
  min-width: 150px;
  gap: 10px;
  color: rgba(255, 255, 255, 1);
  margin: auto 0;
  padding: 16px 24px;
  cursor: pointer;
}
.button-2 {
  align-self: stretch;
  /* flex: 1; */
  border-radius: 13px;
  background-color: rgba(255, 255, 255, 1);
  min-width: 150px;
  gap: 10px;
  color: #11365a;
  width: 150px;
  margin: auto 0;
  padding: 14px 22px;
  border: 2px solid #11365a;
  cursor: pointer;
}
.still-have-questions {
  font-size: 40px;
  color: #11365A;
  font-weight: 800;
}
.contact-us-for-more-information {
  color: rgba(62, 72, 85, 1);
  font-weight: 500;
  line-height: 32px;
  margin-top: 16px;
}
.button-group {
  display: flex;
  width: 50vw;
  align-items: center;
  gap: 8px;
  text-align: center;
  letter-spacing: 0.18px;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 4vh;
  font: 600 18px Montserrat, sans-serif;
}
.action-button {
  align-self: stretch;
  flex: 1;
  border-radius: 13px;
  min-width: 150px;
  gap: 10px;
  margin: auto 0;
  cursor: pointer;
  transition: opacity 0.2s ease;
  height: 45px;
}
.action-button:hover {
  opacity: 0.9;
}
.primary-button {
  background: #11365a;
  color: #fff;
  border: none;
}
.secondary-button {
  background: lightgray;
  color: #11365a;
  border: 2px solid #11365a;
  opacity: 0.6 !important;
  cursor: not-allowed;
}
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  background-color: #11365abd;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.18);
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  z-index: 1000;
}
.scroll-to-top:hover {
  background-color: #11365a;
}
.scroll-to-top.show {
  display: block;
  gap: 20px;
}

@media only screen and (max-width: 767px) {
  .hero-section {
    padding: 45px 0;
  }
  .empowering, .still-have-questions {
    font-size: 24px;
  }
  .institutions {
    font-size: 16px;
  }
  .div {
    padding: 0;
  }
  .at-credence {
    font-size: 16px;
  }
  .button, .button-2 {
    font-size: 16px;
  }
  .button {
    padding: 14px 24px;
  }
  .button-2 {
    padding: 12px 24px;
  }
  .section-description {
    font-size: 16px;
  }
  .feature-title {
    font-size: 22px;
  }
  .feature-image {
    width: 50px;
    height: 50px;
  }
  .contact-button {
    padding: 10px 24px;
  }
  .query {
    padding: 40px 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
  .hero-section {
    padding: 50px 0;
  }
  .empowering, .still-have-questions {
    font-size: 36px;
  }
  .at-credence {
    font-size: 18px;
  }
  .section-description {
    font-size: 16px;
  }
  .feature-title {
    font-size: 22px;
  }
  .feature-image {
    width: 50px;
    height: 50px;
  }
  .contact-button {
    padding: 10px 24px;
  }
}
@media only screen and (min-width: 1156px) {
}
</style>
