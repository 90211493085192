<template>
  <div class="terms-container">
    <h1 class="title">{{ $t("terms-title") }}</h1>
    <div class="content">
      <p>{{ $t("desc1") }}</p>
      <section v-for="(section, index) in termsSections" :key="index">
        <h2>{{ $t(section.title) }}</h2>
        <p v-if="section.description">{{ $t(section.description) }}</p>
        <ul v-if="section.bullets.length">
          <li v-for="(bullet, bIndex) in section.bullets" :key="bIndex">
            <strong v-if="bullet.title">{{ $t(bullet.title) }}:</strong>
            {{ $t(bullet.text) }}
          </li>
        </ul>
      </section>
      <h5>{{ $t("last-update") }}</h5>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsAndConditions",
  computed: {
    termsSections() {
      return [
        {
          title: "sub-title1",
          description: "bullet-title1",
          bullets: [
            { text: "bullet1" },
            { text: "bullet2" },
            { text: "bullet3" },
            { text: "bullet4" },
            { text: "bullet5" },
            { text: "bullet6" },
          ],
        },
        {
          title: "sub-title2",
          description: "bullet-title2",
          bullets: [
            { text: "bullet21" },
            { text: "bullet22" },
            { text: "bullet23" },
            { text: "bullet24" },
            { text: "bullet25" },
            { text: "bullet26" },
            { text: "bullet27" },
            { text: "bullet28" },
            { text: "bullet29" },
            { text: "bullet20" },
          ],
        },
        {
          title: "sub-title3",
          description: "desc-2",
          bullets: [],
        },
        {
          title: "sub-title4",
          description: "bullet-title3",
          bullets: [
            { text: "bullet31" },
            { text: "bullet32" },
            { text: "bullet33" },
            { text: "bullet34" },
            { text: "bullet35" },
          ],
        },
        {
          title: "sub-title5",
          description: "desc-3",
          bullets: [],
        },
        {
          title: "sub-title6",
          description: "desc-4",
          bullets: [],
        },
        {
          title: "sub-title7",
          description: "desc-5",
          bullets: [],
        },
        {
          title: "sub-title8",
          description: "bullet-title4",
          bullets: [
            { title: "bullet4-title1", text: "bullet41" },
            { title: "bullet4-title2", text: "bullet42" },
            { title: "bullet4-title3", text: "bullet43" },
            { title: "bullet4-title4", text: "bullet44" },
            { title: "bullet4-title5", text: "bullet45" },
            { title: "bullet4-title6", text: "bullet46" },
          ],
        },
        {
          title: "sub-title9",
          description: "bullet-title5",
          bullets: [
            { text: "bullet51" },
            { text: "bullet52" },
            { text: "bullet53" },
          ],
        },
        {
          title: "sub-title10",
          description: "bullet-title6",
          bullets: [
            { title: "bullet6-title1", text: "bullet61" },
            { title: "bullet6-title2", text: "bullet62" },
            { title: "bullet6-title3", text: "bullet63" },
            { title: "bullet6-title4", text: "bullet64" },
            { title: "bullet6-title5", text: "bullet65" },
            { title: "bullet6-title6", text: "bullet66" },
          ],
        },
        {
          title: "sub-title11",
          description: null,
          bullets: [{ text: "desc-6" }],
        },
        {
          title: "sub-title12",
          description: null,
          bullets: [{ text: "desc-7" }],
        },
      ];
    },
  },
};
</script>

<style scoped>
.terms-container {
  width: 85%;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
}

.title {
  text-align: center;
  margin-bottom: 20px;
  margin: 7% 0 5% 0;
  align-self: stretch;
  padding: 0 10%;
}

.content {
  font-size: 16px;
  padding: 0 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.content p {
  text-align: justify;
}

.content h2 {
  margin-top: 20px;
  text-align: left;
}

.content ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: justify;
}

.content li {
  margin-bottom: 10px;
  text-align: justify;
}
</style>
