<template>
  <div id="userMenu" v-if="
    $route.path !== '/login' &&
    $route.path !== '/whatsapp/termsandconditions' &&
    !userTokenAvailable
  " :class="appClass" :lang="currentLanguage">
    <div class="frame-35582">
      <div class="frame-7">
        <router-link to="/" class="router-link-no-hover" aria-label="Credence Home">
          <div class="frame-1">
            <img class="image-2" :src="getCDNlink('assets/logo.webp')" alt="Credence" />
          </div>
        </router-link>
        <div class="frame-4" v-if="!userTokenAvailable && !showMobileMenu">
          <div class="partnership" @click="redirectToHome">
            {{ $t("home") }}
          </div>
        </div>
        <div class="frame-5" v-if="!userTokenAvailable && !showMobileMenu">
          <div class="blogs" @click="redirectToProducts">
            {{ $t("product") }}
          </div>
        </div>
        <div class="frame-72" v-if="!userTokenAvailable && !showMobileMenu">
          <div class="blogs" @click="redirectToBlogs">{{ $t("blogs") }}</div>
        </div>
        <div class="frame-9" v-if="!userTokenAvailable && !showMobileMenu">
          <div class="about" @click="redirectToAboutUs">{{ $t("about") }}</div>
        </div>
        <div class="frame-9" v-if="!userTokenAvailable && !showMobileMenu">
          <div class="about" @click="redirectToInstitutions">
            {{ $t("institutions") }}
          </div>
        </div>
      </div>
      <div v-if="showMobileMenu" style="
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 15px;
        ">
        <!--Before Mobile Login Language Selector Start -->
        <div class="select-language1" @click="openLanguageModal" :title="selectedLanguage?.language" v-if="enableTranslation">
          <button class="language-capsule" style="background-color: white;">
            <span :class="`flag-icon flag-icon-${selectedLanguage?.flag}`"></span>
            <span class="language-capsule-name">
              {{ selectedLanguage?.code }}
            </span>
          </button>
        </div>
        <!--Before Mobile Login Language Selector End -->

        <div class="hamburger-menu" @click="toggleDrawer">
          <div class="iconly-sharp-menu-instance">
            <svg width="26" height="26" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M29.625 18C29.625 17.3787 29.1213 16.875 28.5 16.875H7.5C6.87868 16.875 6.375 17.3787 6.375 18C6.375 18.6213 6.87868 19.125 7.5 19.125H28.5C29.1213 19.125 29.625 18.6213 29.625 18Z"
                fill="black" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M29.625 10.5C29.625 9.87868 29.1213 9.375 28.5 9.375H7.5C6.87868 9.375 6.375 9.87868 6.375 10.5C6.375 11.1213 6.87868 11.625 7.5 11.625H28.5C29.1213 11.625 29.625 11.1213 29.625 10.5Z"
                fill="black" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M29.625 25.5C29.625 24.8787 29.1213 24.375 28.5 24.375H7.5C6.87868 24.375 6.375 24.8787 6.375 25.5C6.375 26.1213 6.87868 26.625 7.5 26.625H28.5C29.1213 26.625 29.625 26.1213 29.625 25.5Z"
                fill="black" />
            </svg>
          </div>
        </div>
      </div>
      <!-- Drawer Navigation -->
      <div v-if="showDrawer" class="bg-b-lue" v-on:click.self="toggleDrawer">
        <transition name="slide">
          <div class="drawer-navigation">
            <div class="drawer-item" @click="redirectToHome">
              <div class="solutions" style="text-align: left">
                {{ $t("home") }}
              </div>
            </div>
            <div class="drawer-item" v-if="!userTokenAvailable" @click="redirectToProducts">
              <div class="solutions" style="text-align: left">
                {{ $t("product") }}
              </div>
            </div>
            <div class="drawer-item" v-if="!userTokenAvailable" @click="redirectToBlogs">
              <div class="solutions" style="text-align: left">
                {{ $t("blogs") }}
              </div>
            </div>
            <div class="drawer-item" v-if="!userTokenAvailable" @click="redirectToAboutUs">
              <div class="solutions" style="text-align: left">
                {{ $t("about") }}
              </div>
            </div>
            <div class="drawer-item" v-if="!userTokenAvailable" @click="redirectToInstitutions">
              <div class="solutions" style="text-align: left">
                {{ $t("institutions") }}
              </div>
            </div>
            <div class="drawer-item" v-if="!userTokenAvailable" @click="showLogin('Login')">
              <div class="partnership" style="text-align: left">
                {{ $t("login") }}
              </div>
            </div>
            <div class="drawer-item" v-if="!userTokenAvailable" @click="showLogin('SignUp')">
              <div class="blogs" style="text-align: left">
                {{ $t("sign-up") }}
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div v-if="!showMobileMenu" class="frame-82">
        <ButtonProperty1SecondaryHoverFalse v-if="userToken == null" property1="secondary" class="button-instance"
          style="cursor: pointer" @click="showLogin('SignUp')"></ButtonProperty1SecondaryHoverFalse>

        <ButtonProperty1PrimaryHoverFalse v-if="userToken == null" class="button-instance" style="cursor: pointer"
          @click="showLogin('Login')"></ButtonProperty1PrimaryHoverFalse>
        <!--Before Login Language Selector Start -->
        <div class="select-language1" @click="openLanguageModal" :title="selectedLanguage?.language" v-if="enableTranslation">
          <button class="language-capsule" style="background-color: white;">
            <span :class="`flag-icon flag-icon-${selectedLanguage?.flag}`"></span>
            <span class="language-capsule-name">
              {{ selectedLanguage?.code }}
            </span>
          </button>
        </div>
        <!--Before Login Language Selector End -->
      </div>
    </div>
  </div>
  <div id="userMenu" v-if="userTokenAvailable && showMobileMenu" :class="appClass">
    <div class="frame-355821">
      <div class="navbar-left">
        <div class="menu-wrapper" @click="
          this.drawerOpen = false;
        this.showDrawer = !this.showDrawer;
        " :class="{ active: this.showDrawer }">
          <div class="menu-bar one"></div>
          <div class="menu-bar two"></div>
          <div class="menu-bar three"></div>
        </div>
        <div class="frame-7">
          <router-link to="/" @click="navBarCkicked()" class="router-link-no-hover">
            <div class="frame-1">
              <img class="image-2" :src="getCDNlink('assets/logo.webp')" alt="Credence" />
            </div>
          </router-link>
        </div>
      </div>
      <div style="
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;
        ">
        <router-link to="/profile?tab=credits" v-if="userTokenAvailable && hasCredits" style="padding: 0">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 36 36" fill="none" @click="
            this.drawerOpen = false;
          this.showDrawer = false;
          ">
            <path
              d="M18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33Z"
              stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M24 12H15C14.2044 12 13.4413 12.3161 12.8787 12.8787C12.3161 13.4413 12 14.2044 12 15C12 15.7956 12.3161 16.5587 12.8787 17.1213C13.4413 17.6839 14.2044 18 15 18H21C21.7956 18 22.5587 18.3161 23.1213 18.8787C23.6839 19.4413 24 20.2044 24 21C24 21.7956 23.6839 22.5587 23.1213 23.1213C22.5587 23.6839 21.7956 24 21 24H12"
              stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M18 27V9" stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </router-link>
        <router-link to="/rewards" v-if="userTokenAvailable && hasRewards" style="padding: 0">
          <!-- <img
            :src="getCDNlink('assets/Icons/reward1.webp')"
            alt="Credence My Orders"
            class="icon-image reward"
            @click="
              this.drawerOpen = false;
              this.showDrawer = false;
            "
          /> -->
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 36 36" fill="none" @click="
            this.drawerOpen = false;
          this.showDrawer = false;
          ">
            <path
              d="M30 12H6C5.17157 12 4.5 12.6716 4.5 13.5V16.5C4.5 17.3284 5.17157 18 6 18H30C30.8284 18 31.5 17.3284 31.5 16.5V13.5C31.5 12.6716 30.8284 12 30 12Z"
              stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M18 12V31.5" stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M28.5 18V28.5C28.5 29.2956 28.1839 30.0587 27.6213 30.6213C27.0587 31.1839 26.2956 31.5 25.5 31.5H10.5C9.70435 31.5 8.94129 31.1839 8.37868 30.6213C7.81607 30.0587 7.5 29.2956 7.5 28.5V18"
              stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M11.25 12.0007C10.2554 12.0007 9.30161 11.6056 8.59835 10.9023C7.89509 10.199 7.5 9.24522 7.5 8.25066C7.5 7.25609 7.89509 6.30227 8.59835 5.59901C9.30161 4.89574 10.2554 4.50066 11.25 4.50066C12.697 4.47544 14.115 5.17755 15.3191 6.5154C16.5232 7.85325 17.4574 9.76476 18 12.0007C18.5426 9.76476 19.4768 7.85325 20.6809 6.5154C21.885 5.17755 23.303 4.47544 24.75 4.50066C25.7446 4.50066 26.6984 4.89574 27.4017 5.59901C28.1049 6.30227 28.5 7.25609 28.5 8.25066C28.5 9.24522 28.1049 10.199 27.4017 10.9023C26.6984 11.6056 25.7446 12.0007 24.75 12.0007"
              stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </router-link>
        <div class="cart-icon-wrapper" v-if="hasCart">
          <Dropdown :triggers="[]" :shown="isCheckoutOpen" :autoHide="true" style="display: flex">
            <!-- <img
              :src="getCDNlink('assets/Icons/cart.webp')"
              alt="Credence My Orders"
              class="icon-image"
              style="cursor: pointer"
              @click="toggleWishlist()"
            /> -->
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 36 36" fill="none"
              style="cursor: pointer" @click="toggleWishlist">
              <path
                d="M12 33C12.8284 33 13.5 32.3284 13.5 31.5C13.5 30.6716 12.8284 30 12 30C11.1716 30 10.5 30.6716 10.5 31.5C10.5 32.3284 11.1716 33 12 33Z"
                stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M28.5 33C29.3284 33 30 32.3284 30 31.5C30 30.6716 29.3284 30 28.5 30C27.6716 30 27 30.6716 27 31.5C27 32.3284 27.6716 33 28.5 33Z"
                stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M3.07495 3.07422H6.07495L10.065 21.7042C10.2113 22.3865 10.591 22.9964 11.1385 23.429C11.6861 23.8616 12.3673 24.0897 13.065 24.0742H27.735C28.4177 24.0731 29.0797 23.8392 29.6115 23.411C30.1433 22.9828 30.5132 22.386 30.66 21.7192L33.135 10.5742H7.67995"
                stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <div class="cart-count" v-if="cartCount > 0">{{ cartCount }}</div>
            <template #popper>
              <div class="tooltip-content">
                <CartAnimation />
                <div class="tooltip-message">
                  {{ $t("procced-to-checkout") }}
                </div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="cart-icon-wrapper" ref="profile2" @click="profileClicked2">
          <!-- <img
            :src="getCDNlink('assets/Icons/user.webp')"
            alt="Credence User"
            class="icon-image"
            style="
              width: 25px;
              height: 25px;
              border-radius: 50%;
              box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.18);
              cursor: pointer;
            "
          /> -->
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 36 36" fill="none"
            style="cursor: pointer">
            <path
              d="M18 19.5C20.4853 19.5 22.5 17.4853 22.5 15C22.5 12.5147 20.4853 10.5 18 10.5C15.5147 10.5 13.5 12.5147 13.5 15C13.5 17.4853 15.5147 19.5 18 19.5Z"
              stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M10.5 30.993V28.5C10.5 27.7044 10.8161 26.9413 11.3787 26.3787C11.9413 25.8161 12.7044 25.5 13.5 25.5H22.5C23.2956 25.5 24.0587 25.8161 24.6213 26.3787C25.1839 26.9413 25.5 27.7044 25.5 28.5V30.993"
              stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33Z"
              stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <div class="profile-menu-2" v-if="profileMenuActive2" ref="profileMenu2">
            <div class="profile-menu-item" @click="
              goToProfile();
            navBarCkicked();
            ">
              {{ $t("user-profile") }}
            </div>
            <div class="profile-menu-item" @click="
              processLogout();
            navBarCkicked();
            ">
              {{ $t("logout") }}
            </div>
          </div>

        </div>
        <!--After Mobile Login Language Selector Start -->
        <div class="select-language1" @click="openLanguageModal" :title="selectedLanguage?.language" v-if="enableTranslation">
          <button class="language-capsule" style="background-color: white;">
            <span :class="`flag-icon flag-icon-${selectedLanguage?.flag}`"></span>
            <span class="language-capsule-name">
              {{ selectedLanguage?.code }}
            </span>
          </button>
        </div>
        <!--After Mobile Login Language Selector End -->
      </div>
      <Drawer :open="drawerOpen" @close="toggleWishlist" @placeorder="toggleCheckout"
        :cartCount="updatedCartList.length" :showCTAButtons="true" :flag="flag" style="pointer-events: auto">
        <template #header>
          <div class="wishlist-title-wrapper">
            <div class="wishlist-title" @click="toggleWishlist">
              <i class="fa-solid fa-angle-left"></i>
              <h3>{{ $t("my-cart") }}</h3>
            </div>
            <div class="clear-cart" v-if="updatedCartList.length > 0" @click="deleteFromCart(null, true)">
              {{ $t("clear-cart") }}
            </div>
          </div>
        </template>
        <template #body>
          <div class="validity" v-if="cartValidity">
            <div class="validity-title1">
              {{ $t("cart-expires-on") }} {{ cartValidityData }}.
            </div>
            <div class="validity-title2">
              {{ $t("secure-selection") }}
            </div>
          </div>
          <div class="validity_2" :style="{
            background: this.warning === 'EMPTY' ? '#861f1f' : '#F0AD4E',
          }" v-if="isNotificationEnabled">
            <div style="width: 60%">
              <div class="validity-title1" :style="{
                color: this.warning === 'EMPTY' ? 'white' : '#2c3e50',
              }">
                {{ this.warning === "EMPTY" ? $t("insufficient") : $t("low") }}
                {{ $t("credits") }}
              </div>
              <div class="validity-title2" :style="{
                color: this.warning === 'EMPTY' ? 'white' : '#2c3e50',
              }">
                {{ $t("please-recharge") }}
              </div>
            </div>
            <button class="warning-action" :style="{
              border:
                this.warning === 'EMPTY'
                  ? '1.5px solid #fff'
                  : '1.5px solid #2c3e50',
              color: this.warning === 'EMPTY' ? 'white' : '#2c3e50',
            }" @click="redirectToCredits">
              {{ $t("credits") }}
            </button>
          </div>
          <ul class="cart-items-wrapper" :style="{
            alignItems:
              updatedCartList.length === 0 ? 'center' : 'flex-start',
            justifyContent:
              updatedCartList.length === 0 ? 'center' : 'flex-start',
          }">
            <li class="cart-items" v-for="item in updatedCartList" :key="item.id">
              <div class="cart-item-data">
                <div class="info-wrapper-nav">
                  {{ item?.order?.subjectName }}
                  <div class="report-type-nav">
                    {{ $t("report-type") }}:
                    {{
                      item?.order?.reportType === "FRESH"
                        ? "Fresh Report"
                        : `${item?.order?.reportDetails?.report_label} (${$t('updated')} ${item?.order?.reportDetails?.report_dated})`
                    }}
                  </div>
                </div>
                <i class="fa-solid fa-xmark" @click="deleteFromCart(item, false)"></i>
              </div>
            </li>
            <li v-if="updatedCartList.length === 0">
              <div class="cart-item-data">{{ $t("empty-cart") }}</div>
            </li>
          </ul>
        </template>
      </Drawer>
      <div v-if="showDrawer" class="bg-b-lue-mobile" v-on:click.self="toggleDrawer">
        <transition name="slide">
          <div class="drawer-navigation-loggedin">
            <div class="navbar-optins" style="height: auto">
              <template v-for="(option, index) in options" :key="index">
                <router-link :to="`${option.route}${option.params}`" :class="[
                  'navbar-option',
                  { 'active-link': isActiveRoute(option.route) },
                ]" @click="
                  navBarCkicked();
                toggleDrawer();
                " style="display: flex">
                  <!-- <img
                    :src="getCDNlink(`assets/Icons/${option.icon}`)"
                    :alt="'Credence ' + option.label"
                    class="icon-image"
                  /> -->
                  <div class="icon-wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none"
                      :stroke="isActiveRoute(option.route) ? 'white' : '#11365A'
                        " stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      v-html="getIcon(option.icon)"></svg>
                  </div>
                  <div class="option-lable" :style="{
                    color: isActiveRoute(option.route) ? 'white' : '#11365A',
                  }">
                    {{ option.label }}
                  </div>
                </router-link>
              </template>
              <router-link to="/sample-reports" v-if="userTokenAvailable && !isAdmin" :class="[
                'navbar-option',
                { 'active-link': isActiveRoute('/sample-reports') },
              ]" @click="
                navBarCkicked();
              toggleDrawer();
              " :style="{
                  display: 'flex',
                  animation: showBlinking
                    ? 'blinking 1s linear infinite'
                    : 'none',
                }">
                <!-- <img
                  :src="getCDNlink('assets/Icons/sample.webp')"
                  alt="Credence My Orders"
                  class="icon-image"
                /> -->
                <div class="icon-wrapper">
                  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none"
                    :stroke="isActiveRoute('/sample-reports') ? 'white' : '#11365A'
                      " stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path
                      d="M15 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7V4C20 2.89543 19.1046 2 18 2H15Z" />
                    <path d="M8 14V12" />
                    <path d="M12 14V10" />
                    <path d="M16 18L8 18" />
                  </svg>
                </div>
                <div class="option-lable" :style="{
                  color: isActiveRoute('/sample-reports')
                    ? 'white'
                    : '#11365A',
                }">
                  {{ $t("sample-reports1") }}
                </div>
              </router-link>
            </div>
            <div class="profile-button" @click="profileClicked" v-if="userName !== ''" ref="profile">
              <div class="user-profile">
                <!-- <img
                  :src="getCDNlink('assets/Icons/user.webp')"
                  alt="Credence User"
                  class="icon-image"
                  style="
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.18);
                  "
                /> -->
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M19 21V19C19 17.9391 18.5786 16.9217 17.8284 16.1716C17.0783 15.4214 16.0609 15 15 15H9C7.93913 15 6.92172 15.4214 6.17157 16.1716C5.42143 16.9217 5 17.9391 5 19V21"
                    stroke="#11365A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                    stroke="#11365A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <div class="user-name">{{ truncatedUserName }}</div>
              </div>
              <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6.5L6 1.5L11 6.5" stroke="#11365A" stroke-width="2" stroke-linecap="round" />
              </svg>
              <div class="profile-menu" v-if="profileMenuActive" ref="profileMenu">
                <div class="profile-menu-item" @click="
                  goToProfile();
                toggleDrawer();
                navBarCkicked();
                ">
                  {{ $t("user-profile") }}
                </div>
                <div class="profile-menu-item" @click="
                  processLogout();
                toggleDrawer();
                navBarCkicked();
                ">
                  {{ $t("logout") }}
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
  <div style="
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      position: fixed;
      width: 100%;
      z-index: 1;
      pointer-events: none;
    " v-if="userTokenAvailable && !showMobileMenu">
    <div :class="['dashboard-navigation-container', { collapsed: isCollapsed }]">
      <div :class="['collapse-icon', { rotated: isCollapsed }]" :title="isCollapsed ? 'Expand' : 'Collapse'"
        @click="toggleCollapse">
        <div style="width: 20px; height: 20px; align-content: center">
          <i class="fas fa-angle-left"></i>
        </div>
      </div>

      <div class="dasboard-nav-menu" :style="{
        width: isCollapsed ? '90%' : '',
        padding: isCollapsed ? '0' : '',
      }">
        <router-link :to="userType ? '/search' : '/search'" class="router-link-no-hover" @click="navBarCkicked()"
          :style="{
            width: isCollapsed ? '-webkit-fill-available' : '',
          }">
          <div class="frame-1">
            <img class="image-2" :src="getCDNlink(
              isCollapsed ? 'assets/logo2.webp' : 'assets/logo.webp'
            )
              " alt="Credence" :style="{ width: isCollapsed ? 'auto' : '' }" />
          </div>
        </router-link>
        <div class="line-16"></div>
        <div class="navbar-optins-wrapper">
          <div class="navbar-optins">
            <template v-for="(option, index) in options" :key="index">
              <router-link :to="`${option.route}${option.params}`" :class="[
                'navbar-option',
                { 'active-link': isActiveRoute(option.route) },
              ]" :style="{
                padding: isCollapsed ? '12px' : '',
                justifyContent: isCollapsed ? 'center' : '',
                flexWrap: isCollapsed ? 'wrap' : '',
              }" :title="option.label" @click="navBarCkicked()">
                <!-- <img
                  :src="getCDNlink(`assets/Icons/${option.icon}`)"
                  :alt="'Credence ' + option.label"
                  class="icon-image"
                /> -->
                <div class="icon-wrapper">
                  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none"
                    :stroke="isActiveRoute(option.route) ? 'white' : '#11365A'" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" v-html="getIcon(option.icon)"></svg>
                </div>
                <div class="option-lable" :style="{
                  color: isActiveRoute(option.route) ? 'white' : '#11365A',
                  display: isCollapsed ? 'none' : '',
                }">
                  {{ option.label }}
                </div>
              </router-link>
            </template>
            <router-link to="/sample-reports" v-if="userTokenAvailable && !isAdmin" :class="[
              'navbar-option',
              { 'active-link': isActiveRoute('/sample-reports') },
            ]" @click="navBarCkicked()" title="Sample Reports" :style="{
              animation: showBlinking
                ? 'blinking 1s linear infinite'
                : 'none',
              padding: isCollapsed ? '12px' : '',
              justifyContent: isCollapsed ? 'center' : '',
              flexWrap: isCollapsed ? 'wrap' : '',
            }">
              <!-- <img
                :src="getCDNlink('assets/Icons/sample.webp')"
                alt="Credence My Orders"
                class="icon-image"
              /> -->
              <div class="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" :stroke="isActiveRoute('/sample-reports') ? 'white' : '#11365A'
                  " stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <path
                    d="M15 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7V4C20 2.89543 19.1046 2 18 2H15Z" />
                  <path d="M8 14V12" />
                  <path d="M12 14V10" />
                  <path d="M16 18L8 18" />
                </svg>
              </div>
              <div class="option-lable" :style="{
                color: isActiveRoute('/sample-reports') ? 'white' : '#11365A',
                display: isCollapsed ? 'none' : '',
              }">
                {{ $t("sample-reports1") }}
              </div>
            </router-link>
          </div>
          <div class="profile-button" @click="profileClicked" v-if="userName !== ''" ref="profile" title="Profile"
            :style="{
              border: isCollapsed ? 'none' : '',
              padding: isCollapsed ? '5px 0px' : '',
              justifyContent: isCollapsed ? 'center' : '',
            }">
            <div class="user-profile">
              <!-- <img
                :src="getCDNlink('assets/Icons/user.webp')"
                alt="Credence User"
                class="icon-image"
                style="
                  width: 35px;
                  height: 35px;
                  border-radius: 50%;
                  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.18);
                "
              /> -->
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none">
                <path
                  d="M19 21V19C19 17.9391 18.5786 16.9217 17.8284 16.1716C17.0783 15.4214 16.0609 15 15 15H9C7.93913 15 6.92172 15.4214 6.17157 16.1716C5.42143 16.9217 5 17.9391 5 19V21"
                  stroke="#11365A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                  stroke="#11365A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <div class="user-name" v-if="!isCollapsed">
                {{ truncatedUserName }}
              </div>
              <div class="profile-menu" v-if="profileMenuActive" ref="profileMenu">
                <div class="profile-menu-item" @click="
                  goToProfile();
                navBarCkicked();
                ">
                  {{ $t("user-profile") }}
                </div>
                <div class="profile-menu-item" @click="
                  processLogout();
                navBarCkicked();
                ">
                  {{ $t("logout") }}
                </div>
              </div>
            </div>
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"
              v-if="!isCollapsed">
              <path d="M1 6.5L6 1.5L11 6.5" stroke="#11365A" stroke-width="2" stroke-linecap="round" />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div style="
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
      ">
      <div style="
          width: -webkit-fill-available;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          height: 55px;
          padding: 0 4vw;
          background: #e2eaf5;
          pointer-events: auto;
        ">
        <div class="navbar-title">{{ currentScreenName }}</div>
        <div style="
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 25px;
          ">
          <router-link to="/profile?tab=credits" v-if="userTokenAvailable && hasCredits" style="padding: 0">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 36 36" fill="none" @click="
              this.drawerOpen = false;
            this.showDrawer = false;
            ">
              <path
                d="M18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33Z"
                stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M24 12H15C14.2044 12 13.4413 12.3161 12.8787 12.8787C12.3161 13.4413 12 14.2044 12 15C12 15.7956 12.3161 16.5587 12.8787 17.1213C13.4413 17.6839 14.2044 18 15 18H21C21.7956 18 22.5587 18.3161 23.1213 18.8787C23.6839 19.4413 24 20.2044 24 21C24 21.7956 23.6839 22.5587 23.1213 23.1213C22.5587 23.6839 21.7956 24 21 24H12"
                stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M18 27V9" stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </router-link>
          <router-link to="/rewards" v-if="userTokenAvailable && hasRewards && !showMobileMenu">
            <!-- <img
              :src="getCDNlink('assets/Icons/reward1.webp')"
              alt="Credence My Orders"
              class="icon-image"
            /> -->
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 36 36" fill="none">
              <path
                d="M30 12H6C5.17157 12 4.5 12.6716 4.5 13.5V16.5C4.5 17.3284 5.17157 18 6 18H30C30.8284 18 31.5 17.3284 31.5 16.5V13.5C31.5 12.6716 30.8284 12 30 12Z"
                stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M18 12V31.5" stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M28.5 18V28.5C28.5 29.2956 28.1839 30.0587 27.6213 30.6213C27.0587 31.1839 26.2956 31.5 25.5 31.5H10.5C9.70435 31.5 8.94129 31.1839 8.37868 30.6213C7.81607 30.0587 7.5 29.2956 7.5 28.5V18"
                stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M11.25 12.0007C10.2554 12.0007 9.30161 11.6056 8.59835 10.9023C7.89509 10.199 7.5 9.24522 7.5 8.25066C7.5 7.25609 7.89509 6.30227 8.59835 5.59901C9.30161 4.89574 10.2554 4.50066 11.25 4.50066C12.697 4.47544 14.115 5.17755 15.3191 6.5154C16.5232 7.85325 17.4574 9.76476 18 12.0007C18.5426 9.76476 19.4768 7.85325 20.6809 6.5154C21.885 5.17755 23.303 4.47544 24.75 4.50066C25.7446 4.50066 26.6984 4.89574 27.4017 5.59901C28.1049 6.30227 28.5 7.25609 28.5 8.25066C28.5 9.24522 28.1049 10.199 27.4017 10.9023C26.6984 11.6056 25.7446 12.0007 24.75 12.0007"
                stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </router-link>
          <!-- <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="20"
          viewBox="0 0 18 20"
          fill="none"
          style="cursor: pointer"
        >
          <path
            d="M17.7938 14.4944C17.2735 13.5981 16.5 11.0622 16.5 7.75C16.5 5.76088 15.7098 3.85322 14.3033 2.4467C12.8968 1.04018 10.9891 0.25 9.00001 0.25C7.01089 0.25 5.10323 1.04018 3.69671 2.4467C2.29019 3.85322 1.50001 5.76088 1.50001 7.75C1.50001 11.0631 0.725637 13.5981 0.205324 14.4944C0.0724531 14.7222 0.00201297 14.9811 0.00110747 15.2449C0.000201977 15.5086 0.0688633 15.768 0.200167 15.9967C0.331471 16.2255 0.520774 16.4156 0.748986 16.5478C0.977197 16.6801 1.23625 16.7498 1.50001 16.75H5.32595C5.49899 17.5967 5.95916 18.3577 6.62864 18.9042C7.29811 19.4507 8.1358 19.7492 9.00001 19.7492C9.86422 19.7492 10.7019 19.4507 11.3714 18.9042C12.0409 18.3577 12.501 17.5967 12.6741 16.75H16.5C16.7637 16.7496 17.0226 16.6798 17.2507 16.5475C17.4788 16.4151 17.668 16.225 17.7992 15.9963C17.9303 15.7676 17.9989 15.5083 17.998 15.2446C17.997 14.9809 17.9266 14.7222 17.7938 14.4944ZM9.00001 18.25C8.53484 18.2499 8.08115 18.1055 7.70139 17.8369C7.32162 17.5683 7.03444 17.1886 6.87939 16.75H11.1206C10.9656 17.1886 10.6784 17.5683 10.2986 17.8369C9.91887 18.1055 9.46518 18.2499 9.00001 18.25ZM1.50001 15.25C2.22189 14.0087 3.00001 11.1325 3.00001 7.75C3.00001 6.1587 3.63215 4.63258 4.75737 3.50736C5.88259 2.38214 7.40871 1.75 9.00001 1.75C10.5913 1.75 12.1174 2.38214 13.2427 3.50736C14.3679 4.63258 15 6.1587 15 7.75C15 11.1297 15.7763 14.0059 16.5 15.25H1.50001Z"
            fill="black"
          />
        </svg> -->
          <div class="cart-icon-wrapper" v-if="hasCart">
            <Dropdown :triggers="[]" :shown="isCheckoutOpen" :autoHide="true" style="display: flex">
              <!-- <img
                :src="getCDNlink('assets/Icons/cart.webp')"
                alt="Credence My Orders"
                class="icon-image"
                style="cursor: pointer"
                @click="toggleWishlist"
              /> -->
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 36 36" fill="none"
                style="cursor: pointer" @click="toggleWishlist">
                <path
                  d="M12 33C12.8284 33 13.5 32.3284 13.5 31.5C13.5 30.6716 12.8284 30 12 30C11.1716 30 10.5 30.6716 10.5 31.5C10.5 32.3284 11.1716 33 12 33Z"
                  stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M28.5 33C29.3284 33 30 32.3284 30 31.5C30 30.6716 29.3284 30 28.5 30C27.6716 30 27 30.6716 27 31.5C27 32.3284 27.6716 33 28.5 33Z"
                  stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M3.07495 3.07422H6.07495L10.065 21.7042C10.2113 22.3865 10.591 22.9964 11.1385 23.429C11.6861 23.8616 12.3673 24.0897 13.065 24.0742H27.735C28.4177 24.0731 29.0797 23.8392 29.6115 23.411C30.1433 22.9828 30.5132 22.386 30.66 21.7192L33.135 10.5742H7.67995"
                  stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <div class="cart-count" v-if="cartCount > 0">{{ cartCount }}</div>
              <template #popper>
                <div class="tooltip-content">
                  <CartAnimation />
                  <div class="tooltip-message">
                    {{ $t("procced-to-checkout") }}
                  </div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="cart-icon-wrapper" ref="profile2" @click="profileClicked2">
            <!-- <img
              :src="getCDNlink('assets/Icons/user.webp')"
              alt="Credence User"
              class="icon-image"
              style="
                width: 25px;
                height: 25px;
                border-radius: 50%;
                box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.18);
                cursor: pointer;
              "
            /> -->
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 36 36" fill="none"
              style="cursor: pointer">
              <path
                d="M18 19.5C20.4853 19.5 22.5 17.4853 22.5 15C22.5 12.5147 20.4853 10.5 18 10.5C15.5147 10.5 13.5 12.5147 13.5 15C13.5 17.4853 15.5147 19.5 18 19.5Z"
                stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M10.5 30.993V28.5C10.5 27.7044 10.8161 26.9413 11.3787 26.3787C11.9413 25.8161 12.7044 25.5 13.5 25.5H22.5C23.2956 25.5 24.0587 25.8161 24.6213 26.3787C25.1839 26.9413 25.5 27.7044 25.5 28.5V30.993"
                stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33Z"
                stroke="#11365A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

            <div class="profile-menu-2" v-if="profileMenuActive2" ref="profileMenu2">
              <div class="profile-menu-item" @click="
                goToProfile();
              navBarCkicked();
              ">
                {{ $t("user-profile") }}
              </div>
              <div class="profile-menu-item" @click="
                processLogout();
              navBarCkicked();
              ">
                {{ $t("logout") }}
              </div>
            </div>
          </div>
          <!--After Login Language Selector Start -->
          <div class="select-language1" @click="openLanguageModal" :title="selectedLanguage?.language" v-if="enableTranslation">
            <button class="language-capsule">
              <span :class="`flag-icon flag-icon-${selectedLanguage?.flag}`"></span>
              <span class="language-capsule-name">
                {{ selectedLanguage?.code }}
              </span>

            </button>
          </div>
          <!--After Login Language Selector End -->
        </div>
      </div>
      <WarningMessage v-if="isNotificationEnabled" />
    </div>
    <Drawer :open="drawerOpen" @close="toggleWishlist" @placeorder="toggleCheckout" :cartCount="updatedCartList.length"
      :showCTAButtons="true" :flag="flag" style="pointer-events: auto">
      <template #header>
        <div class="wishlist-title-wrapper">
          <div class="wishlist-title" @click="toggleWishlist">
            <i class="fa-solid fa-angle-left"></i>
            <h3>{{ $t("my-cart") }}</h3>
          </div>
          <div class="clear-cart" v-if="updatedCartList.length > 0" @click="deleteFromCart(null, true)">
            {{ $t("clear-cart") }}
          </div>
        </div>
      </template>
      <template #body>
        <div class="validity" v-if="cartValidity">
          <div class="validity-title1">
            {{ $t("cart-expires-on") }} {{ cartValidityData }}.
          </div>
          <div class="validity-title2">
            {{ $t("secure-selection") }}
          </div>
        </div>
        <div class="validity_2" :style="{
          background: this.warning === 'EMPTY' ? '#861f1f' : '#F0AD4E',
        }" v-if="isNotificationEnabled">
          <div style="width: 60%">
            <div class="validity-title1" :style="{
              color: this.warning === 'EMPTY' ? 'white' : '#2c3e50',
            }">
              {{ this.warning === "EMPTY" ? $t("insufficient") : $t("low") }}
              {{ $t("credits") }}
            </div>
            <div class="validity-title2" :style="{
              color: this.warning === 'EMPTY' ? 'white' : '#2c3e50',
            }">
              {{ $t("please-recharge") }}
            </div>
          </div>
          <button class="warning-action" :style="{
            border:
              this.warning === 'EMPTY'
                ? '1.5px solid #fff'
                : '1.5px solid #2c3e50',
            color: this.warning === 'EMPTY' ? 'white' : '#2c3e50',
          }" @click="redirectToCredits">
            {{ $t("credits") }}
          </button>
        </div>
        <ul class="cart-items-wrapper" :style="{
          alignItems: updatedCartList.length === 0 ? 'center' : 'flex-start',
          justifyContent:
            updatedCartList.length === 0 ? 'center' : 'flex-start',
        }">
          <li class="cart-items" v-for="item in updatedCartList" :key="item.id">
            <div class="cart-item-data">
              <div class="info-wrapper-nav">
                {{ item?.order?.subjectName }}
                <div class="report-type-nav">
                  {{ $t("report-type") }}:
                  {{
                    item?.order?.reportType === "FRESH"
                      ? "Fresh Report"
                      : `${item?.order?.reportDetails?.report_label}(${$t('updated')} ${item?.order?.reportDetails?.report_dated})`
                  }}
                </div>
              </div>
              <i class="fa-solid fa-xmark" @click="deleteFromCart(item, false)"></i>
            </div>
          </li>
          <li v-if="updatedCartList.length === 0">
            <div class="cart-item-data">{{ $t("empty-cart") }}</div>
          </li>
        </ul>
      </template>
    </Drawer>
  </div>
  <LoaderPopup v-if="showLoaderPopup" />
  <session-expired-modal v-if="sessionExpired" @login="redirectToLogin" />
  <CartCheckout v-if="toggleCheckoutModal" @close="toggleCheckout" @placeorder="placeBulkOrder"
    :cartLoader="cartLoader" />
  <BulkOrderConfirmation v-if="bulkOrderConfirmation" @close="toggleBulkOrderConfirmation()"
    @goToSearch="goBackToSearch()" />
  <InviteNewUser v-if="showInviteNewUser" @close="toggleInviteNewUser()" />
  <Toastify ref="toastContainer" />
  <LanguageSelector v-if="isLanguageModalOpen" @close="closeLanguageModal" @select="handleLanguageSelect" />
  <div :lang="currentLanguage">
    <router-view />
  </div>
</template>
<script>
import VueCookies from "vue-cookies";
import LoaderPopup from "@/components/LoaderPopup.vue";
import ButtonProperty1SecondaryHoverFalse from "./components/HomePage/ButtonProperty1SecondaryHoverFalse/ButtonProperty1SecondaryHoverFalse.vue";
import ButtonProperty1PrimaryHoverFalse from "./components/HomePage/ButtonProperty1PrimaryHoverFalse/ButtonProperty1PrimaryHoverFalse.vue";
import { mapGetters, mapActions } from "vuex";
import SessionExpiredModal from "@/views/Modals/SessionExpiredModal.vue";
import InviteNewUser from "@/views/Modals/InviteNewUser.vue";
import {
  Logout,
  GetCartItems,
  DeleteFromCart,
  CheckoutCart,
  GetMyReferrals,
} from "./api/APIs";
import Drawer from "@/components/Drawer.vue";
import CartCheckout from "@/views/Modals/CartCheckout.vue";
import Toastify from "@/components/Toastify.vue";
import BulkOrderConfirmation from "@/views/Modals/BulkOrderConfirmation.vue";
import { Dropdown } from "floating-vue";
import "floating-vue/dist/style.css";
import CartAnimation from "@/assets/Animation/CartCheckout/CartCheckout.vue";
import config from "@/config.js";
import WarningMessage from "@/components/Warning.vue";
import { icons } from "@/assets/Icons/iconPaths";
import LanguageSelector from "@/views/Modals/LanguageSelector/LanguageSelector.vue";

export default {
  name: "App",
  components: {
    LoaderPopup,
    ButtonProperty1SecondaryHoverFalse,
    ButtonProperty1PrimaryHoverFalse,
    SessionExpiredModal,
    Drawer,
    CartCheckout,
    BulkOrderConfirmation,
    Toastify,
    Dropdown,
    CartAnimation,
    InviteNewUser,
    WarningMessage,
    LanguageSelector,
  },
  data() {
    return {
      guestUser: true,
      userName: "",
      showLoaderPopup: false,
      showLoginPopup: false,
      showLogoutPopup: false,
      showHistoryPopup: false,
      showOrdersPopup: false,
      showOrderDetailsPopup: false,
      orderDetailsModel: null,
      manualOrderForm: false,
      availableCredits: 0,
      userToken: null,
      showDrawer: false,
      showMobileMenu: window.innerWidth < 1023,
      mobile: window.innerWidth <= 767,
      clickOutsideDrawer: false,
      loginType: "",
      isProduction: process.env.NODE_ENV === "production",
      profileMenuActive: false,
      profileMenuActive2: false,
      carouselSettings: [
        {
          dots: false,
          infinite: true,
          arrows: false,
          speed: 10000,
          slidesToShow: 5,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 0,
          variableWidth: true,
          cssEase: "linear",
          rtl: false,
          pauseOnHover: false,
        },
        {
          dots: false,
          infinite: true,
          arrows: false,
          speed: 10000,
          slidesToShow: 5,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 0,
          variableWidth: true,
          cssEase: "linear",
          rtl: true,
          pauseOnHover: false,
        },
      ],
      cartItemsList: [],
      toggleCheckoutModal: false,
      cartLoader: false,
      bulkOrderConfirmation: false,
      isCheckoutOpen: false,
      tooltipTimeout: null,
      showBoxShadow: false,
      showBlinking: false,
      myReferralList: [],
      showInviteNewUser: false,
      isTooltipOpen: false,
      shareOptions: [
        {
          type: "link",
          label: "Share Link",
          icon: "assets/SocialMediaIcons/link.webp",
        },
        {
          type: "whatsapp",
          label: "Whatsapp",
          icon: "assets/SocialMediaIcons/whatsapp.webp",
        },
        {
          type: "linkedin",
          label: "LinkedIn",
          icon: "assets/SocialMediaIcons/linkedin.webp",
        },
        {
          type: "twitter",
          label: "Twitter",
          icon: "assets/SocialMediaIcons/x.webp",
        },
      ],
      referralCredits: config.referralCredits,
      drawerOpen: false,
      rewardsDrawerOpen: false,
      cartValidity: false,
      cartValidityData: null,
      isCollapsed: false,
      waringType: this.warning,
      flag: false,
      isLanguageModalOpen: false,
      allLanguages: [
        { code: "en", language: "English", region: "Global", flag: "gb" },
        // { code: "ar", language: "العربية", region: "Arab", flag: "ae" },
        // { code: "zh", language: "中文", region: "China", flag: "cn" },
        { code: "bn", language: "বাংলা.", region: "Bengali", flag: "bd" },
        // { code: "ja", language: "日本語", region: "Japanese", flag: "jp" },
        // { code: "ko", language: "한국어", region: "Korean", flag: "kr" },
        // { code: "th", language: "ไทย", region: "Thai", flag: "th" },
        // { code: "vi", language: "Tiếng Việt", region: "Vietnamese", flag: "vn" },

      ],
      enableTranslation:process.env.VUE_APP_ENABLE_TRANSLATION === "true"
    };
  },
  computed: {
    ...mapGetters(["cartCount", "sessionExpired", "userDetails", "warning",'toasts','selectedLanguage']),
    currentLanguage() {
      return this.$i18n.locale;
    },
    hasRewards() {
      return this.userDetails?.includes("REWARDS");
    },
    hasCredits() {
      return this.userDetails?.includes("CREDIT_MANAGEMENT");
    },
    hasCart() {
      return this.userDetails?.includes("ORDERS");
    },
    isNotificationEnabled() {
      return this.warning && this.warning !== "NORMAL";
    },
    options() {
      const routeMap = {
        SEARCH: {
          label: this.$t("search"),
          route: "/search",
          params: "",
          icon: "search",
        },
        DASHBOARD: {
          label: this.$t("dashboard"),
          route: "/bankuserdashboard",
          params: "",
          icon: "dashboard",
        },
        ORDERS: {
          label: this.$t("orders"),
          route: "/MyOrders",
          params: "?tab=active-orders",
          icon: "orders",
        },
        TEAMS: {
          label: this.$t("manage-team"),
          route: "/profile",
          params: "?tab=teams",
          icon: "team",
        },
        HELPDESK: {
          label: this.$t("helpdesk"),
          route: "/contact",
          params: "",
          icon: "helpdesk",
        },
        "ADMIN-CONSOLE": {
          label: this.$t("admin-portal"),
          route: "/banks-&-hq",
          params: "",
          icon: "bank",
        },
        "BLOG-EDITOR": [
          {
            label: this.$t("post-blog"),
            route: "/blog-editor",
            params: "",
            icon: "blog",
          },
          {
            label: this.$t("edit-blog"),
            route: "/existing-blogs",
            params: "",
            icon: "blog",
          },
        ],
      };
      return this.userDetails
        ?.flatMap((option) => routeMap[option] || [])
        .filter(Boolean);
    },
    referralCode() {
      return this.$store.getters.referralCode;
    },
    credits() {
      return this.$store.getters.credits;
    },
    updatedCartList() {
      return this.cartItemsList;
    },
    updatedInvites() {
      return this.myReferralList;
    },
    currentScreenName() {
      const routeName = this.$route.name || "";
      const localizedNames = this.$route.meta.localizedNames || {};
      let localizedName = localizedNames[this.selectedLanguage?.code];
      if (localizedName) {
        localizedName = localizedName
          .replace(/-/g, " ")
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .replace(/^./, (str) => str.toUpperCase());
      } else {
        localizedName = routeName
          .replace(/-/g, " ")
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .replace(/^./, (str) => str.toUpperCase());
      }
      return localizedName;
    },
    // authorisedUserType() {
    //   return ["HEAD", "MANAGER"].includes(VueCookies.get("user-type"));
    // },
    userType() {
      return ["HEAD", "MANAGER", "EXECUTIVE"].includes(
        VueCookies.get("user-type")
      );
    },
    isRetail() {
      return VueCookies.get("user-type") === "RETAIL";
    },
    isAdmin() {
      return VueCookies.get("user-type") === "BLOG-ADMIN";
    },
    userTokenAvailable() {
      return this.userToken !== null;
    },
    appClass() {
      return this.userTokenAvailable ? "#app.logged-in" : "#app.not-logged-in";
    },
    isActiveRoute() {
      return (path, query = {}) => {
        const isSamePath = this.$route.path === path;
        const isSameQuery = Object.keys(query).every(
          (key) => this.$route.query[key] === query[key]
        );
        const hasNoQuery = Object.keys(query).length === 0;
        return isSamePath && (isSameQuery || hasNoQuery);
      };
    },
    truncatedUserName() {
      const user = this.userName || VueCookies.get("user");
      if (user) {
        return user;
      } else {
        return "";
      }
    },
  },
  beforeMount() {
    this.userName = VueCookies.get("user");
    this.userToken = VueCookies.get("token");
  },
  watch: {
    toasts: {
    handler(newToasts) {
      if (newToasts.length > 0) {
        setTimeout(() => {
          newToasts.forEach((toast) => {
            if (this.$refs.toastContainer) {
              this.$refs.toastContainer.addToast(toast.message, {
                type: toast.type
              });
            } else {
              console.warn("Toast container not found.");
            }
          });
          this.$store.commit('clearToasts');
        }, 0);
      }
    },
    deep: true, 
    immediate: true
  },
  selectedLanguage: {
    handler(newLang) {
      this.$store.dispatch("updateSelectedLanguage", newLang);
      this.$i18n.locale = newLang.code;
     },
    deep: true
  },
    $route(to) {
      if (to.path !== "/login") {
        this.handleMountedLogic();
      }
      if (
        VueCookies.get("token") &&
        VueCookies.get("order-count") == 0 &&
        localStorage.getItem("effectTriggered") === "false"
      ) {
        this.triggerBoxShadow();
        localStorage.setItem("effectTriggered", true);
      }
    },
  },
  mounted() {    
    this.getStoredLanguage();
    const storedState = sessionStorage.getItem("isCollapsed");
    if (storedState !== null) {
      this.isCollapsed = storedState === "true";
    }
    if (this.$route.path !== "/login") {
      this.handleMountedLogic();
    }
    window.addEventListener("resize", this.handleResize);
    document.addEventListener("click", this.handleClickOutside);
    document.addEventListener("click", this.handleClickOutside2);
    document.addEventListener("scroll", this.handleScroll);
    // Watch for changes in localStorage and update state
  window.addEventListener("storage", (event) => {
    if (event.key === "selectedLanguage") {
      const newLang = JSON.parse(event.newValue);
      this.$store.dispatch("updateSelectedLanguage", newLang);
      this.$i18n.locale = newLang.code;
    }
  });
},
 
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
    document.removeEventListener("click", this.handleClickOutside);
    document.removeEventListener("click", this.handleClickOutside2);
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("storage", this.handleStorageChange);

  },
  updated() {
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapActions(["updateCartCount"]),
    getStoredLanguage(){
      const savedLanguage = JSON.parse(localStorage.getItem("selectedLanguage"));
    if (savedLanguage) {
      this.$store.dispatch("updateSelectedLanguage", savedLanguage);
      this.$i18n.locale = savedLanguage.code;
    } else {
      const userLanguage = navigator.language?.split("-")[0]?.toLowerCase();
      const foundLanguage = this.allLanguages.find(lang => lang?.code === userLanguage);
      // If language is present, then set to that code
      if (foundLanguage) {
        this.$store.dispatch("updateSelectedLanguage", foundLanguage);
        this.$i18n.locale = foundLanguage?.code;
      } else { //If not, then default to english
        const lang=this.allLanguages.find(lang => lang?.code === 'en');
        this.$store.dispatch("updateSelectedLanguage", lang);
        this.$i18n.locale = 'en';
      }
    }
    },
    openLanguageModal() {
      this.isLanguageModalOpen = true;
    },
    closeLanguageModal() {
      this.isLanguageModalOpen = false;
    },
    handleLanguageSelect(language) {
      this.$i18n.locale = language.code;
      this.isLanguageModalOpen = false;
    },
    getIcon(iconName) {
      return icons[iconName.split(".")[0]] || "";
    },
    redirectToCredits() {
      this.drawerOpen = false;
      this.$router.push({ path: "/profile", query: { tab: "credits" } });
    },
    goBackToSearch() {
      this.$router.push("/search");
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
      sessionStorage.setItem("isCollapsed", this.isCollapsed);
    },
    initiateTokenExpiryHandler() {
      const tokenCreationTime = VueCookies.get("token-creation-time");
      const currentTime = Date.now();

      if (tokenCreationTime) {
        const tokenAge = currentTime - new Date(tokenCreationTime).getTime();
        const expiryTime = 12 * 60 * 60 * 1000;
        const remainingTime = expiryTime - tokenAge;

        if (remainingTime > 0) {
          setTimeout(() => {
            this.$store.dispatch("processLogout");
          }, remainingTime);
        } else {
          this.$store.dispatch("processLogout");
        }
      } else {
        console.warn("Token creation time not found.");
      }
    },

    handleScroll() {
      if (this.profileMenuActive2) {
        this.profileMenuActive2 = false;
      }
    },
    navBarCkicked() {
      this.drawerOpen = false;
      this.rewardsDrawerOpen = false;
    },
    toggleTooltip() {
      this.isTooltipOpen = !this.isTooltipOpen;
    },
    handleShare(type) {
      this.toggleTooltip();
      const baseUrl = window.location.origin;
      const referralMessage=`${this.$t("referal-message-1")} ${this.referralCode}, 
      ${this.$t("referal-message-2")} ${this.referralCredits} ${this.$t("referal-message-3")} :) ${this.$t("referal-message-4")} 
      ${baseUrl}/login?type=SignUp&refCode=${this.referralCode}`
      const referralLink = `${baseUrl}/login?type=SignUp&refCode=${this.referralCode}`;
      if (type === "link") {
        this.copyToClipboard(true);
      } else {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        let shareUrl;
        switch (type) {
          case "whatsapp":
            shareUrl = `https://wa.me/?text=${encodeURIComponent(
              referralMessage
            )}`;
            break;
          case "linkedin":
            shareUrl = `https://www.linkedin.com/sharing/share-offsite/?text=${encodeURIComponent(
              referralMessage
            )}`;
            break;
          case "twitter":
            shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
              referralMessage
            )}`;
            break;
        }

        if (isMobile) {
          const appUrl = this.getAppUrl(type, referralMessage, referralLink);
          window.location.href = appUrl;
          setTimeout(() => {
            window.location.href = shareUrl;
          }, 1000);
        } else {
          window.open(shareUrl, "_blank");
        }
      }
    },
    getAppUrl(type, referralMessage) {
      switch (type) {
        case "whatsapp":
          return `whatsapp://send?text=${encodeURIComponent(referralMessage)}`;
        case "linkedin":
          return `linkedin://shareArticle?mini=true&text=${encodeURIComponent(
            referralMessage
          )}`;
        case "twitter":
          return `twitter://post?message=${encodeURIComponent(
            referralMessage
          )}`;
        default:
          return "";
      }
    },
    toggleInviteNewUser() {
      this.showInviteNewUser = !this.showInviteNewUser;
    },
    copyToClipboard(message) {
      const baseUrl = window.location.origin;
const referralMessage=`${this.$t("referal-message-1")} ${this.referralCode}, 
      ${this.$t("referal-message-2")} ${this.referralCredits} ${this.$t("referal-message-3")} :) ${this.$t("referal-message-4")} 
      ${baseUrl}/login?type=SignUp&refCode=${this.referralCode}`
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard
          .writeText(message ? referralMessage : this.referralCode)
          .then(() => {
            alert(
              message
                ? this.$t('copied-clipboard')
                : this.$t('referal-code-copied')
            );
          })
          .catch((err) => {
            console.error("Failed to copy: ", err);
          });
      } else {
        const textarea = document.createElement("textarea");
        textarea.value = message ? referralMessage : this.referralCode;
        textarea.style.position = "fixed";
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        try {
          document.execCommand("copy");
          alert(this.$t('referal-code-copied'));
        } catch (err) {
          console.error(
            message
              ? this.$t('copied-clipboard')
              : "Fallback: Oops, unable to copy",
            err
          );
        }
        document.body.removeChild(textarea);
      }
    },
    triggerBoxShadow() {
      this.showBoxShadow = true;
      this.showBlinking = true;
      setTimeout(() => {
        this.showBoxShadow = false;
        this.showBlinking = false;
      }, 20000);
    },
    toggleCheckoutTooltip() {
      if (this.tooltipTimeout) {
        clearTimeout(this.tooltipTimeout);
      }

      this.isCheckoutOpen = !this.isCheckoutOpen;

      if (this.isCheckoutOpen) {
        this.tooltipTimeout = setTimeout(() => {
          this.isCheckoutOpen = false;
        }, 3000);
      }
    },
    async toggleWishlist() {
      try {
        await this.GetCartItems();
        if (this.mobile) {
          this.showDrawer = false;
        }
        this.drawerOpen = !this.drawerOpen;
      } catch (error) {
        console.error("Error fetching cart items:", error);
      }
    },
    async toggleRewards() {
      try {
        if (this.rewardsDrawerOpen === false) {
          await this.MyReferrals();
        }
        this.rewardsDrawerOpen = !this.rewardsDrawerOpen;
      } catch (error) {
        console.error("Error fetching rewards:", error);
      }
    },
    async toggleCheckout() {
      try {
        this.toggleCheckoutModal = !this.toggleCheckoutModal;
      } catch (error) {
        console.error("Error fetching cart items:", error);
      }
    },
    toggleBulkOrderConfirmation() {
      this.bulkOrderConfirmation = !this.bulkOrderConfirmation;
    },
    async GetCartItems() {
      try {
        const response = await GetCartItems();
        if (response.cart) {
          this.cartItemsList = response.cart;
          this.cartValidity = response.cartValidityExpiring
            ? response.cartValidityExpiring
            : false;
          this.cartValidityData = response.cartValidityDate
            ? response.cartValidityDate
            : null;
          this.updateCartCount(response?.size);
          this.flag = response.insufficientCreditsFlag;
          if (response.lowCreditBalanceFlag)
            this.$store.dispatch("setWarning", response.lowCreditBalanceFlag);
        } else {
          return this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    async MyReferrals() {
      try {
        const response = await GetMyReferrals();
        if (response) {
          this.myReferralList = response;
        } else {
          return this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    async placeBulkOrder(deliveryEmail, isDiscountApplied) {
      this.cartLoader = true;
      const payload =
        this.isRetail === true
          ? {
            email: deliveryEmail,
            isDiscountApplied: isDiscountApplied,
          }
          : {
            email: deliveryEmail,
          };
      try {
        const response = await CheckoutCart(payload);
        if (response.message === "SUCCESS") {
          if (this.isRetail === true && response?.result?.paymentRequired) {
            this.cartLoader = false;
            this.GetCartItems();
            this.toggleCheckout();
            this.drawerOpen = false;
            window.location.href = response?.result?.paymentLink;
          } else {
            this.cartLoader = false;
            this.GetCartItems();
            this.toggleCheckout();
            this.drawerOpen = false;
            this.toggleBulkOrderConfirmation();
          }
        } else {
          return this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    async deleteFromCart(item, clearCart) {
      const payload = clearCart
        ? { clearCart: clearCart }
        : { itemId: item.id };
      try {
        const response = await DeleteFromCart(payload);
        if (response.message === "Deleted from cart") {
          this.GetCartItems();
        } else if (response.message === "Cart cleared") {
          this.GetCartItems();
        } else {
          return this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    redirectToLogin() {
      this.tokenExpired();
      this.$store.commit("setSessionExpired", false);
    },
    profileClicked() {
      this.profileMenuActive = !this.profileMenuActive;
    },
    profileClicked2() {
      this.profileMenuActive2 = !this.profileMenuActive2;
    },
    handleClickOutside2(event) {
      if (
        this.profileMenuActive2 &&
        this.$refs.profileMenu2 &&
        this.$refs.profile2 &&
        !this.$refs.profileMenu2.contains(event.target) &&
        !this.$refs.profile2.contains(event.target)
      ) {
        this.profileMenuActive2 = false;
      }
    },
    handleClickOutside(event) {
      if (
        this.profileMenuActive &&
        this.$refs.profileMenu &&
        this.$refs.profile &&
        !this.$refs.profileMenu.contains(event.target) &&
        !this.$refs.profile.contains(event.target)
      ) {
        this.profileMenuActive = false;
      }
    },
    goToProfile() {
      this.profileMenuActive = false;
      this.profileMenuActive2 = false;
      this.$router.push("/profile?tab=myaccount");
    },
    redirectToAboutUs() {
      this.$router.push("/aboutus");
      this.showDrawer = false;
    },
    redirectToInstitutions() {
      this.$router.push("/institutions");
      this.showDrawer = false;
    },
    redirectToBlogs() {
      this.$router.push("/blogs");
      this.showDrawer = false;
    },
    redirectToProducts() {
      this.$router.push("/product");
      this.showDrawer = false;
    },
    redirectToHome() {
      if (this.$route.path === "/") {
        location.reload();
      } else {
        this.$router.push("/");
        this.showDrawer = false;
      }
    },
    handleMountedLogic() {
      this.userToken = VueCookies.get("token");
      this.userName = VueCookies.get("user");
    },
    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
    },
    handleResize() {
      this.showMobileMenu = window.innerWidth < 1023;
    },
    showLoader() {
      this.showLoaderPopup = true;
    },
    hideLoader() {
      this.showLoaderPopup = false;
    },
    showLogin(actionType) {
      this.loginType = actionType;
      this.$router.push({ path: "/login", query: { type: actionType } });
    },
    async processLogout() {
      const user = VueCookies.get("email");
      const password = VueCookies.get("password");
      try {
        const response = await Logout(user, password);
        if (response) {
          this.profileMenuActive = false;
          localStorage.setItem("effectTriggered", false);
          this.$store.dispatch("clearData");
          const cookiesToRemove = [
            "token",
            "user",
            "password",
            "email",
            "user-type",
            "id",
            "designation",
            "designationId",
            "phone",
            "order-count",
            "entityId",
            "branch",
            "RM",
            "phone_w_countryCode",
            "token-creation-time",
            "EntityType",
          ];
          if (sessionStorage.getItem("isCollapsed")) {
            sessionStorage.removeItem("isCollapsed");
          }
          cookiesToRemove.forEach((cookie) => {
            if (VueCookies.get(cookie)) {
              VueCookies.remove(cookie);
            }
          });
          const sessionStorageToClear = [
            "isCollapsed",
            "allCountriesList",
            "selectedCountry",
          ];
          sessionStorageToClear.forEach((variable) => {
            if (sessionStorage.getItem(variable)) {
              sessionStorage.removeItem(variable);
            }
          });
          this.userToken = null;
          this.userName = "";
          window.location.href = "/";
        }
      } catch (error) {
        console.error(error);
      }
    },
    async tokenExpired() {
      const user = VueCookies.get("email");
      const password = VueCookies.get("password");
      try {
        const response = await Logout(user, password);
        if (response) {
          this.profileMenuActive = false;
          this.profileMenuActive2 = false;
          localStorage.setItem("effectTriggered", false);
          this.$store.dispatch("clearData");
          const cookiesToRemove = [
            "token",
            "user",
            "password",
            "email",
            "user-type",
            "id",
            "designation",
            "designationId",
            "phone",
            "order-count",
            "entityId",
            "branch",
            "RM",
            "phone_w_countryCode",
            "token-creation-time",
            "EntityType",
          ];

          cookiesToRemove.forEach((cookie) => {
            if (VueCookies.get(cookie)) {
              VueCookies.remove(cookie);
            }
          });
          this.userToken = null;
          this.userName = "";
          this.$router.push({ path: "/login", query: { type: "Login" } });
        }
      } catch (error) {
        console.error(error);
      }
    },
    getCDNlink(fileKey) {
      let baseUrl = "";
      if (
        process.env.NODE_ENV === "development" ||
        process.env.NODE_ENV === "staging"
      ) {
        baseUrl = config.stagingCDN;
      } else if (process.env.NODE_ENV === "production") {
        baseUrl = config.prodCDN;
      }

      return `${baseUrl}${fileKey}`;
    },
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}

@keyframes blinking {
  0% {
    box-shadow: 0px 0px 24px 0px rgba(143, 200, 255, 0);
  }

  50% {
    box-shadow: 0px 0px 24px 0px rgba(143, 200, 255, 1);
  }

  100% {
    box-shadow: 0px 0px 24px 0px rgba(143, 200, 255, 0);
  }
}

#app {
  font-family: "Montserrat-Medium", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f2f8ff;
  min-height: 100vh;
  transition: margin-top 0.3s;
}

#app.logged-in {
  margin-top: 0;
}

.link {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600 !important;
}

#app.not-logged-in {
  margin-top: 60px;
}

.icon-image {
  width: 25px;
  height: 25px;
}

.warning-action {
  border-radius: 10px;
  background: transparent;
  cursor: pointer;
}

.navbar-title {
  color: var(--Secondary-Grey-900, #2b3674);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.48px;
}

.wrapper-class {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.referral-title-2 {
  color: var(--Secondary-Grey-900, #2b3674);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.refer {
  display: flex;
  padding: 5px 14px;
  justify-content: center;
  align-items: center;
  gap: 14px;
  border-radius: 13px;
  background: #11365a;
  color: #fcd600;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  cursor: pointer;
}

.social-media-icons {
  width: 20px;
}

.tooltip-content-profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 8px;
  width: 140px;
  gap: 13px;
}

.tooltip-message-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  gap: 10px;
  cursor: pointer;
}

.available-credits {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid rgb(181, 181, 181);
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  padding: 5px 15px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  min-width: 85px;
}

.share-invitation-main {
  display: flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 13px;
  border: 2px solid #11365a;
  background: #fff;
  color: var(--Dark-Blue, #11365a);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
  min-width: 85px;
}

.invitationstatus-main {
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 33px;
}

.invitation-status-main {
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.14px;
  text-transform: capitalize;
}

.referred-email-main {
  color: var(--Dark-Blue, #11365a);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.32px;
  opacity: 0.9px;
}

.cart-items-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  height: 65%;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 0;
}

.cart-icon-wrapper {
  position: relative;
  display: inline-block;
}

.info-wrapper-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
}

.cart-item-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
}

.cart-count {
  position: absolute;
  top: -4px;
  right: -8px;
  background-color: #11365a;
  color: white;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.2px;
  min-width: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  width: 18px;
  box-sizing: border-box;
}

.tooltip-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 180px;
  padding-top: 8px;
  /* gap: 15px; */
}

.tooltip-checkout {
  background-color: #11365a;
  border-radius: 10px;
  color: white;
  height: 40px;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 18px;
  font-weight: 500;
  align-self: stretch;
  cursor: pointer;
  outline: none;
  width: 100%;
  text-align: center;
}

.cart-items {
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.2px;
  align-self: stretch;
  padding: 12px;
  border-radius: 16px;
  border: 1px solid rgba(24, 195, 223, 0.2);
  background: #fff;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.08);
}

.report-type-nav {
  font-size: 14px;
  font-weight: 500;
  color: #3e4855;
  opacity: 0.5;
}

.wishlist-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.wishlist-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-family: "Montserrat-Medium", sans-serif;
  cursor: pointer;
}

.clear-cart {
  color: var(--Main-COlor, #26bbc2);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  cursor: pointer;
}

input[type="text"],
input[type="textarea"] {
  height: 30px;
  border-radius: 0.5em;
  box-shadow: 0 1px 2px #aaa;
  margin: 8px 0;
  padding: 0px 20px;
}

textarea {
  height: 100px;
  border-radius: 0.5em;
  box-shadow: 0 1px 2px #aaa;
  margin: 8px 0;
  padding: 7px 20px;
}

button {
  font-weight: bold;
  font-size: 15px;
  width: 100px;
  height: 35px;
  border-radius: 0.5em;
  color: #ffffff;
  background-color: #d43451;
}

#userMenu {
  overflow: hidden;
  background-color: white;
  border-bottom: 1px solid #82d7db;
  position: sticky;
  top: 0;
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  flex-wrap: wrap;
}

#footer {
  margin-top: 20rem;
  padding-top: 1rem;
  overflow: hidden;
  display: flex;
  background-color: #ffffff;
  align-items: flex-start;
  border-top: 1px solid #82d7db;
  bottom: 0;
  width: 100%;
  flex-direction: column;
}

#footer a {
  float: right;
  display: block;
  color: #1d375b;
  font-weight: bold;
  text-align: center;
  padding: 16px 16px;
  text-decoration: none;
}

#userMenu p {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  text-decoration: none;
  margin-left: 16px;
}

#userMenu a {
  float: right;
  display: flex;
  color: #1d375b;
  font-weight: bold;
  text-align: center;
  padding: 16px 16px;
  text-decoration: none;
  position: relative;
  /* left: -3%; */
}

#userMenu a:hover {
  background: #f1f8f8;
  color: black;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup .popup-inner {
  background: transparent;
  padding: 32px;
}

.scBrand_list {
  display: flex;
  flex-direction: row;
}

.scBrand_list_One {
  display: flex;
  flex-direction: row;
}

.frame-35582 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 90%;
  position: relative;
}

.frame-355821 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  width: 90%;
  position: relative;
}

.navbar-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: 5px;
}

.frame-7 {
  display: flex;
  flex-direction: row;
  gap: 36px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.frame-1 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

.image-2 {
  flex-shrink: 0;
  width: 173px;
  height: 40px;
  position: relative;
  object-fit: cover;
}

.frame-6 {
  border-radius: 16px;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

.solutions {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  cursor: pointer;
}

.iconly-sharp-arrow-down-2-instance {
  flex-shrink: 0 !important;
}

.frame-5 {
  border-radius: 16px;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

.pricing {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: relative;
}

.frame-4 {
  border-radius: 16px;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

.partnership {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  cursor: pointer;
}

.frame-72 {
  border-radius: 16px;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

.blogs {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  cursor: pointer;
}

.frame-8 {
  border-radius: 16px;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

.tools {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: relative;
}

.tooltip-message {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.frame-9 {
  border-radius: 16px;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

.about {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  cursor: pointer;
}

.frame-82 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  position: relative;
}

.logout-button {
  display: flex;
  min-width: 150px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 13px;
  background: #11365a;
  height: auto;
  border: none;
}

.hamburger-menu {
  cursor: pointer;
  margin-top: 10px;
}

.drawer-navigation {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 999;
  width: 30%;
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
}

.drawer-navigation-loggedin {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: white;
  z-index: 999;
  gap: 8px;
  width: 60%;
  padding: 20px;
  box-shadow: 4px 5px 15px rgba(0, 0, 0, 0.2);
}

.drawer-item {
  padding: 10px 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}

.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  backdrop-filter: blur(10px);
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.bg-b-lue-mobile {
  background: rgba(0, 0, 0, 8%);
  backdrop-filter: blur(10px);
  z-index: 999;
  position: fixed;
  top: 70px;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
}

.router-link-no-hover {
  cursor: pointer;
  background-color: transparent !important;
}

.dashboard-navigation-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 18vw;
  height: 100vh;
  z-index: 1000;
  flex-wrap: wrap;
  background-color: white;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.18);
  pointer-events: auto;
  transition: width 0.3s ease;
  position: relative;
}

.dashboard-navigation-container.collapsed {
  width: 60px;
  justify-content: center;
  /* display: none; */
  /* overflow: hidden; */
}

.collapse-icon {
  position: absolute;
  top: 39px;
  right: -15px;
  background-color: white;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  z-index: 100;
  border: 1px solid #11365a;
  transition: transform 0.3s ease;
}

.collapse-icon:hover {
  background-color: #11365a;
  border: 1px solid transparent;
  color: white !important;
}

.collapse-icon i.iconly-sharp-arrow-down-2-instance {
  color: #11365a;
}

.collapse-icon.rotated {
  transform: rotate(180deg);
}

.dasboard-nav-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 5%;
  width: 18vw;
  height: 95%;
  position: relative;
  gap: 14px;
}

.navbar-optins-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.navbar-optins {
  /* border-radius: 16px; */
  /* padding: 12px 0; */
  gap: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 82vh;
  overflow-y: scroll;
  scrollbar-width: none;
}

.navbar-option {
  border-radius: 16px;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  text-decoration: none;
}

.navbar-option:hover {
  background-color: #11365a1f;
  border-radius: 16px;
}

.active-link {
  background: #11365a !important;
  border-radius: 16px;
}

.option-lable {
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 15px;
  line-height: 28px;
  font-weight: 600;
  position: relative;
}

.line-16 {
  border-style: solid;
  border-color: var(--secondary-grey-300, #f4f7fe);
  border-width: 1px 0 0 0;
  align-self: stretch;
  flex-shrink: 0;
  height: 0px;
  position: relative;
}

.profile-button {
  background: #ffffff;
  border-radius: 16px;
  border: 1px solid rgba(17, 54, 90, 0.5);
  padding: 5px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
}

.profile-button:hover {
  background: #11365a1f;
}

.user-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.user-name {
  color: var(--dark-blue, #11365a);
  text-align: left;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  position: relative;
  overflow-wrap: anywhere;
}

.profile-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 15px;
  border: 2px solid rgba(38, 187, 194, 0.4);
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.25);
  width: 25vw;
  background-color: white;
  top: -133px;
  overflow: hidden;
}

.profile-menu-2 {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 15px;
  border: 2px solid rgba(38, 187, 194, 0.4);
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.25);
  width: 25vw;
  background-color: white;
  top: 40px;
  right: -20px;
  overflow: hidden;
}

.profile-menu-item {
  display: flex;
  padding: 16px;
  align-items: center;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: var(--dark-blue, #11365a);
  text-align: left;
  align-self: stretch;
  cursor: pointer;
}

.profile-menu-item:hover {
  background-color: #26bac237;
}

.menu-wrapper {
  width: 26px;
  height: 18px;
  cursor: pointer;
  position: relative;
}

.menu-bar {
  position: absolute;
  width: 30px;
  height: 2px;
  background: #000000;
}

.one {
  top: 0px;
  animation-delay: 0.1s;
  transition: all 0.3s;
}

.two {
  top: 8px;
  transition: all 0.3s;
}

.three {
  top: 16px;
  transition: all 0.3s;
}

.validity {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 13px;
  background: var(--Danger-Light-BG, #ffc9c9);
}

.validity_2 {
  display: flex;
  padding: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 13px;
  background: var(--Danger-Light-BG, #ffc9c9);
}

.validity-title1 {
  color: var(--Danger-Text, #861f1f);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-align: left;
}

.validity-title2 {
  color: var(--Danger-Text, #861f1f);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
}

.select-language1 {
  display: inline-block;
  cursor: pointer;
}

.language-capsule {
  border: 3px solid #11365A;
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 5px 12px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: rgb(226, 234, 245);
  justify-content: center;
  width: -webkit-fill-available;
}

.flag-icon {
  width: 20px;
  height: 15px;
  margin-right: 8px;
}

.language-capsule-name {
  font-weight: bold;
  color: #11365A;
  text-transform: uppercase;
}

@keyframes slideOut {
  0% {
    width: 100%;
    left: 0%;
    right: auto;
  }

  50% {
    width: 0%;
    left: 0%;
    right: auto;
  }

  51% {
    width: 0%;
    right: 0%;
    left: auto;
  }

  100% {
    width: 100%;
    right: 0%;
    left: auto;
  }
}

.menu-wrapper:hover .menu-bar.active {
  animation: none;
}

.active .one {
  top: 50%;
  left: 0%;
  transform: rotate(45deg);
}

.active .two {
  opacity: 0;
  transition: opacity 0.2s;
}

.active .three {
  top: 50%;
  left: 0%;
  transform: rotate(-45deg);
}

.reward {
  height: 23px;
  width: 23px;
}

@media only screen and (max-width: 767px) {
  #userMenu a {
    padding: 8px 8px;
    width: -webkit-fill-available;
  }

  .image-2 {
    width: 110px;
    height: auto;
  }

  .menu-bar {
    width: 28px;
  }

  .profile-menu {
    width: 89%;
  }

  .wishlist-title h3 {
    margin-block-start: 0em;
    margin-block-end: 0em;
  }

  .cart-items {
    font-size: 14px;
  }

  .validity-title1,
  .validity-title2 {
    font-size: 14px;
  }

  .profile-menu-2 {
    position: fixed;
    width: 44vw;
    top: 60px;
    right: 20px;
  }

  .select-language1 {
    margin-top: 7px;
  }

  .language-capsule {
    border: 2px solid #11365A;
    display: flex;
    align-items: center;
    border-radius: 13px;
    padding: 2px 8px;
    cursor: pointer;
    transition: all 0.3sease;
    background: rgb(226, 234, 245);
    justify-content: center;
    width: -webkit-fill-available;
    height: 25px;
  }

  .flag-icon {
    width: 10px;
    height: 10px;
    margin-right: 3px;
  }

  .language-capsule-name {
    font-size: 10px;
  }

}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  #userMenu a {
    padding: 0;
  }

  .image-2 {
    width: 136.5px;
    height: auto;
  }

  .bg-b-lue-mobile {
    top: 81px;
  }

  .drawer-navigation-loggedin {
    width: 30%;
  }

  #userMenu a {
    padding: 8px 8px;
    width: -webkit-fill-available;
  }

  .profile-menu {
    width: 89%;
  }

  .validity-title1,
  .validity-title2 {
    font-size: 14px;
  }

  .profile-menu-2 {
    position: fixed;
    width: 20vw;
    top: 60px;
    right: 20px;
  }

  .select-language1 {
    margin-top: 7px;
  }

  .language-capsule {
    border: 2px solid #11365A;
    display: flex;
    align-items: center;
    border-radius: 13px;
    padding: 2px 8px;
    cursor: pointer;
    transition: all 0.3sease;
    background: rgb(226, 234, 245);
    justify-content: center;
    width: -webkit-fill-available;
    height: 25px;
  }

  .flag-icon {
    width: 10px;
    height: 10px;
    margin-right: 3px;
  }

  .language-capsule-name {
    font-size: 10px;
  }
}

@media only screen and (min-width: 1023px) and (max-width: 1220px) {

  .solutions,
  .pricing,
  .partnership,
  .blogs,
  .tools,
  .about {
    font-size: 16px;
  }

  .frame-82 {
    gap: 20px;
    justify-content: space-between;
  }

  .router-link-no-hover {
    padding: 0 !important;
  }
}

@media only screen and (min-width: 1024px) {}
</style>
<style scoped>
.select-language {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}
</style>
