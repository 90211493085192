<template>
  <div
    :class="[
      'notFound',
      { 'logged-in': !userTokenAvailable, 'not-logged-in': userTokenAvailable },
    ]"
  >
    <div class="page-404" :style="{ height: userTokenAvailable ? '85vh' : '100vh' }">
      <Error404 />
      <div class="title">{{$t("not-found-code")}}</div>
      <h1 class="message">{{$t("oops-not-found")}}</h1>
      <div class="description">
       {{$t("page-doesnt-exist")}}...
      </div>
      <div class="buttons-wrapper">
        <div v-if="userTokenAvailable" class="home-button" @click="goHome">
          {{$t("goto-home")}}
        </div>
        <div class="search-button" @click="goSearch">{{$t("search")}}</div>
      </div>
    </div>
    <Footer v-if="userTokenAvailable"/>
  </div>
</template>
<script>
import Error404 from "@/assets/Animation/404Error/404Error.vue";
import Footer from "@/components/HomePage/Footer/Footer.vue";
import VueCookies from "vue-cookies";

export default {
  components: {
    Error404,
    Footer,
  },
  data() {
    return {
      userToken: null,
    };
  },
  beforeMount() {
    this.userToken = VueCookies.get("token");
  },
  computed: {
    userTokenAvailable() {
      return this.userToken == null;
    },
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
    goSearch() {
      this.$router.push("/search");
    },
  },
};
</script>
<style scoped>
.notFound,
.notFound * {
  box-sizing: border-box;
}
.notFound {
  background: linear-gradient(to left, #ecf4ff, #ecf4ff),
    linear-gradient(to left, #e7ebf0, #e7ebf0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* background-color: white; */
}
.page-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: 85vh; */
  gap: 1vh;
}
.logged-in {
  margin-left: 18vw;
  padding-top: 55px;
}
.not-logged-in {
  width: 100%;
}
.title {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.message {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0;
}
.description {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
}
.buttons-wrapper {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  justify-content: center;
}
.home-button {
  display: flex;
  border-radius: 13px;
  background: #11365a;
  min-width: 150px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.18px;
  text-transform: capitalize;
  cursor: pointer;
}
.search-button {
  display: flex;
  border-radius: 13px;
  border: 2px solid #11365a;
  background: #fff;
  min-width: 150px;
  padding: 14.3px 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.18px;
  text-transform: capitalize;
  cursor: pointer;
}
</style>
