<template>
  <div
    :class="[
      'table-container',
      {
        'logged-in': !isMobile,
        'not-logged-in': isMobile,
        collapsed: this.$root.isCollapsed && !isMobile,
        notification: this.$root.warning && this.$root.warning !== 'NORMAL' && !isMobile,
      },
    ]"
  >
    <div class="plan-info">
      <div class="heading">
        <div class="back-button" @click="goBack">
          <i class="fa-solid fa-angle-left" style="margin-right: 10px"></i>
          {{ $t('back') }}
        </div>
        <div class="credit-plan-title">
          {{ $t('review_reconcile_credit_entries') }}
        </div>
      </div>
      <form class="selection-form">
        <div class="selection-group">
          <label for="invoiceNumber" class="selection-label">
            {{ $t('invoice_number') }}
          </label>
          <div class="invoice-field-wrapper">
            <input
              type="text"
              id="invoiceNumber"
              class="rate-input no-arrows"
              :placeholder="$t('enter_invoice_number')"
              aria-label="Enter Invoice Number"
              v-model="invoiceNumber"
            />
            <button
              type="button"
              class="verify-button"
              :style="{
                opacity: !isInvoiceNumberPresent || isVerifying ? 0.5 : 1,
              }"
              :disabled="!isInvoiceNumberPresent || isVerifying"
              @click="GetInvoiceDetails"
              tabindex="0"
              aria-label="Verify Invoice"
            >
              {{ $t('verify') }}
            </button>
          </div>
        </div>
      </form>
      <section class="subscription-section">
        <div class="subscription-title">{{ $t('name') }}</div>
        <p class="subscription-text">
          {{ invoiceDetails?.customer || "-" }}
        </p>
      </section>
      <section class="subscription-section">
        <div class="subscription-title">{{ $t('current_credit_balance') }}</div>
        <p class="subscription-text">
          {{ invoiceDetails?.currentAvailableCredits || "-" }}
        </p>
      </section>
      <section class="subscription-section">
        <div class="subscription-title">{{ $t('credits_purchased') }}</div>
        <p class="subscription-text">
          {{ invoiceDetails?.amount || "-" }}
        </p>
      </section>
      <div class="action-buttons">
        <button
          type="button"
          class="action-button cancel-button"
          @click="handleCancel"
          tabindex="0"
          aria-label="Cancel action"
        >
          {{ $t('cancel') }}
        </button>
        <button
          type="submit"
          class="action-button submit-button"
          :style="{ opacity: !isFormValid || isSubmitting ? 0.5 : 1 }"
          tabindex="0"
          aria-label="Submit form"
          :disabled="!isFormValid || isSubmitting"
          @click="handleSubmit"
        >
          {{ $t('submit') }}
        </button>
      </div>
    </div>
  </div>
  <Toastify ref="toastContainer" />
</template>
<script>
import Toastify from "@/components/Toastify.vue";
import { FetchInvoiceDetails, ReconcileCredits } from "@/api/APIs";

export default {
  name: "CreditReconciliation",
  components: {
    Toastify,
  },
  data() {
    return {
      isMobile: window.innerWidth < 1023,
      mobile: window.innerWidth <= 767,
      invoiceNumber: "",
      isSubmitting: false,
      creditsTobeAdded: 0,
      isVerifying: false,
      invoiceDetails: null,
    };
  },
  computed: {
    isInvoiceNumberPresent() {
      return this.invoiceNumber !== "";
    },
    isFormValid() {
      return this.invoiceDetails;
    },
  },
  mounted() {},
  watch: {},
  methods: {
    goBack() {
      this.$router.push("/banks-&-hq");
    },
    async GetInvoiceDetails() {
      this.isVerifying = true;
      try {
        let payload = {
          invoiceNo: this.invoiceNumber,
        };
        const response = await FetchInvoiceDetails(payload);
        if (response.data.message == "SUCCESS") {
          this.invoiceDetails = response.data.data;
        } else if (response.data.message == "Payment already settled!") {
          return this.$refs.toastContainer.addToast(response.data.message, {
            type: "error",
          });
        } else if (
          response.status === 204 ||
          response.headers["x-exit-description"] === "Payment not found"
        ) {
          this.$refs.toastContainer.addToast(
            "Payment with the given invoice number not found. Please enter the correct invoice number.",
            { type: "error" }
          );
        } else {
          return this.$refs.toastContainer.addToast(
            `Something went wrong! Please try again later.`,
            { type: "error" }
          );
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            `Something went wrong! Please try again later.`,
            { type: "error" }
          );
        }
      } finally {
        this.isVerifying = false;
      }
    },
    async handleSubmit() {
      this.isSubmitting = true;
      try {
        let payload = {
          invoiceNo: this.invoiceNumber,
        };
        const response = await ReconcileCredits(payload);
        if (response.message === "SUCCESS") {
          this.$refs.toastContainer.addToast(
            `Credits successfully reconciled, and Invoice status updated.`,
            { type: "success" }
          );
          this.resetFormState();
        } else if (response.message == "Payment already settled!") {
          return this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        } else if (response.message == "No payment found") {
          return this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        } else {
          return this.$refs.toastContainer.addToast(
            `Something went wrong! Please try again later.`,
            { type: "error" }
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.$refs.toastContainer.addToast(error.response.data, {
            type: "error",
          });
        }
        if (
          error.response &&
          error.response.status !== 403 &&
          error.response &&
          error.response.status !== 400
        ) {
          this.$refs.toastContainer.addToast(
            `Something went wrong! Please try again later.`,
            { type: "error" }
          );
        }
      } finally {
        this.isSubmitting = false;
      }
    },
    resetFormState() {
      this.invoiceNumber = "";
      this.invoiceDetails = null;
    },
    handleCancel() {
      this.resetFormState();
    },
  },
};
</script>
<style scoped>
.table-container,
.table-container * {
  box-sizing: border-box;
}
.table-container {
  padding-top: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 100vh;
  background: white;
}
.logged-in {
  margin-left: 18vw;
  padding-top: 55px;
  transition: margin-left 0.3s ease;
}
.logged-in.collapsed {
  margin-left: 60px;
}
.logged-in.notification {
  padding-top: 105px !important;
}
.not-logged-in {
  width: 100%;
}
.plan-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: -webkit-fill-available;
  padding: 20px 40px;
  gap: 40px;
  height: 100%;
}
.heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
}
.credit-plan-title {
  align-self: stretch;
  color: rgba(17, 54, 90, 1);
  letter-spacing: -0.24px;
  font: 600 24px/1.2 Montserrat, sans-serif;
  text-align: left;
}
.selection-form {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: -webkit-fill-available;
  align-items: start;
  gap: 40px;
  font-family: Montserrat, sans-serif;
  color: rgba(17, 54, 90, 1);
  justify-content: start;
  flex-wrap: wrap;
}

.selection-group {
  display: flex;
  min-width: 240px;
  flex-direction: column;
  flex: 1;
  flex-basis: 0%;
}
.selection-label {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
}
.invoice-field-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}
.select-wrapper {
  border-radius: 16px;
  background-color: #fff;
  display: flex;
  margin-top: 8px;
  min-height: 52px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  border: 1px solid rgba(17, 54, 90, 0.4);
}

.select-input {
  background: none;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: 28px;
  width: 100%;
  outline: none;
  font-family: inherit;
  color: inherit;
}
.rate-input {
  border-radius: 16px;
  background-color: #fff;
  margin-top: 8px;
  min-height: 52px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: 28px;
  padding: 12px 24px;
  border: 1px solid rgba(17, 54, 90, 0.4);
  font-family: inherit;
  color: inherit;
  box-shadow: none;
}
.subscription-section {
  display: flex;
  flex-direction: column;
  font-family: Montserrat, sans-serif;
  color: rgba(17, 54, 90, 1);
}

.subscription-title {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  text-align: left;
}

.subscription-text {
  font-size: 16px;
  font-weight: 500;
  margin: 8px 0 0;
  text-align: left;
}
.credit-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.credit-text {
  color: rgba(17, 54, 90, 1);
  font: 500 16px/28px Montserrat, sans-serif;
  margin: 0;
  text-align: left;
}
.action-buttons {
  display: flex;
  align-self: stretch;
  align-items: center;
  gap: 19px;
  justify-content: flex-end;
  flex-wrap: wrap;
  font: 600 16px/28px Montserrat, sans-serif;
}

.action-button {
  align-self: stretch;
  min-width: 18px;
  border-radius: 10px;
  margin: auto 0;
  cursor: pointer;
  border: none;
  font: inherit;
}

.verify-button {
  align-self: stretch;
  min-width: 120px;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  font: inherit;
  color: var(--Dark-Blue, #11365a);
  border: 2px solid var(--Dark-Blue, #11365a);
  background: transparent;
  height: -webkit-fill-available;
  margin-top: 8px;
  padding: 11px 0px;
  line-height: 28px;
}

.cancel-button {
  color: var(--Dark-Blue, #11365a);
  border: 2px solid var(--Dark-Blue, #11365a);
  background: transparent;
}

.submit-button {
  color: #fff;
  background: var(--Dark-Blue, #11365a);
}
.back-button {
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  color: #11365a;
  padding: 10px 0;
  border-radius: 14px;
  opacity: 0.7;
}
.back-button:hover {
  opacity: 1;
}
.no-arrows {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
}
.no-arrows::-webkit-inner-spin-button,
.no-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .table-container {
    padding-top: 0;
  }
  .selection-form {
    grid-template-columns: repeat(1, 1fr);
  }
  .plan-info {
    gap: 25px;
    padding: 20px;
  }
  .credit-plan-title {
    font-size: 20px;
  }
  .select-input,
  .rate-input {
    min-height: 45px;
    padding: 10px 24px;
  }
  .rate-input {
    line-height: 22px;
  }
  .selection-label,
  .subscription-title {
    font-size: 16px;
  }
  .selection-form {
    gap: 25px;
  }
  .action-buttons {
    flex-wrap: nowrap;
  }
  .action-button {
    width: -webkit-fill-available;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
}
@media only screen and (min-width: 1156px) {
}
</style>
