<template>
  <div class="bg-b-lue" v-on:click.self="redirectToHomePage()">
    <div class="frame-35628" v-if="orderDetails">
      <CompletedAnimationJSON
        id="completed-animation"
        v-if="paymentStatus === 'SUCCESS'"
      />
      <PaymentFailure
        id="completed-animation"
        v-if="paymentStatus === 'CANCELLED'"
      />
      <div
        class="company-name-wrapper"
        style="margin: 1rem 0"
        v-if="paymentStatus === 'SUCCESS'"
      >
        {{$t("successfully-added")}}
        <strong>{{ orderDetails?.creditsPurchased }} {{$t("credits")}}</strong> {{$t("to-your-account")}}
        <p style="font-weight: 500">
          {{$t("current-balance")}}
          <strong>{{ orderDetails?.creditBalance }}</strong>
        </p>
      </div>
      <div
        class="company-name-wrapper"
        style="margin: 1rem 0"
        v-if="paymentStatus === 'CANCELLED'"
      >
        <h3>{{$t("payment-failed")}}</h3>
        <p style="font-weight: 500">{{$t("please-try-again")}}</p>
      </div>
      <div class="buttons-wrapper">
        <div
          class="button1"
          style="
            background-color: white;
            border: 2px solid #11365a;
            padding: 14px 0px;
          "
          v-on:click.prevent="redirectToHomePage()"
        >
          <div class="button-text" style="color: #11365a">{{$t("close")}}</div>
        </div>
      </div>
    </div>
    <div v-else>
      <LoaderAnimation />
      <div
        style="
          font-size: 14px;
          margin-top: 30px;
          color: #2e5ba7;
          font-weight: 600;
        "
      >
        {{$t("please-wait")}} <br />
        {{$t("payment-being-verified")}}...
      </div>
    </div>
  </div>
</template>
<script>
import CompletedAnimationJSON from "../../assets/Animation/CompletedAnimation.vue";
import PaymentFailure from "@/assets/Animation/PaymentFailure/PaymentFailure.vue";
import { BuyCreditsSuccess, BuyCreditsFailure } from "@/api/APIs";
import LoaderAnimation from "@/assets/Animation/LoaderAnimation.vue";
import { mapActions } from "vuex";

export default {
  name: "BuyCredits",
  components: {
    CompletedAnimationJSON,
    PaymentFailure,
    LoaderAnimation,
  },
  props: {},
  data() {
    return {
      paymentStatus: null,
      orderDetails: null,
    };
  },
  async mounted() {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      this.paymentStatus = urlParams.get("paymentStatus")
        ? urlParams.get("paymentStatus")
        : null;
      const paymentSecret = urlParams.get("paymentSecret")
        ? urlParams.get("paymentSecret")
        : null;
      if (paymentSecret) {
        let payload = {
          ...(this.paymentStatus === "CANCELLED" && {
            paymentStatus: this.paymentStatus,
          }),
          paymentSecret: paymentSecret,
        };
        const response =
          this.paymentStatus === "CANCELLED"
            ? await BuyCreditsFailure(payload)
            : await BuyCreditsSuccess(payload);
        if (response) {
          this.orderDetails = response;
        }
      } else {
        throw new Error(this.$t("missing-secret"));
      }
    } catch (error) {
      console.error(this.$t("error-confirming-payment"), error);
    }
  },
  methods: {
    ...mapActions(["updateCartCount"]),
    redirectToMyOrders() {
      this.$emit("close");
      this.$router.push({ path: "/MyOrders", query: { tab: "active-orders" } });
    },
    redirectToHomePage() {
      if (this.selectedCompanyName) {
        this.$emit("close");
      } else {
        this.$router.push({ name: "YourProfile", query: { tab: "credits" } });
      }
    },
  },
};
</script>
<style scoped>
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  z-index: 9999;
}
.frame-35628 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 30vw;
  position: absolute;
  background: #ffffff;
  border-radius: 24px;
  position: relative;
  box-shadow: 0px 24px 24px 0px rgba(17, 54, 90, 0.2);
  padding: 2rem 3rem;
}
.buttons-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}
.button-text {
  text-align: center;
  font-family: "NunitoSans-SemiBold", sans-serif;
  font-size: 18px;
  font-weight: 600;
  flex: 1;
  cursor: pointer;
}
.company-name-wrapper {
  color: var(--Dark-Blue, #11365a);
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5px;
}
.button1 {
  border-radius: 13px !important;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-width: 150px;
  width: 100%;
  cursor: pointer;
}
.button-text {
  color: #ffffff;
  text-align: center;
  font-family: "NunitoSans-SemiBold", sans-serif;
  font-size: 18px;
  font-weight: 600;
  flex: 1;
}

@media only screen and (max-width: 767px) {
  .frame-35628 {
    width: 75%;
    padding: 1rem 2rem;
  }
  #completed-animation {
    width: 75px;
    height: 75px;
  }
  .company-name-wrapper {
    font-size: 16px !important;
  }
  .button1 {
    width: 100% !important;
    padding: 0 !important;
    height: 40px !important;
  }
  .button-text {
    font-size: 14px;
    width: 100%;
  }
  .active-order {
    border-radius: 13px !important;
  }
  .button1 div {
    padding: 0 !important;
  }
}
</style>
