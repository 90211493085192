<template>
  <ul v-if="information && !Array.isArray(information)">
    <template v-for="(value, key) in information" :key="key">
      <li v-if="!(key == 'company_address' && value == '')">
        <span class="informationKey">
          {{ getTranslatedKey(key) }}:&nbsp;
        </span>
        <!-- Apply dir="ltr" to the value to fix Arabic number ordering -->
        <span v-if="!(typeof value == 'object')" style="font-weight: 400" :dir="isNumber(value) ? 'ltr' : 'auto'">
          {{ value }}
        </span>
        <InformationList :information="value" v-if="typeof value == 'object'" />
      </li>
    </template>
  </ul>

  <ul v-if="information && Array.isArray(information)">
    <template v-for="(value, key) in information" :key="key">
      <li v-if="!isObject(value) && value !== 'WIN'" style="list-style-type: disc;">
        <span class="informationValue" style="font-weight: 400" :dir="isNumber(value) ? 'ltr' : 'auto'">
          {{ value }}
        </span>
      </li>
      <li v-else-if="isObject(value) && value.name !== 'WIN'">
        <span class="informationKey">{{ getTranslatedKey(value.name) }}:</span>
        <span style="font-weight: 400" :dir="isNumber(value.value) ? 'ltr' : 'auto'">
          &nbsp;{{ value.value }}
        </span>
      </li>
    </template>
  </ul>
</template>



<script>
import InformationList from "@/components/InformationList.vue";

export default {
  name: "InformationList",
  components: {
    InformationList,
  },
  props: {
    information: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    isNumber(value) {
    return !isNaN(value) || /^\d+$/.test(value);
  },
    getTranslatedKey(key) {
      const translated = this.$t(key);
      if (translated && translated !== key) {
        return translated;
      }
      return key
        ?.replace(/_/g, " ")
        ?.replace(/company/g, "")
        ?.replace(/\b\w/g, c => c.toUpperCase());
    }
    ,
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    isObject(value) {
      return value && typeof value === "object" && !Array.isArray(value);
    },
  },
};
</script>

<style>
.informationKey {
  text-transform: capitalize;
  font-weight: 600;
  color: #1a2d4b;
}

ul {
  list-style-type: none;
  padding-inline-start: 20px;
}

@media only screen and (max-width: 767px) {
  ul {
    font-size: 14px;
    background-color: white;
    border-radius: 10px;
    padding-inline-start: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: "Montserrat-Light", sans-serif;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  ul {
    font-size: 16px;
  }
}
</style>
