<template>
    <div class="bg-b-lue">
      <div class="modal">
        <PaymentFailure/>
        <h2>{{$t("session-expired")}}</h2>
        <p>{{$t("please-login-again")}}</p>
        <button @click="login">{{$t("login")}}</button>
      </div>
    </div>
  </template>
  
  <script>
  import PaymentFailure from '@/assets/Animation/PaymentFailure/PaymentFailure.vue';

  export default {
    name: 'SessionExpiredModal',
    components: {
        PaymentFailure,
    },
    methods: {
      login() {
        this.$emit('login');
      },
    },
  };
  </script>
  
  <style scoped>
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  z-index: 9999;
}
  
  .modal {
    background: white;
    padding: 40px 20px;
    border-radius: 16px;
    text-align: center;
  }
  
  button {
    margin: 10px;
    background: #11365a;
    color: white;
    border: none;
    outline: none;
  }
  </style>
  