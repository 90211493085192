<template>
  <div
    class="bg-b-lue"
    v-on:click.self="companyName !== '' ? redirectToHomePage() : null"
  >
    <div class="frame-35628" v-if="companyName !== ''">
      <CompletedAnimationJSON id="completed-animation" />
      <div class="company-name-wrapper" style="margin-top: 10px">
        {{
          isBulkOrder
            ? $t("order-confirmation")
            : $t("thanks-for-order")
        }}
      </div>
      <div
        class="company-name-wrapper"
        style="font-size: 20px"
        v-if="!isBulkOrder"
      >
        {{ companyName }}
      </div>
      <div class="report-delivery-time">
        {{
          isBulkOrder
            ? $t("visit-ative-orders")
            : deliveryTime
            ? `${$t("report-delivery-time")} ${deliveryTime} ${$t("working-hours")}`
            : ""
        }}
      </div>
      <div class="buttons-wrapper">
        <button
          class="active-order button-text"
          v-if="isBulkOrder"
          @click="redirectToMyOrders()"
        >
          {{$t("goto-active-order")}}
        </button>
        <div
          class="button1"
          style="
            background-color: white;
            border: 2px solid #11365a;
            padding: 14px 0px;
          "
          v-on:click.prevent="redirectToHomePage()"
        >
          <div class="button-text" style="color: #11365a">{{$t("close")}}</div>
        </div>
      </div>
    </div>
    <div v-else>
      <LoaderAnimation />
      <div
        style="
          font-size: 14px;
          margin-top: 30px;
          color: #2e5ba7;
          font-weight: 600;
        "
      >
        {{$t("please-wait")}} <br />
        {{$t("verifying-payment")}}...
      </div>
    </div>
  </div>
</template>
<script>
import CompletedAnimationJSON from "../../assets/Animation/CompletedAnimation.vue";
import { PaymentConfirm, UserDetails } from "@/api/APIs";
import LoaderAnimation from "@/assets/Animation/LoaderAnimation.vue";
import VueCookies from "vue-cookies";
import { mapActions } from "vuex";

export default {
  name: "OrderConfirmationModal",
  components: {
    CompletedAnimationJSON,
    LoaderAnimation,
  },
  props: {
    selectedCompanyName: {
      type: String,
      required: true,
    },
    reportDetails: {
      type: String,
      required: true,
    },
    userEmail: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      companyName: "",
      deliveryTime: "",
      isBulkOrder: false,
    };
  },
  async mounted() {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const orderEmail = urlParams.get("orderEmail")
        ? urlParams.get("orderEmail")
        : null;
      const paymentSecret = urlParams.get("paymentSecret")
        ? urlParams.get("paymentSecret")
        : null;
      const doNewPayment = urlParams.get("doNewPayment") ? true : false;
      this.isBulkOrder = urlParams.get("doNewPayment") ? true : false;
      if (orderEmail && paymentSecret) {
        let payload = {
          orderEmail: orderEmail,
          paymentSecret: paymentSecret,
          doNewPayment: doNewPayment,
        };
        const response = await PaymentConfirm(payload);
        if (response && response.message === "SUCCESS") {
          this.companyName = response?.subjectName;
          this.deliveryTime = response?.orderDeliveryHours;
          if (doNewPayment) {
            this.UserDetails();
          }
        }
      } else if (this.selectedCompanyName && this.userEmail) {
        this.companyName = this.selectedCompanyName;
        this.deliveryTime = this.reportDetails?.eta_hours;
      } else {
        throw new Error(this.$t("missing-order-email"));
      }
    } catch (error) {
      console.error(this.$t("error-confirming-payment"), error);
    }
  },
  methods: {
    ...mapActions(["updateCartCount"]),
    async UserDetails() {
      const response = await UserDetails(
        VueCookies.get("email"),
        VueCookies.get("token")
      );
      if (response) {
        this.$store.dispatch("setCredits", response.availableCredits);
        this.updateCartCount(response.cartItems);
        VueCookies.set("order-count", response.orderCount);
      }
    },
    redirectToMyOrders() {
      this.$emit("close");
      this.$router.push({ path: "/MyOrders", query: { tab: "active-orders" } });
    },
    redirectToHomePage() {
      if (this.selectedCompanyName) {
        this.$emit("close");
      } else {
        this.$router.push({ name: "new-home" });
      }
    },
  },
};
</script>
<style scoped>
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  z-index: 9999;
}
.frame-35628 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 420px;
  position: absolute;
  background: #ffffff;
  border-radius: 24px;
  position: relative;
  box-shadow: 0px 24px 24px 0px rgba(17, 54, 90, 0.2);
  padding: 2rem 3rem;
}
.buttons-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}
.active-order {
  color: #11365a;
  background-color: #26bbc2;
  outline: none;
  border: none;
  padding: 14px 0px;
  width: 100%;
}
.button-text {
  text-align: center;
  font-family: "NunitoSans-SemiBold", sans-serif;
  font-size: 18px;
  font-weight: 600;
  flex: 1;
  cursor: pointer;
}
.company-name-wrapper {
  color: var(--Dark-Blue, #11365a);
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5px;
}
.report-delivery-time {
  color: var(--Text-Mid, #3e4855);
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.18px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.button1 {
  border-radius: 13px !important;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-width: 150px;
  width: 100%;
  cursor: pointer;
}
.button-text {
  color: #ffffff;
  text-align: center;
  font-family: "NunitoSans-SemiBold", sans-serif;
  font-size: 18px;
  font-weight: 600;
  flex: 1;
}

@media only screen and (max-width: 767px) {
  .frame-35628 {
    width: 75%;
    padding: 1rem 2rem;
  }
  #completed-animation {
    width: 75px;
    height: 75px;
  }
  .company-name-wrapper {
    font-size: 16px !important;
  }
  .report-delivery-time {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 14px;
  }
  .button1 {
    width: 100% !important;
    padding: 0 !important;
    height: 40px !important;
  }
  .button-text {
    font-size: 14px;
    width: 100%;
  }
  .active-order {
    border-radius: 13px !important;
  }
  .button1 div {
    padding: 0 !important;
  }
}
</style>
