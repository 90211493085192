<template>
  <div class="bg-b-lue">
    <div class="frame-35628">
      <div class="title-wrapper">
        <div class="title">
          {{ newBranch ? $t("create-branch") : $t("edit-branch") }} •
          {{ bankDetails.name }}
        </div>
        <i class="fa-solid fa-xmark fa-xl" @click="close()"></i>
      </div>
      <form @submit.prevent="submitForm" class="bank-details-wrapper">
        <div class="bank-details">
          <div class="field-wrapper">
            <div class="field-title">{{$t("branch-name")}}</div>
            <input
              class="input"
              type="text"
              v-model="form.branchName"
              @input="checkForChanges"
              :placeholder="$t('enter-branch-name')"
              required
            />
          </div>
          <div class="field-wrapper">
            <div class="field-title">{{$t("branch-type")}}</div>
            <select
              v-model="form.branchType"
              @input="checkForChanges"
              class="input"
              :style="{ height: mobile ? '56px' : '42px' }"
              required
            >
              <option value="" disabled>{{$t("select-branch")}}</option>
              <option
                v-for="type in productConfig?.branchType"
                :value="type"
                :key="type"
              >
                {{ type }}
              </option>
            </select>
          </div>
        </div>
        <div class="bank-details">
          <div class="field-wrapper">
            <div class="field-title">{{$t("order-potential")}}</div>
            <select
              v-model="form.orderPotential"
              @input="checkForChanges"
              class="input"
              :style="{ height: mobile ? '56px' : '42px' }"
              required
            >
              <option value="" disabled>{{$t("select")}}</option>
              <option
                v-for="value in productConfig?.branchOrderPotential"
                :value="value"
                :key="value"
              >
                {{ value }}
              </option>
            </select>
          </div>
          <div class="field-wrapper">
            <div class="field-title">{{$t("location")}}</div>
            <input
              class="input"
              type="text"
              v-model="form.location"
              @input="checkForChanges"
              :placeholder="$t('branch-location')"
              required
            />
          </div>
        </div>
        <div class="bank-details">
          <div class="field-wrapper">
            <div class="field-title">{{$t("address")}}</div>
            <textarea
              class="address-field"
              v-model="form.address"
              @input="checkForChanges"
              :placeholder="$t('enter-branch-address')"
            ></textarea>
          </div>
        </div>
        <div class="buttons">
          <button class="cancel-button" @click="close()">{{$t("cancel")}}</button>
          <button
            type="submit"
            class="save-button"
            :style="{
              opacity: loading || !isChanged ? '0.5' : '1',
              cursor: isChanged ? 'pointer' : 'not-allowed',
            }"
            :disabled="loading || !isChanged"
          >
            {{ newBranch ? $t("create-branch") : $t("save-changes") }}
          </button>
        </div>
      </form>
    </div>
  </div>
  <Toastify ref="toastContainer" />
</template>
<script>
import Toastify from "@/components/Toastify.vue";
import { UpdateBranchDetails, AddBranch, GetProductConfig } from "@/api/APIs";

export default {
  name: "EditBranch",
  components: {
    Toastify,
  },
  props: {
    bankDetails: {
      type: Object,
      required: true,
    },
    branchDetails: {
      type: Object,
      required: true,
    },
    newBranch: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      form: {
        branchName: "",
        branchType: "",
        orderPotential: "",
        location: "",
        address: "",
      },
      mobile: window.innerWidth > 767,
      originalForm: {},
      loading: false,
      isChanged: false,
      productConfig: null,
    };
  },
  async mounted() {
    await this.ConfigItems();
    this.prefillForm();
  },
  methods: {
    async ConfigItems() {
      try {
        const response = await GetProductConfig();
        if (response) {
          this.productConfig = response.entityProductConfig;
        } else {
          this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            this.$t("something-went-wrong"),
            { type: "error" }
          );
        }
      }
    },
    prefillForm() {
      if (!this.newBranch) {
        this.form.branchName = this.branchDetails.branchName || "";
        this.form.branchType = this.branchDetails.branchType || "";
        this.form.orderPotential =
          this.branchDetails.orderPotentialPerMonth || "";
        this.form.location = this.branchDetails.location || "";
        this.form.address = this.branchDetails.address || "";
        this.originalForm = { ...this.form };
      }
    },
    checkForChanges() {
      this.isChanged =
        JSON.stringify(this.form) !== JSON.stringify(this.originalForm);
    },
    async submitForm() {
      if (!this.isChanged) return;

      this.loading = true;
      if (this.newBranch) {
        const payload = {
          branchName: this.form.branchName,
          branchType: this.form.branchType,
          location: this.form.location,
          address: this.form.address,
          entityId: this.bankDetails.id,
          orderPotentialPerMonth: this.form.orderPotential,
        };
        try {
          const response = await AddBranch(payload);
          if (response.message == "SUCCESS") {
            this.$refs.toastContainer.addToast(this.$t("branch-created-successfully"), {
              type: "success",
            });
            this.close();
          } else {
            this.$refs.toastContainer.addToast(response.message, {
              type: "error",
            });
          }
        } catch (error) {
          if (error.response && error.response.status !== 403) {
            this.$refs.toastContainer.addToast(
              this.$t("please-try-again"),
              { type: "error" }
            );
          }
        }
      } else {
        const payload = {
          branchId: this.branchDetails.id,
          branchName: this.form.branchName,
          location: this.form.location,
          address: this.form.address,
          branchType: this.form.branchType,
          orderPotentialPerMonth: this.form.orderPotential,
        };
        try {
          const response = await UpdateBranchDetails(payload);
          if (
            response.message == "Entity Branch details updated successfully"
          ) {
            this.$refs.toastContainer.addToast(
              this.$t("branch-updated"),
              { type: "success" }
            );
            this.close();
          } else {
            this.$refs.toastContainer.addToast(response.message, {
              type: "error",
            });
          }
        } catch (error) {
          if (error.response && error.response.status !== 403) {
            this.$refs.toastContainer.addToast(
              this.$t("failed-to-update"),
              { type: "error" }
            );
          }
        }
      }
      this.loading = false;
    },
    close() {
      this.form = {
        branchName: "",
        location: "",
        address: "",
        branchType: "",
        orderPotential: "",
      };
      this.originalForm = {};
      this.isChanged = false;
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  z-index: 9999;
}
.frame-35628 {
  display: flex;
  width: 70%;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 56px 56px 0px rgba(17, 54, 90, 0.1);
  position: relative;
  box-shadow: 0px 24px 24px 0px rgba(17, 54, 90, 0.2);
}
.title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.title {
  color: #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.24px;
}
.bank-details-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
.bank-details {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.field-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}
.field-title {
  color: #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.2px;
}
.input {
  /* display: flex; */
  padding: 12px 24px;
  /* align-items: center; */
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid rgba(17, 54, 90, 0.4);
  background: #fff;
  box-shadow: none;
  color: #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.16px;
  outline: none;
}
.address-field {
  display: flex;
  height: 104px;
  padding: 12px 24px;
  color: #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.16px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid rgba(17, 54, 90, 0.4);
  background: #fff;
  outline: none;
}
.buttons {
  display: flex;
  align-items: flex-start;
  gap: 19px;
  width: 100%;
  box-shadow: none;
  padding: 0;
}
.save-button {
  display: flex;
  width: 50%;
  align-self: stretch;
  padding: 12px 26px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: var(--Dark-Blue, #11365a);
  color: #fff;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.16px;
  height: 52px;
  outline: none;
}
.cancel-button {
  display: flex;
  width: 50%;
  align-self: stretch;
  padding: 12px 26px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: white;
  border: 2px solid #11365a;
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.16px;
  height: 52px;
  outline: none;
}
.save-button:hover,
.cancel-button:hover {
  box-shadow: 0px 0px 20px 4px #cbe5ff;
}
@media only screen and (max-width: 767px) {
  .frame-35628 {
    width: 90%;
    padding: 1rem;
  }
  .title {
    font-size: 18px;
  }
  .field-title {
    font-size: 16px;
  }
  .bank-details {
    flex-direction: column;
  }
  .field-wrapper {
    align-self: stretch;
  }
  .input {
    padding: 5px 24px;
    margin: 0;
  }
  .buttons {
    width: 100%;
    box-shadow: none;
    padding: 0;
  }
  .save-button,
  .cancel-button {
    height: 42px;
  }
  .address-field {
    height: 50px;
    margin: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
}
@media only screen and (min-width: 1156px) {
}
</style>
