<template>
  <div
    :class="'section-2-flags-property-1-default ' + 'property-1-' + property1"
  >
    <div class="consumers-with-sme-s">{{ $t("cross-border-reach") }}</div>
    <div class="flag-rows">
      <img style="height: 55px" v-lazy="this.$root.getCDNlink('assets/FlagRow1.webp')" alt="World international reach">
    </div>
    <div class="flag-rows2">
      <img style="height: 64px; " v-lazy="this.$root.getCDNlink('assets/FlagRowMain.webp')" alt="World international reach">
    </div>
    <div class="flag-rows3" style="padding-top: 28px;">
      <img style="height: 55px" v-lazy="this.$root.getCDNlink('assets/FlagRow3.webp')" alt="World international reach">
    </div>
    <img :style="{ height: '70%', position: 'absolute', width: '100%', bottom: '0', left: '0' }" v-lazy="this.$root.getCDNlink('assets/Group 5.webp')" alt="World international reach background"/>
  </div>
</template>
<script>
export default {
  name: "Section2FlagsProperty1Default",
  components: {},
  props: {
    property1: {
      type: String,
      default: "default",
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  }
};
</script>
<style scoped>
.section-2-flags-property-1-default,
.section-2-flags-property-1-default * {
  box-sizing: border-box;
}

.section-2-flags-property-1-default {
  background: linear-gradient(to left, #11365a, #11365a),
    radial-gradient(
      closest-side,
      rgba(33, 199, 240, 0.2) 0%,
      rgba(217, 217, 217, 0) 100%
    );
  height: 385px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.consumers-with-sme-s {
  color: #ffffff;
  text-align: center;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 24px;
  font-weight: 700;
  position: absolute;
  width: 100%;
  top: 68px;
}

.flag-rows {
  display: flex;
  flex-direction: row;
  gap: 36px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 0;
  top: 137px;
  animation: rotateRightToLeft 20s linear infinite;
}

.flag-rows2 {
  display: flex;
  flex-direction: row;
  gap: 36px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  right: 0;
  top: 209px;
  animation: rotateLeftToRight 20s linear infinite;
}

.flag-rows3 {
  display: flex;
  flex-direction: row;
  gap: 36px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 0;
  top: 281px;
  animation: rotateRightToLeft 20s linear infinite;
}

@keyframes rotateRightToLeft {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-10%);
  }
  20% {
    transform: translateX(-20%);
  }
  30% {
    transform: translateX(-30%);
  }
}

@keyframes rotateLeftToRight {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(10%);
  }
  20% {
    transform: translateX(20%);
  }
  30% {
    transform: translateX(30%);
  }
}


/* Media query */
@media only screen and (max-width: 767px) {
  .section-2-flags-property-1-default {
    height: 250px;
  }
  .consumers-with-sme-s {
    top: 40px;
    font-size: 16px;
  }
  .flag-rows {
    gap: 26px;
    top: 90px;
  }
  .flag-rows img, .flag-rows3 img {
    height: 35px !important;
  }
  .flag-rows2 {
    top: 140px;
  }
  .flag-rows2 img{
    height: 33px !important;
  }
  .flag-rows3 {
    top: 175px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .section-2-flags-property-1-default {
    height: 300px;
  }
  .consumers-with-sme-s {
    top: 40px;
    font-size: 22px;
  }
  .flag-rows {
    gap: 26px;
    top: 110px;
  }
  .flag-rows img, .flag-rows3 img {
    height: 45px !important;
  }
  .flag-rows2 {
    top: 170px;
  }
  .flag-rows2 img{
    height: 43px !important;
  }
  .flag-rows3 {
    top: 215px;
  }
}

@media only screen and (min-width: 1024px) {
}
</style>



