import { createWebHistory, createRouter } from "vue-router";
import VueCookies from "vue-cookies";
import ReplenishCredits from "@/views/ReplenishCredits.vue";
import NotFound from "@/views/NotFound.vue";
import HomePage from "@/components/HomePage/HomePage/HomePage.vue";
// import CorporatesPage from "@/components/HomePage/CorporatesPage/CorporatesPage.vue";
// import MyOrders from "@/views/MyOrders.vue";
// import BankUserOrders from "@/views/BankUserOrders.vue";
// import MyRepository from "@/views/MyRepository.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import ContactUs from "@/views/Contact.vue";
import LoginScreen from "@/views/Login.vue";
import AboutUs from "@/views/AboutUs.vue";
import OrderConfirmationModal from "@/views/Modals/OrderConfirmation.vue";
import PaymentRetryModal from "@/views/Modals/PaymentRetryModal.vue";
// import UserDashboard from "@/views/RegisterdUser/Dashboard/Dashboard.vue";
import BankUserDashboard from "@/views/RegisterdUser/Dashboards/BankUserDashboard.vue";
import Products from "@/views/Products.vue";
import BlogsBanner from "@/views/BlogsBanner.vue";
import SearchPage from "@/views/SearchPage.vue";
import PaymentRetry from "@/views/PaymentRetry.vue";
import UserProfile from "@/views/RegisterdUser/UserProfile.vue";
import TeamInvite from "@/views/TeamInvite.vue";
import BanksAndHQ from "@/views/InternalUsersScreens/BanksAndHQ.vue";
import SampleReports from "@/views/SampleReports.vue";
import Rewards from "@/views/Rewards.vue";
import WhatsappTermsAndConditions from "@/views/WhatsappIntegration/TermsAndConditions.vue";
import AllOrders from "@/views/Orders.vue";
import BlogEditor from "@/components/Blogs/BlogsEditor.vue";
import Blog from "@/components/Blogs/Blog.vue";
// import { useStore } from "vuex";
import store from "@/store";
import ExistingBlogsList from "@/components/Blogs/ExistingBlogsList.vue";
import InstitutionsCorporate from "@/views/Institutions.vue";
import OrderDetails from "@/views/Modals/OrderDetailsModal.vue";
import ManualOrder from "@/views/Modals/ManualOrderModal.vue";
import RmManualOrder from "@/views/Modals/InternalUserModals/RmManualOrder.vue";
import buyCreditsSuccessModal from "@/views/Modals/buyCreditsSuccess.vue";
import CreditPlanAssignment from "@/views/CreditPlanAssignment.vue";
import CreditReconciliation from "@/views/creditReconciliation.vue";

function trackPageView(to) {
  const params = to.query;
  const utmParams = {
    utm_source: params.utm_source,
    utm_medium: params.utm_medium,
    utm_campaign: params.utm_campaign,
    utm_term: params.utm_term,
    utm_content: params.utm_content,
  };
  if (typeof window.gtag === "function") {
    window.gtag("config", "G-MYWX7F5RFT", {
      page_path: to.fullPath,
      ...utmParams,
    });
  }
}

const routes = [
  {
    path: "/",
    name: "new-home",
    component: HomePage,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (token) {
        next({ name: "SearchForCompanies" });
      } else {
        next();
      }
    },
    meta: {
      localizedNames: {
        en: "new-home",
        zh: "新-首頁",
        ar: "الصفحة-الرئيسية",
        ko: "새로운-홈",
        th: "บ้านใหม่",
        vi: "trang-chủ-mới",
        bn: "নতুন-হোম",
        ja: "新しいホーム",
      },
    },
  },
  {
    path: "/bankuserdashboard",
    name: "dashboard",
    component: BankUserDashboard,
    beforeEnter: (to, from, next) => {
      const userType = VueCookies.get("user-type");
      const token = VueCookies.get("token");
      if (token) {
        if (
          userType === "RETAIL" ||
          userType === "EXECUTIVE" ||
          userType === "RM"
        ) {
          next({ name: "SearchForCompanies" });
        } else {
          next();
        }
      } else {
        next({ name: "new-home" });
      }
    },
    meta: {
      localizedNames: {
        en: "dashboard",
        zh: "儀表板",
        ar: "لوحة-القيادة",
        ko: "대시보드",
        th: "แผงควบคุม",
        vi: "bảng-điều-khiển",
        bn: "ড্যাশবোর্ড",
        ja: "ダッシュボード",
      },
    },
  },
  {
    path: "/blogs/:category?",
    name: "blogs-list",
    component: BlogsBanner,
    props: (route) => ({ category: route.params.category || null }),
    meta: {
      localizedNames: {
        en: "blogs-list",
        zh: "博客-列表",
        ar: "قائمة-المدونات",
        ko: "블로그-목록",
        th: "รายการ-บล็อก",
        vi: "danh-sách-blog",
        bn: "ব্লগের-তালিকা",
        ja: "ブログリスト",
      },
    },
  },
  {
    path: "/blog/:blogTitle/:blogId",
    name: "blog",
    component: Blog,
    props: true,
    meta: {
      localizedNames: {
        en: "blog",
        zh: "博客",
        ar: "مدونة",
        ko: "블로그",
        th: "บล็อก",
        vi: "blog",
        bn: "ব্লগ",
        ja: "ブログ",
      },
    },
  },
  {
    path: "/blog-editor",
    name: "blog-editor",
    component: BlogEditor,
    props: { isEditMode: false },
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      const userDetails = store.state.userDetails;
      if (token && userDetails?.includes("BLOG-EDITOR")) {
        next();
      } else if (!token) {
        next({ name: "new-home" });
      } else {
        next({ name: "SearchForCompanies" });
      }
    },
    meta: {
      localizedNames: {
        en: "blog-editor",
        zh: "博客-編輯器",
        ar: "محرر-المدونة",
        ko: "블로그-편집기",
        th: "ตัวแก้ไข-บล็อก",
        vi: "trình-chỉnh-sửa-blog",
        bn: "ব্লগ-সম্পাদক",
        ja: "ブログエディター",
      },
    },
  },
  {
    path: "/existing-blog-editor",
    name: "existing-blog-editor",
    component: BlogEditor,
    props: { isEditMode: true },
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      const userDetails = store.state.userDetails;
      if (token && userDetails?.includes("BLOG-EDITOR")) {
        next();
      } else if (!token) {
        next({ name: "new-home" });
      } else {
        next({ name: "SearchForCompanies" });
      }
    },
    meta: {
      localizedNames: {
        en: "existing-blog-editor",
        zh: "現有博客-編輯器",
        ar: "محرر-المدونة-الحالية",
        ko: "기존-블로그-편집기",
        th: "ตัวแก้ไข-บล็อก-ปัจจุบัน",
        vi: "trình-chỉnh-sửa-blog-hiện-tại",
        bn: "বিদ্যমান-ব্লগ-সম্পাদক",
        ja: "既存のブログエディター",
      },
    },
  },
  {
    path: "/existing-blogs",
    name: "ExistingBlogs",
    component: ExistingBlogsList,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      const userDetails = store.state.userDetails;
      if (token && userDetails?.includes("BLOG-EDITOR")) {
        next();
      } else if (!token) {
        next({ name: "new-home" });
      } else {
        next({ name: "SearchForCompanies" });
      }
    },
    meta: {
      localizedNames: {
        en: "ExistingBlogs",
        zh: "現有-博客",
        ar: "المدونات-الحالية",
        ko: "기존-블로그",
        th: "บล็อกที่มีอยู่",
        vi: "blog-hiện-tại",
        bn: "বিদ্যমান-ব্লগগুলি",
        ja: "既存のブログ",
      },
    },
  },
  {
    path: "/sample-reports",
    name: "ExploreOurSampleReports",
    component: SampleReports,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (!token) {
        next({ name: "new-home" });
      } else {
        next();
      }
    },
    meta: {
      localizedNames: {
        en: "ExploreOurSampleReports",
        zh: "探索-我們的樣本報告",
        ar: "استكشاف-تقاريرنا-العينة",
        ko: "샘플-보고서-탐색",
        th: "สำรวจรายงานตัวอย่างของเรา",
        vi: "khám-phá-báo-cáo-mẫu",
        bn: "আমাদের-নমুনা-প্রতিবেদন-অন্বেষণ করুন",
        ja: "サンプルレポートを探索する",
      },
    },
  },
  {
    path: "/credit-plan-assignment",
    name: "CreditPlanAssignment",
    component: CreditPlanAssignment,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      const userDetails = store.state.userDetails;
      if (token && userDetails?.includes("CREDIT_PLAN_MAPPING")) {
        next();
      } else if (!token) {
        next(from.fullPath);
      } else {
        next(from.fullPath);
      }
    },
    meta: {
      localizedNames: {
        en: "CreditPlanAssignment",
        zh: "信用計劃-分配",
        ar: "تعيين-خطة-الائتمان",
        ko: "신용-계획-할당",
        th: "การมอบหมายแผนเครดิต",
        vi: "phân-bổ-kế-hoạch-tín-dụng",
        bn: "ক্রেডিট-পরিকল্পনা-বরাদ্দ",
        ja: "クレジットプランの割り当て",
      },
    },
  },
  {
    path: "/credit-reconciliation",
    name: "CreditReconciliation",
    component: CreditReconciliation,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      const userDetails = store.state.userDetails;
      if (token && userDetails?.includes("CREDIT_PLAN_MAPPING")) {
        next();
      } else if (!token) {
        next(from.fullPath);
      } else {
        next(from.fullPath);
      }
    },
    meta: {
      localizedNames: {
        en: "CreditReconciliation",
        zh: "信用調解",
        ar: "التسوية-الائتمانية",
        ko: "신용-조정",
        th: "การปรับปรุงเครดิต",
        vi: "đối-soát-tín-dụng",
        bn: "ক্রেডিট-সমন্বয়",
        ja: "クレジット調整",
      },
    },
  },
  {
    path: "/rewards",
    name: "CredenceConnectReferralProgram",
    component: Rewards,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (!token) {
        next({ name: "new-home" });
      } else {
        next();
      }
    },
    meta: {
      localizedNames: {
        en: "CredenceConnectReferralProgram",
        zh: "Credence-Connect-推薦計劃",
        ar: "برنامج-الإحالة-كريدنس-كونكت",
        ko: "Credence-Connect-추천-프로그램",
        th: "โปรแกรมแนะนำ-Credence-Connect",
        vi: "chương-trình-giới-thiệu-Credence-Connect",
        bn: "CredenceConnect-রেফারেল-প্রোগ্রাম",
        ja: "CredenceConnect紹介プログラム",
      },
    },
  },
  {
    path: "/search/:country?/:company?",
    name: "SearchForCompanies",
    props: true,
    component: SearchPage,
    meta: {
      localizedNames: {
        en: "SearchForCompanies",
        zh: "搜尋-公司",
        ar: "البحث-عن-الشركات",
        ko: "회사-검색",
        th: "ค้นหาบริษัท",
        vi: "tìm-kiếm-công-ty",
        bn: "কোম্পানি-অনুসন্ধান",
        ja: "企業を検索",
      },
    },
  },
  {
    path: "/payment/success",
    name: "OrderConfirmationModal",
    props: true,
    component: OrderConfirmationModal,
    meta: {
      localizedNames: {
        en: "OrderConfirmationModal",
        zh: "訂單-確認-模態框",
        ar: "نموذج-تأكيد-الطلب",
        ko: "주문-확인-모달",
        th: "กล่องยืนยันคำสั่งซื้อ",
        vi: "hộp-xác-nhận-đơn-hàng",
        bn: "কোম্পানির-অনুসন্ধান",
        ja: "企業を検索",
      },
    },
  },
  {
    path: "/payment/no-success",
    name: "PaymentRetryModal",
    props: true,
    component: PaymentRetryModal,
    meta: {
      localizedNames: {
        en: "PaymentRetryModal",
        zh: "付款-重試-模態框",
        ar: "نموذج-إعادة-الدفع",
        ko: "결제-재시도-모달",
        th: "กล่องลองชำระเงินใหม่",
        vi: "hộp-thử-lại-thanh-toán",
        bn: "পেমেন্ট-পুনরায়-চেষ্টা-মডাল",
        ja: "支払い再試行モーダル",
      },
    },
  },
  {
    path: "/payment/retry",
    name: "PaymentRetry",
    props: true,
    component: PaymentRetry,
    meta: {
      localizedNames: {
        en: "PaymentRetry",
        zh: "付款-重試",
        ar: "إعادة-الدفع",
        ko: "결제-재시도",
        th: "ลองชำระเงินใหม่",
        vi: "thử-lại-thanh-toán",
        bn: "পেমেন্ট-পুনরায়-চেষ্টা",
        ja: "支払い再試行",
      },
    },
  },
  {
    path: "/payment/buy-credits-success",
    name: "BuyCreditSuccess",
    props: true,
    component: buyCreditsSuccessModal,
    meta: {
      localizedNames: {
        en: "BuyCreditSuccess",
        zh: "購買-信用-成功",
        ar: "شراء-الرصيد-ناجح",
        ko: "크레딧-구매-성공",
        th: "ซื้อเครดิตสำเร็จ",
        vi: "mua-tín-dụng-thành-công",
        bn: "ক্রেডিট-ক্রয়-সফল",
        ja: "クレジット購入成功",
      },
    },
  },
  {
    path: "/payment/buy-credits-no-success",
    name: "BuyCreditFailure",
    props: true,
    component: buyCreditsSuccessModal,
    meta: {
      localizedNames: {
        en: "BuyCreditFailure",
        zh: "購買-信用-失敗",
        ar: "شراء-الرصيد-فشل",
        ko: "크레딧-구매-실패",
        th: "ซื้อเครดิตล้มเหลว",
        vi: "mua-tín-dụng-thất-bại",
        bn: "ক্রেডিট-ক্রয়-ব্যর্থ",
        ja: "クレジット購入失敗",
      },
    },
  },
  {
    path: "/contact",
    name: "ShareYourThoughts",
    component: ContactUs,
    meta: {
      localizedNames: {
        en: "ShareYourThoughts",
        zh: "分享-您的-想法",
        ar: "شارك-أفكارك",
        ko: "생각을-공유하세요",
        th: "แบ่งปันความคิดของคุณ",
        vi: "chia-sẻ-suy-nghĩ-của-bạn",
        bn: "আপনার-মতামত-শেয়ার-করুন",
        ja: "あなたの考えを共有する",
      },
    },
  },
  {
    path: "/product",
    name: "ProductsPage",
    component: Products,
    meta: {
      localizedNames: {
        en: "ProductsPage",
        zh: "產品-頁面",
        ar: "صفحة-المنتجات",
        ko: "제품-페이지",
        th: "หน้าผลิตภัณฑ์",
        vi: "trang-sản-phẩm",
        bn: "পণ্য-পৃষ্ঠা",
        ja: "製品ページ",
      },
    },
  },
  {
    path: "/aboutus",
    name: "AboutUs",
    component: AboutUs,
    meta: {
      localizedNames: {
        en: "AboutUs",
        zh: "關於-我們",
        ar: "معلومات-عنا",
        ko: "회사소개",
        th: "เกี่ยวกับเรา",
        vi: "về-chúng-tôi",
        bn: "আমাদের-সম্পর্কে",
        ja: "私たちについて",
      },
    },
  },
  {
    path: "/profile",
    name: "YourProfile",
    props: true,
    component: UserProfile,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (!token) {
        next({ name: "new-home" });
      } else {
        next();
      }
    },
    meta: {
      localizedNames: {
        en: "YourProfile",
        zh: "您的-個人資料",
        ar: "ملفك-الشخصي",
        ko: "내-프로필",
        th: "โปรไฟล์ของคุณ",
        vi: "hồ-sơ-của-bạn",
        bn: "আপনার-প্রোফাইল",
        ja: "あなたのプロフィール",
      },
    },
  },
  {
    path: "/banks-&-hq",
    name: "RM-Admin-portal",
    props: true,
    component: BanksAndHQ,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      const userDetails = store.state.userDetails;
      if (token && userDetails?.includes("ADMIN-CONSOLE")) {
        next();
      } else if (!token) {
        next({ name: "new-home" });
      } else {
        next({ name: "SearchForCompanies" });
      }
    },
    meta: {
      localizedNames: {
        en: "RM-Admin-portal",
        zh: "RM-管理-入口",
        ar: "بوابة-إدارة-RM",
        ko: "RM-관리-포털",
        th: "พอร์ทัลผู้ดูแล-RM",
        vi: "cổng-quản-trị-RM",
        bn: "RM-অ্যাডমিন-পোর্টাল",
        ja: "RM管理ポータル",
      },
    },
  },
  {
    path: "/MyOrders",
    name: "Orders",
    props: true,
    component: () => {
      const userDetails = store.state.userDetails;
      return userDetails?.includes("POSTPAID_ORDERS")
        ? import("@/views/BankUserOrders.vue")
        : import("@/views/MyOrders.vue");
    },
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (!token) {
        next({ name: "new-home" });
      } else {
        next();
      }
    },
    meta: {
      localizedNames: {
        en: "Orders",
        zh: "訂單",
        ar: "الطلبات",
        ko: "주문",
        th: "คำสั่งซื้อ",
        vi: "đơn-hàng",
        bn: "অর্ডারসমূহ",
        ja: "注文",
      },
    },
  },
  {
    path: "/MyOrders/:status",
    name: "AllOrders",
    props: true,
    component: AllOrders,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (!token) {
        next({ name: "new-home" });
      } else {
        to.name = to.params.status;
        next();
      }
    },
    meta: {
      localizedNames: {
        en: "AllOrders",
        zh: "所有-訂單",
        ar: "جميع-الطلبات",
        ko: "모든-주문",
        th: "คำสั่งซื้อทั้งหมด",
        vi: "tất-cả-đơn-hàng",
        bn: "সকল-অর্ডার",
        ja: "すべての注文",
      },
    },
  },
  {
    path: "/institutions",
    name: "Institutions",
    component: InstitutionsCorporate,
    meta: {
      localizedNames: {
        en: "Institutions",
        zh: "機構",
        ar: "المؤسسات",
        ko: "기관",
        th: "สถาบัน",
        vi: "tổ-chức",
        bn: "প্রতিষ্ঠানসমূহ",
        ja: "機関",
      },
    },
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: {
      localizedNames: {
        en: "PrivacyPolicy",
        zh: "隱私政策",
        ar: "سياسة-الخصوصية",
        ko: "개인정보-정책",
        th: "นโยบายความเป็นส่วนตัว",
        vi: "chính-sách-quyền-riêng-tư",
        bn: "গোপনীয়তা-নীতি",
        ja: "プライバシーポリシー",
      },
    },
  },
  {
    path: "/order-details",
    name: "CreateOrder",
    component: OrderDetails,
    meta: {
      localizedNames: {
        en: "CreateOrder",
        zh: "創建訂單",
        ar: "إنشاء-طلب",
        ko: "주문-생성",
        th: "สร้างคำสั่งซื้อ",
        vi: "tạo-đơn-hàng",
        bn: "অর্ডার-তৈরি-করুন",
        ja: "注文を作成",
      },
    },
  },
  {
    path: "/manual-order",
    name: "CreateManualOrder",
    component: ManualOrder,
    meta: {
      localizedNames: {
        en: "CreateManualOrder",
        zh: "創建手動訂單",
        ar: "إنشاء-طلب-يدوي",
        ko: "수동-주문-생성",
        th: "สร้างคำสั่งซื้อด้วยตนเอง",
        vi: "tạo-đơn-hàng-thủ-công",
        bn: "ম্যানুয়াল-অর্ডার-তৈরি-করুন",
        ja: "手動注文を作成",
      },
    },
  },
  {
    path: "/pi-order",
    name: "CompanySelection",
    component: () => import("@/views/PiOrderCompanyDeatils.vue"),
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      const userDetails = store.state.userDetails;
      if (token && !userDetails?.includes("OFFLINE-ORDERS")) {
        next();
      } else if (!token) {
        next({ name: "new-home" });
      } else {
        next({ name: "SearchForCompanies" });
      }
    },
    meta: {
      localizedNames: {
        en: "CompanySelection",
        zh: "公司-選擇",
        ar: "شركة-اختيار",
        ko: "회사-선택",
        th: "เลือกบริษัท",
        vi: "chọn-công-ty",
        bn: "কোম্পানি-নির্বাচন",
        ja: "会社の選択",
      },
    },
  },
  {
    path: "/rm-single-order",
    name: "CreateSingleOrder",
    component: RmManualOrder,
    meta: {
      localizedNames: {
        en: "CreateSingleOrder",
        zh: "創建單一訂單",
        ar: "إنشاء-طلب-مفرد",
        ko: "단일-주문-생성",
        th: "สร้างคำสั่งซื้อเดี่ยว",
        vi: "tạo-đơn-hàng-đơn",
        bn: "একক-অর্ডার-তৈরি-করুন",
        ja: "単一注文を作成",
      },
    },
  },
  {
    path: "/whatsapp/termsandconditions",
    name: "termsandconditions",
    component: WhatsappTermsAndConditions,
  },
  {
    path: "/team-invite",
    name: "TeamInvite",
    props: true,
    component: TeamInvite,
    meta: {
      localizedNames: {
        en: "TeamInvite",
        zh: "團隊-邀請",
        ar: "دعوة-الفريق",
        ko: "팀-초대",
        th: "เชิญทีม",
        vi: "mời-đội-nhóm",
        bn: "টিম-আমন্ত্রণ",
        ja: "チーム招待",
      },
    },
  },
  {
    path: "/replenish-credits/rm/:rm_id/user/:user_id/info/:info",
    name: "replenish-credits",
    component: ReplenishCredits,
    props: true,
    meta: {
      localizedNames: {
        en: "replenish-credits",
        zh: "補充-信用",
        ar: "إعادة-شحن-الرصيد",
        ko: "크레딧-보충",
        th: "เติมเครดิต",
        vi: "nạp-tín-dụng",
        bn: "ক্রেডিট-পুনরায়-ভরাট",
        ja: "クレジットを補充",
      },
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "notFound",
    component: NotFound,
  },
];

routes.push({
  path: "/login",
  name: "Login",
  props: true,
  component: LoginScreen,
});

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = VueCookies.get("token");
  trackPageView(to);
  if (to.name === "Login" && token) {
    next({ name: "new-home" });
  } else {
    next();
  }
});

export default router;
