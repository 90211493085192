<template>
  <div
    :class="
      'button-property-1-secondary-hover-false ' +
      'property-1-' +
      property1 +
      ' hover-' +
      hover
    "
  >
    <div class="login">{{ $t("sign-up") }}</div>
  </div>
</template>
<script>
export default {
  name: "ButtonProperty1SecondaryHoverFalse",
  components: {},
  props: {
    property1: {
      type: String,
      default: "primary",
    },
    hover: {
      type: String,
      default: "false",
    },
  },
  data() {},
};
</script>
<style scoped>
.button-property-1-secondary-hover-false,
.button-property-1-secondary-hover-false * {
  box-sizing: border-box;
}
.button-property-1-secondary-hover-false {
  background: #ffffff;
  border-radius: 13px;
  border-style: solid;
  border-color: #11365a;
  border-width: 2px;
  padding: 14px 22px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-width: 150px;
  position: relative;
}
.login {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  flex: 1;
}

@media only screen and (min-width: 1023px) and (max-width: 1220px) {
  .button-property-1-secondary-hover-false {
    padding: 14px 18px 14px 18px;
    border-width: 1.5px;
    align-self: stretch;
  }
  /* .login {
    font-size: 14px;
  } */
}
</style>
