<template>
  <div class="popup bg-b-lue">
    <div class="frame-35628">
      <div class="title-wrapper">
      <div class="title">{{$t("invite-members-to")}} {{ teamName }}</div>
      <i class="fa-solid fa-xmark fa-xl" @click="hideInviteMembersModal()"></i>
    </div>
      <div class="add-members-wrapper">
        <input
          type="email"
          :placeholder="$t('invite-email')"
          class="invitee-email"
          autocomplete="off"
          id="inviteeEmail"
          v-model="inviteeEmail"
          for="inviteeEmail"
        />
        <select v-model="role" class="invitee-email">
          <option value="" disabled>{{$t("select-role")}}</option>
          <option v-for="role in roles" :value="role" :key="role.id">
            {{ role.name }}
          </option>
        </select>
        <div class="add-button" @click="addInvitee">
          <span>{{$t("add")}}</span>
        </div>
      </div>
      <div class="invitees-wrapper">
        <div class="invitees-title">
          {{
            pendingInvitesList?.length !== 0 &&
            pendingInvitesList?.length !== undefined &&
            pendingInvitesList?.length !== null
              ? $t('pending-invites')
              : $t('your-inviting')
          }}
        </div>
        <div class="invitees-list invitees-list-height">
          <div class="table-wrapper" v-if="invitees && invitees?.length !== 0">
            <table
              id="searchResultTable"
              v-if="!mobile && invitees && invitees.length > 0"
            >
              <tbody>
                <tr
                  v-for="(invitee, index) in invitees"
                  :style="hoveredRow2 === index ? hoverStyle : {}"
                  :key="index"
                >
                  <td>
                    {{ invitee?.email }}
                  </td>
                  <td>
                    {{ invitee?.role }}
                  </td>
                  <td>
                    <div class="remove-invitee" @click="removeInvitee(index)">
                      <!-- <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M10.9425 6L9 7.9425L7.0575 6L6 7.0575L7.9425 9L6 10.9425L7.0575 12L9 10.0575L10.9425 12L12 10.9425L10.0575 9L12 7.0575L10.9425 6ZM9 1.5C4.8525 1.5 1.5 4.8525 1.5 9C1.5 13.1475 4.8525 16.5 9 16.5C13.1475 16.5 16.5 13.1475 16.5 9C16.5 4.8525 13.1475 1.5 9 1.5ZM9 15C5.6925 15 3 12.3075 3 9C3 5.6925 5.6925 3 9 3C12.3075 3 15 5.6925 15 9C15 12.3075 12.3075 15 9 15Z"
                          fill="#FC402B"
                        />
                      </svg> -->
                      <span style="color: #FC402B; margin-left: 7px;">{{$t('remove')}}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              id="searchResultDiv"
              v-if="mobile && invitees && invitees.length > 0"
            >
              <div
                v-for="(invitee, index) in invitees"
                :key="index"
                class="result-wrapper"
              >
                <div class="extra-wrapper">
                  <div class="row">
                    <div class="label">{{$t('name')}}:</div>
                    <div class="value">
                      {{ invitee?.email }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="label">{{$t('role')}}:</div>
                    <div class="value">{{ invitee?.role }}</div>
                  </div>
                </div>
                <div class="remove-invitee" @click="removeInvitee(index)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M10.9425 6L9 7.9425L7.0575 6L6 7.0575L7.9425 9L6 10.9425L7.0575 12L9 10.0575L10.9425 12L12 10.9425L10.0575 9L12 7.0575L10.9425 6ZM9 1.5C4.8525 1.5 1.5 4.8525 1.5 9C1.5 13.1475 4.8525 16.5 9 16.5C13.1475 16.5 16.5 13.1475 16.5 9C16.5 4.8525 13.1475 1.5 9 1.5ZM9 15C5.6925 15 3 12.3075 3 9C3 5.6925 5.6925 3 9 3C12.3075 3 15 5.6925 15 9C15 12.3075 12.3075 15 9 15Z"
                      fill="#FC402B"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div
            class="table-wrapper"
            v-else-if="pendingInvitesList && pendingInvitesList?.length !== 0"
          >
            <table
              id="searchResultTable"
              v-if="pendingInvitesList && pendingInvitesList.length > 0"
            >
              <thead style="background: #ecf4ff">
                <tr>
                  <th v-for="col in tableColumns" :key="col">
                    {{ col }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(invitee, index) in pendingInvitesList"
                  :style="hoveredRow2 === index ? hoverStyle : {}"
                  :key="index"
                >
                  <td>
                    {{ invitee?.email }}
                  </td>
                  <td>
                    {{ invitee?.lastMail }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="no-invitees" v-else>
            <AddTeamMembers />
            {{$t("add-members-to-invite")}}
          </div>
        </div>
        <div
          :class="[
            'create-button',
            { disabled: invitees && invitees?.length == 0 || disableInvite },
          ]"
          @click="invitees && invitees?.length == 0 || disableInvite ? null : SendInvitation()"
        >
          <span>{{$t('invite1')}}</span>
        </div>
        <div class="cancel-button" @click="hideInviteMembersModal()">
          <span>{{ newTeam === true ? $t("skip") : $t("cancel") }}</span>
        </div>
      </div>
    </div>
  </div>
  <Toastify ref="toastContainer" />
</template>
<script>
import VueCookies from "vue-cookies";
import {
  InviteMembers,
  GetDesignationList,
  CheckExistingTeamMembers,
  GetPendingInvites,
} from "@/api/APIs";
import Toastify from "@/components/Toastify.vue";
import AddTeamMembers from "@/assets/Animation/AddTeamMembers/AddTeamMembers.vue";

export default {
  name: "InviteMembersModal",
  props: {
    hideInviteMembersModal: {
      type: Function,
      required: true,
    },
    showInvitationStatusModal: {
      type: Function,
      required: true,
    },
    inviteMembersModal: {
      type: Function,
      required: true,
    },
    newTeam: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Toastify,
    AddTeamMembers,
  },
  data() {
    return {
      inviteeEmail: "",
      role: {},
      invitees: [],
      roles: null,
      teamName: "",
      teamId: "",
      pendingInvitesList: [],
      // tableColumns: ["Invited user", "Last invite"],
      mobile: window.innerWidth <= 767,
      disableInvite: false,
    };
  },
  computed: {
    tableColumns(){
     return [
      this.$t('invited-user'),
      this.$t('last-invite'),
    ]
    },
    //   isCreateButtonDisabled() {
    //     return this.teamName.trim().length < 3;
    //   },
  },
  async mounted() {
    await this.DesignationList();
    this.teamName = VueCookies.get("teamName");
    this.teamId = VueCookies.get("teamID");
    if (!this.newTeam) {
      await this.PendingInvites();
    }
  },
  methods: {
    async PendingInvites() {
      const payload = {
        team: this.teamId,
      };
      try {
        const response = await GetPendingInvites(payload);
        if (response.message === "SUCCESS") {
          this.pendingInvitesList = response?.data;
        } else {
          this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    async DesignationList() {
      try {
        const response = await GetDesignationList();
        if (response) {
          this.roles = response;
        } else {
          this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    async addInvitee() {
      this.pendingInvitesList = [];
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.inviteeEmail || !emailPattern.test(this.inviteeEmail)) {
        return this.$refs.toastContainer.addToast(
          "Please enter a valid email",
          { type: "error" }
        );
      }
      if (!this.role?.id) {
        return this.$refs.toastContainer.addToast(
          "Please select the user role",
          { type: "error" }
        );
      } else {
        try {
          let payload = {
            email: this.inviteeEmail,
            teamId: this.teamId,
          };
          const response = await CheckExistingTeamMembers(payload);
          if (response?.message == "NOT A MEMBER") {
            this.invitees.push({
              email: this.inviteeEmail,
              role: this.role.name,
              designation: this.role.id,
              team: this.teamId,
            });
            this.inviteeEmail = "";
            this.role = "";
          } else if (response?.message == "MEMBER") {
            this.$refs.toastContainer.addToast(
              `User already exist in the team '${this.teamName}'`,
              {
                type: "error",
              }
            );
          }
        } catch (error) {
          if (error.response && error.response.status !== 403) {
            return this.$refs.toastContainer.addToast(
              "Something went wrong. Please try again later.",
              { type: "error" }
            );
          }
        }
      }
    },
    removeInvitee(index) {
      this.invitees.splice(index, 1);
    },
    async SendInvitation() {
      this.disableInvite = true;
      try {
        let payload = {
          userList: this.invitees,
        };
        const response = await InviteMembers(payload);
        if (response.message === "User invited succesfully") {
          // VueCookies.set("teamID", response?.data?.id);
          this.showInvitationStatusModal(response);
          // this.$refs.toastContainer.addToast(`Invitation sent successfuly!`, {
          //   type: "success",
          // });
        } else if (response.message === "Team creation failed") {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            {
              type: "error",
            }
          );
        } else {
          return this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      } finally {
        this.disableInvite = false;
      }
    },
  },
};
</script>
<style scoped>
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  z-index: 9999;
}
.frame-35628 {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  justify-content: flex-start;
  width: 60vw;
  position: relative;
  background: #ffffff;
  border-radius: 24px;
  min-height: 384px;
  box-shadow: 0px 24px 24px 0px rgba(17, 54, 90, 0.2);
  padding: 2rem 3rem;
}
.title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.title {
  color: var(--Dark-Blue, #11365a);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-self: stretch;
  text-align: left;
}
.add-members-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  position: relative;
  gap: 8px;
}
.invitee-email {
  padding: 12px 24px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #11365a;
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
  outline: none;
  /* width: 40%; */
}
.invitees-wrapper,
.invitees-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  gap: 18px;
}
.invitees-list-height {
  height: 45vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: #f2f6fd;
  padding: 10px;
  border-radius: 16px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.invitees-title {
  color: var(--Text-Mid, #3e4855);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.18px;
}
.remove-invitee {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  cursor: pointer;
}
.create-button {
  display: flex;
  color: white;
  font-family: "Montserrat-Regular", sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  border-radius: 16px;
  padding: 12px 26px;
  justify-content: center;
  align-items: center;
  background: #11365a;
  cursor: pointer;
  align-self: stretch;
}
.create-button.disabled {
  opacity: 0.4;
  background: var(--Dark-Blue, #11365a);
}
.cancel-button,
.add-button {
  color: #11365a;
  border: 2px solid #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  border-radius: 16px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-self: stretch;
}
.table-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* margin: 20px 0; */
}
#searchResultTable {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  font-family: "Montserrat-Regular", sans-serif;
}
#searchResultTable th {
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  color: #11365a;
  padding: 10px 16px;
  min-width: 30px;
}
#searchResultTable td {
  text-align: left;
  padding: 10px 16px;
  color: #11365a;
  font-size: 16px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: -0.16px;
}
.no-invitees {
  align-self: stretch;
  color: #11365a5d;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.extra-wrapper {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}

@media only screen and (max-width: 767px) {
  .frame-35628 {
    width: 95vw;
    padding: 1.5rem 1.2rem;
    gap: 15px;
  }
  .invitees-wrapper {
    gap: 15px;
  }
  .animation-wrapper-AddTeamMembers {
    width: 80%;
  }
  .no-invitees {
    text-align: center;
    font-size: 14px;
  }
  .title {
    font-size: 18px;
  }
  .add-members-wrapper {
    flex-direction: column;
    gap: 10px;
  }
  .invitee-email {
    padding: 10px 20px;
  }
  .invitee-email:first-of-type {
    line-height: 20.5px;
  }
  .invitees-list-height {
    height: 28vh;
  }
  .add-button {
    padding: 6px 24px;
  }
  .create-button {
    padding: 8px 24px;
  }
  .cancel-button {
    padding: 6px 24px;
  }
  .invitees-title {
    font-size: 16px;
  }
  #searchResultDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    width: 100%;
    gap: 20px;
  }
  .result-wrapper {
    display: flex;
    padding: 15px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 5px;
    align-self: stretch;
    border-radius: 21.634px;
    border: 1.352px solid rgba(0, 0, 0, 0.15);
    background: #fff;
  }
  .remove-invitee {
    align-self: auto;
  }
  .row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    color: #11365a;
    /* gap: 8px; */
    overflow-wrap: anywhere;
  }
  .label {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    color: #11365a;
    font-family: "Montserrat-Regular", sans-serif;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 37.859px; */
    letter-spacing: -0.216px;
  }
  .value {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    /* align-self: stretch; */
    color: #11365a;
    font-family: "Montserrat-Regular", sans-serif;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    /* font-weight: 400; */
    letter-spacing: -0.216px;
    /* text-transform: capitalize; */
    flex: 1;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
  .invitee-email {
    width: 40%;
  }
  .add-button {
    width: 18%;
  }
}
@media only screen and (min-width: 1156px) {
  .invitee-email {
    width: 40%;
  }
  .add-button {
    width: 18%;
  }
}
</style>
