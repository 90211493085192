<template>
  <div class="footer">
    <div class="frame-35567">
      <div class="frame-7" v-if="!showTab">
        <div class="frame-1">
          <img
            class="image-2"
            :src="this.$root.getCDNlink('assets/logo.webp')"
            alt="Credence due diligence reports and International trade"
          />
        </div>
      </div>
      <div class="frame-35612">
        <div class="frame-355642">
          <div class="links">{{ $t("links") }}</div>
          <div class="frame-35564">
            <div class="blogs2" @click="redirectToBlogs">{{ $t("blogs") }}</div>
            <div class="blogs2" @click="redirectToProducts">
              {{ $t("product") }}
            </div>
            <div class="blogs2" @click="redirectToContactUs">
              {{ $t("contact-us") }}
            </div>
            <div class="blogs2" @click="redirectToAboutUs">
              {{ $t("about") }}
            </div>
          </div>
        </div>
      </div>
      <div class="frame-35570">
        <div class="frame-35561">
          <div class="subscribe-to-newsletter">{{ $t("subscribe") }}</div>
          <div class="here-s-what-we-ve-be2">
            {{ $t("stay-updated") }}
          </div>
        </div>
        <div class="frame-35569">
          <div class="frame-35560">
            <input
              class="news-letter-email"
              style="width: auto"
              type="text"
              v-model="email"
              :placeholder="email == null ? $t('email-placeholder') : `${email}`"
              required
            />
            <div
              class="button-instance"
              style="display: flex; flex-direction: row; gap: 20px"
            >
              <span class="button" v-on:click.prevent="subscribeNewsLetter()">{{
                $t("join")
              }}</span>
            </div>
          </div>
          <div class="here-s-what-we-ve-be3">
            {{ $t("subscribing") }}
          </div>
        </div>
      </div>
    </div>
    <div class="frame-35571">
      <div v-if="!show" class="frame-355643">
        <div class="_2023-credence-all-rights-reserved">
          {{ $t("copy-right") }}
        </div>
        <div class="privacy-policy" @click="redirectToPrivacyPolicy">
          {{ $t("privacy-policy") }}
        </div>
        <div class="privacy-policy" @click="redirectToPrivacyPolicy">
          {{ $t("terms-of-services") }}
        </div>
        <!-- <div class="privacy-policy">Cookies Settings</div> -->
      </div>
      <!--Before Login Language Selector Start -->
      <div class="select-language1" @click="this.$root.openLanguageModal()" :title="this.$root.selectedLanguage?.language" v-if="enableTranslation">
          <button class="language-capsule" style="background-color: white;">
            <span :class="`flag-icon flag-icon-${this.$root.selectedLanguage?.flag}`"></span>
            <span class="language-capsule-name">
              {{ this.$root.selectedLanguage?.code }}
            </span>
          </button>
        </div>
        <!--Before Login Language Selector End -->
      <div v-if="show" class="frame-355643">
        <div class="privacy-policy" @click="redirectToPrivacyPolicy">
          {{ $t("privacy-policy") }}
        </div>
        <div class="privacy-policy" @click="redirectToPrivacyPolicy">
          {{ $t("terms-of-services") }}
        </div>
        <!-- <div class="privacy-policy">Cookies Settings</div> -->
        <div class="_2023-credence-all-rights-reserved">
          {{ $t("copy-right") }}
        </div>
      </div>
    </div>
  </div>
  <Toastify ref="toastContainer" />
</template>
<script>
import { NewsLetter } from "@/api/APIs";
import VueCookies from "vue-cookies";
import Toastify from "@/components/Toastify.vue";

export default {
  name: "Footer-new",
  components: {
    Toastify,
  },
  props: {},
  data() {
    return {
      show: window.innerWidth < 767,
      showTab: window.innerWidth < 1023 && window.innerWidth > 768,
      email: VueCookies.get("email"),
      isLanguageModalOpen: false,
      enableTranslation:process.env.VUE_APP_ENABLE_TRANSLATION === "true"
    };
  },
  mounted() {
    this.handleResize();
    this.handleResizeTab();
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("resize", this.handleResizeTab);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("resize", this.handleResizeTab);
  },
  methods: {
    async subscribeNewsLetter() {
      if (!this.email) {
        return this.$refs.toastContainer.addToast(
          "Please enter the email to subscribe to our Newsletter.",
          { type: "error" }
        );
      }
      try {
        const response = await NewsLetter(this.email);
        if (response && response?.message == "SUCCESS") {
          return this.$refs.toastContainer.addToast(
            "Thank you for subscribing to our News and Offers! 🎉",
            { type: "success" }
          );
        } else {
          return this.$refs.toastContainer.addToast(
            "You have already subscribed to our Newsletter. Stay tuned for exciting updates! 👍🏻",
            { type: "info" }
          );
        }
      } catch (error) {
        this.emailError = "Something went wrong! Please try again.";
        console.error(error);
      }
    },
    handleResize() {
      this.show = window.innerWidth < 767;
    },
    handleResizeTab() {
      this.showTab = window.innerWidth < 1023 && window.innerWidth > 768;
    },
    redirectToPrivacyPolicy() {
      this.$router.push("/privacypolicy");
    },
    redirectToContactUs() {
      this.$router.push("/contact");
    },
    redirectToAboutUs() {
      this.$router.push("/aboutus");
    },
    redirectToBlogs() {
      this.$router.push("/blogs");
    },
    redirectToProducts() {
      this.$router.push("/product");
    },
  },
};
</script>
<style scoped>
.footer,
.footer * {
  box-sizing: border-box;
}
.footer {
  background: #ffffff;
  border-style: solid;
  border-color: #82d7db;
  border-width: 1px 0px 0px 0px;
  padding: 40px 7.5% 20px 7.5%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.news-letter-email {
  padding: 16.5px;
  border: 1px solid rgba(87, 87, 87, 0.5);
  border-radius: 13px;
  height: auto !important;
  box-shadow: none;
  margin: 0;
  width: 100% !important;
  outline: none;
}
.frame-35567 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  position: relative;
}
.frame-7 {
  display: flex;
  flex-direction: row;
  gap: 26px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-1 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.image-2 {
  flex-shrink: 0;
  width: 173px;
  height: 40px;
  position: relative;
  object-fit: cover;
}
.frame-35612 {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-35563 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  width: 140px;
  position: relative;
}
.frame-35564 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.page-1,
.blogs2 {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  cursor: pointer;
}
.frame-355642 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  width: 140px;
  position: relative;
}
.links {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-ExtraBold", sans-serif;
  font-size: 18px;
  font-weight: 800;
  position: relative;
}
.subscribe-to-newsletter {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}
.frame-35569 {
  display: flex;
  flex-direction: column;
  gap: 9px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-35560 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  width: 100%;
}
.frame-35559 {
  background: #ffffff;
  border-radius: 13px;
  border-style: solid;
  border-color: #11365a;
  border-width: 1px;
  padding: 15px 24px 15px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 343px;
  min-width: 150px;
  position: relative;
  box-shadow: 0px 8px 14px 0px rgba(0, 0, 0, 0.1);
}
.enter-email-address {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  position: relative;
}
.here-s-what-we-ve-be2 {
  color: #3e4855;
  text-align: left;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 14px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
}
.frame-35571 {
  border-style: solid;
  border-color: #3e4855;
  border-width: 1px 0px 0px 0px;
  padding: 40px 0px 20px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  position: relative;
}
.frame-355643 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 55%;
  position: relative;
}
._2023-credence-all-rights-reserved {
  color: #3e4855;
  text-align: left;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
}
.privacy-policy {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  cursor: pointer;
}
.frame-35572 {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.facebook-negative-instance {
  flex-shrink: 0 !important;
  width: 16px !important;
  height: 16px !important;
}
.linked-in-negative-instance {
  flex-shrink: 0 !important;
  width: 16px !important;
  height: 16px !important;
}
.instagram-negative-instance {
  flex-shrink: 0 !important;
  width: 16px !important;
  height: 16px !important;
}
.twitter-negative-instance {
  flex-shrink: 0 !important;
  width: 16px !important;
  height: 16px !important;
}
.you-tube-negative-instance {
  flex-shrink: 0 !important;
  width: 16px !important;
  height: 16px !important;
}
.button {
  vertical-align: super;
  border-radius: 13px;
  background-color: #11365a;
  padding: 15px 24px 15px 24px;
  color: white;
  text-align: center;
  font-family: NunitoSans-SemiBold, sans-serif;
  font-size: 18px;
  font-weight: 600;
  position: relative;
}
.here-s-what-we-ve-be3 {
  color: #3e4855;
  text-align: left;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 14px;
  line-height: 180%;
  font-weight: 500;
}
.select-language {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.select-language1 {
  display: inline-block;
  cursor: pointer;
}

.language-capsule {
  border: 3px solid #11365A;
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 5px 12px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: rgb(226, 234, 245);
  justify-content: center;
  width: -webkit-fill-available;
}

.flag-icon {
  width: 20px;
  height: 15px;
  margin-right: 8px;
}

.language-capsule-name {
  font-weight: bold;
  color: #11365A;
  text-transform: uppercase;
}

/* Media query */
@media only screen and (max-width: 767px) {
  /* Header */
  .frame-35567 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  .frame-35563,
  .frame-355642 {
    align-items: center;
  }
  .frame-35564 {
    gap: 10px;
    align-items: center;
  }
  .image-2 {
    height: 45px;
    width: auto;
  }
  .solutions2,
  .links {
    font-size: 14px;
    font-weight: 800;
  }
  .page-1,
  .blogs2 {
    font-size: 14px;
  }
  .subscribe-to-newsletter {
    font-size: 16px;
    text-align: center;
  }
  .here-s-what-we-ve-be2 {
    font-size: 12px;
    text-align: center;
  }
  .frame-35561 {
    gap: 6px;
  }
  .frame-35559 {
    padding: 4% 4%;
    width: 90%;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .enter-email-address {
    font-size: 12px;
  }
  .button {
    /* padding: 28% 50%; */
    font-size: 14px;
  }
  /* .here-s-what-we-ve-be3 {
    font-size: 12px;
    text-align: center;
  } */
  .frame-35571 {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .frame-35572 {
    justify-content: space-between;
    width: 60%;
    gap: 0;
  }
  .frame-355643 {
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 2rem;
  }
  .privacy-policy,
  ._2023-credence-all-rights-reserved,
  .here-s-what-we-ve-be3 {
    font-size: 12px;
    text-align: center;
    width: 100%;
  }

  .select-language1{
    margin-top: 7px;
  }

  .language-capsule {
    border: 2px solid #11365A;
    display: flex;
    align-items: center;
    border-radius: 13px;
    padding: 2px 8px;
    cursor: pointer;
    transition: all 0.3sease;
    background: rgb(226, 234, 245);
    justify-content: center;
    width: -webkit-fill-available;
    height: 25px;
  }

  .flag-icon {
    width: 10px;
    height: 10px;
    margin-right: 3px;
  }

  .language-capsule-name {
    font-size: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .frame-35567 {
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
  }
  .image-2 {
    height: 45px;
    width: auto;
    top: -12px;
  }
  .solutions2,
  .links {
    font-size: 16px;
    font-weight: 800;
  }
  .page-1,
  .blogs2 {
    font-size: 16px;
  }
  .subscribe-to-newsletter {
    font-size: 16px;
  }
  .here-s-what-we-ve-be2 {
    font-size: 14px;
  }
  .frame-35561 {
    gap: 6px;
  }
  .frame-35559 {
    padding: 4% 4%;
    width: 90%;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .enter-email-address {
    font-size: 14px;
  }
  .button {
    /* padding: 28% 50%; */
    font-size: 16px;
  }
  .here-s-what-we-ve-be3 {
    font-size: 16px;
  }
  .frame-35571 {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
  .frame-35612 {
    gap: 0;
  }
  .frame-35572 {
    justify-content: space-between;
    width: 20%;
    gap: 0;
  }
  .frame-355643 {
    flex-direction: row;
    width: 55%;
  }
  .privacy-policy,
  ._2023-credence-all-rights-reserved {
    font-size: 12px;
  }

  .select-language1{
    margin-top: 7px;
  }

  .language-capsule {
    border: 2px solid #11365A;
    display: flex;
    align-items: center;
    border-radius: 13px;
    padding: 2px 8px;
    cursor: pointer;
    transition: all 0.3sease;
    background: rgb(226, 234, 245);
    justify-content: center;
    width: -webkit-fill-available;
    height: 25px;
  }

  .flag-icon {
    width: 10px;
    height: 10px;
    margin-right: 3px;
  }

  .language-capsule-name {
    font-size: 10px;
  }
}

@media only screen and (min-width: 1024px) {
}
</style>
