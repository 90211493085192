<template>
  <div class="related-blogs" v-if="blogs.length > 0">
    <div class="title">{{ $t("blogs") }}</div>
    <div class="carousel-container">
      <div class="carousel-wrapper">
        <div
          class="carousel-card"
          v-for="blog in blogs.slice(0, 8)"
          :key="blog.id"
          @click="redirectToBlog(blog)"
        >
          <img
            :src="this.$root.getCDNlink(blog.bannerImage)"
            alt="Related Blog Banner"
          />
          <div
            class="blog-meta"
            style="
              color: #8f8f8f;
              font-size: small;
              text-align: left;
              line-height: 16px;
              padding: 0 20px;
              margin-top: 10px;
            "
          >
            <span class="author"
              >By <strong>{{ blog?.author }}</strong> <br />
              {{ formattedPublicationDate(blog?.publicationDate) }}</span
            >
          </div>
          <h3 style="padding: 0 20px">{{ blog.title }}</h3>
          <p class="blog-description">{{ blog.summary }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetBlog } from "@/api/APIs";

export default {
  name: "BlogsCarousel",
  components: {},
  data() {
    return {
      blogs: [],
      selectedCategory: "",
    };
  },
  mounted() {
    this.getBlogsList();
  },
  methods: {
    async getBlogsList() {
      const payload = {
        category: this.selectedCategory,
      };

      try {
        const response = await GetBlog(payload);
        if (response.message === "SUCCESS") {
          this.blogs = response.blog;
        } else {
          return this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    formattedPublicationDate(publishDate) {
      if (!publishDate) return "Unknown Date";

      const date = new Date(publishDate);
      if (isNaN(date.getTime())) return "Invalid Date";

      return new Intl.DateTimeFormat("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }).format(date);
    },
    redirectToBlog(blog) {
        this.$router.push(`/blog/${blog.slug}/${blog.id}`);
    },
  },
};
</script>

<style scoped>
.carousel-container {
  display: flex;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.carousel-wrapper {
  display: flex;
  gap: 25px;
  padding: 20px 10px;
}
.carousel-card {
  flex: 0 0 auto;
  width: 300px;
  background: white;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease;
  box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.18);
}
.carousel-card:hover {
  transform: scale(1.05);
}
.carousel-card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.carousel-card h3 {
  font-size: 18px;
  margin: 10px 0;
  text-align: left;
}
.carousel-card p {
  font-size: 14px;
  color: #555;
}
.blog-meta {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  font-size: 0.9em;
  color: white;
}
.related-blogs {
  width: 85%;
  padding: 20px 0;
  border-radius: 13px;
  /* box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.18), -4px -4px 8px 0px rgba(255, 255, 255, 1); */
  /* background: #f4f8fa; */
  margin-top: 2rem;
}
.title {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-ExtraBold", sans-serif;
  font-size: 35px;
  font-weight: 800;
  position: relative;
}
.blog-description {
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat-Light", sans-serif;
  text-align: justify;
  padding: 0px 20px;
}

@media only screen and (max-width: 767px) {
  .title {
    font-size: 18px;
  }
  .carousel-card {
    width: 240px;
    border: 0.5px solid rgb(228, 228, 228);
  }
  .blog-meta {
    font-size: 12px !important;
  }
  .carousel-card h3 {
    font-size: 16px;
  }
  .carousel-card p {
    font-size: 13px;
  }
  .carousel-wrapper {
    gap: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
  .title {
    font-size: 28px;
  }
  .carousel-card h3 {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1156px) {
}
</style>
