import { createApp, watch } from "vue";
import App from "./App.vue";
import router from "./router";
import { createI18n } from "vue-i18n";
import "@fortawesome/fontawesome-free/css/all.css";
import "vue3-toastify/dist/index.css";
import Vue3Toastify from "vue3-toastify";
import { createHead } from "@unhead/vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
import VueCookies from "vue-cookies";
import store from "./store";
import VueLazyload from "vue-lazyload";
import config from "@/config.js";

const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: {},
});

const app = createApp(App);
const head = createHead();

app.use(router);
app.use(store);
app.use(i18n);
app.use(Vue3Toastify, {
  autoClose: 3000,
  limit: 3,
  clearOnUrlChange: true,
  style: {
    opacity: "1",
    userSelect: "initial",
  },
});
app.use(head);
app.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1,
});

let reCaptchaScriptLoaded = false;

async function loadPageTranslation(routeName, locale) {
  try {
    const jsonUrl = `${config.jsonStgCdn}common/${locale}.json`;
    const jsonUrl1 = `${config.jsonStgCdn}${routeName}/${locale}.json`;

    const response = await fetch(jsonUrl);
    const response1 = await fetch(jsonUrl1);

    const data = await response.json();
    const data1 = await response1.json();
    i18n.global.setLocaleMessage(locale, {
      ...data,
      ...i18n.global.getLocaleMessage(locale),
      ...data1,
    });
    i18n.global.locale = locale;
  } catch (error) {
    console.warn(
      `No translation file found for page: ${routeName}, locale: ${locale}`
    );
    fallbackEnglish(locale, routeName);
  }
}

const fallbackEnglish = async (locale, routeName) => {
  // Fallback to local English JSON
  try {
    const commonEN = await import(`../locales/common/en.json`);
    const routeEN = await import(`../locales/${routeName}/en.json`);
    i18n.global.setLocaleMessage(locale, {
      ...commonEN,
      ...i18n.global.getLocaleMessage(locale),
      ...routeEN,
    });
    i18n.global.locale = "en";
    if (!localStorage.getItem("errorToastShowedOnce")) {
      // Show toast message, if converted back to english
      store.dispatch("addToast", {
        message: "Language selection is not available in offline mode.",
        type: "error",
      });
      localStorage.setItem("errorToastShowedOnce", true);
    }
  } catch (localError) {
    console.error("Failed to load local English JSON:", localError);
  }
};

router.beforeEach(async (to, from, next) => {
  const locale = i18n.global.locale;
  await loadPageTranslation(to.name, locale);

  if (
    (to.name === "ShareYourThoughts" &&
      VueCookies.get("token") === undefined) ||
    (to.name === "ShareYourThoughts" && VueCookies.get("token") === null)
  ) {
    if (!reCaptchaScriptLoaded) {
      app.use(VueReCaptcha, {
        siteKey: "6Ldwj_cpAAAAAMFNhKnnTgLeT7yboKC_Nv8zZ0cx",
      });
      reCaptchaScriptLoaded = true;
      loadReCaptchaScript();
      next();
    } else {
      loadReCaptchaScript();
      next();
    }
  } else {
    removeReCaptchaScript();
    next();
  }
});

watch(
  () => i18n.global.locale,
  async (newLocale) => {
    const currentRoute = router.currentRoute.value;
    if (currentRoute && currentRoute.name) {
      if (newLocale == "en") {
        const selectedLanguage = {
          code: "en",
          language: "English",
          region: "Global",
          flag: "gb",
        };
        i18n.global.locale = newLocale;
        store.dispatch("updateSelectedLanguage", selectedLanguage);
      }
      await loadPageTranslation(currentRoute.name, newLocale);
    }
  }
);

function loadReCaptchaScript() {
  if (!document.getElementById("recaptcha-script")) {
    const script = document.createElement("script");
    script.id = "recaptcha-script";
    script.src = `https://www.google.com/recaptcha/api.js?render=6Ldwj_cpAAAAAMFNhKnnTgLeT7yboKC_Nv8zZ0cx`;
    script.async = true;
    document.head.appendChild(script);
  }
}

function removeReCaptchaScript() {
  const script = document.getElementById("recaptcha-script");
  if (script) {
    script.remove();
  }

  const badge = document.querySelector(".grecaptcha-badge");
  if (badge) {
    badge.remove();
  }
}

app.mount("#app");
