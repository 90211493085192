<template>
  <div
    class="bg-b-lue"
    v-on:click.self="close()"
    v-if="showEditBranch == false"
  >
    <div
      class="tables-wrapper"
      :style="{
        justifyContent:
          branchList === null || branchList?.length == 0
            ? 'center'
            : 'flex-start',
      }"
    >
      <div class="table-wrapper">
        <div class="table-title-wrapper">
          <div class="table-title">
            {{ $t("branchesTitle") }} {{ bankDetails.name }}
          </div>
          <div class="view-all-button" @click="AddNewBranch()">
            {{ $t('createNewButton') }}
          </div>
        </div>
        <!-- Desktop -->
        <div
          class="table-container"
          v-if="!mobile && branchList && branchList?.length > 0"
        >
          <table id="searchResultTable">
            <thead style="background: #ecf4ff">
              <tr>
                <th v-for="col in tabelColumns" :key="col">
                  {{ col }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="branchDetails in branchList" :key="branchDetails?.id">
                <td>
                  {{ branchDetails?.branchName }}
                </td>
                <td>
                  {{
                    branchDetails?.location !== null
                      ? branchDetails?.location
                      : "-"
                  }}
                </td>
                <td>
                  {{ branchDetails?.address }}
                </td>
                <td class="action-buttons">
                  <span class="button" @click="editBranch(branchDetails)">
                    {{ $t('editButton') }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="view-all-button" @click="close()">
          {{ $t('doneButton') }}
        </div>
        <!-- Mobile -->
        <div
          id="searchResultDiv"
          v-if="mobile && branchList && branchList?.length > 0"
        >
          <div
            v-for="(branchDetails, index) in branchList"
            :key="index"
            class="result-wrapper"
          >
            <div class="row">
              <div class="label">{{ $t('branchNameLabel') }}</div>
              <div class="value">
                {{ branchDetails?.branchName }}
              </div>
            </div>
            <div class="row">
              <div class="label">{{ $t('locationLabel') }}</div>
              <div class="value">
                {{
                  branchDetails?.location !== null
                    ? branchDetails?.location
                    : "-"
                }}
              </div>
            </div>
            <div class="row">
              <div class="label">{{ $t('addressLabel') }}</div>
              <div class="value">
                {{ branchDetails?.address }}
              </div>
            </div>
            <div class="check-status" @click="editBranch(branchDetails)">
              <span class="button">
                {{ $t('editButton') }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="branchList === null || branchList?.length == 0"
        class="no-data-wrapper"
      >
        <p class="no-data">{{ $t('noDataFound') }}</p>
      </div>
    </div>
  </div>
  <Toastify ref="toastContainer" />
  <EditBranch
    v-if="showEditBranch"
    :bankDetails="bankDetails"
    :branchDetails="selectedBranch"
    :newBranch="addBranch"
    @close="hideEditBranch()"
  />
</template>
<script>
// import VueCookies from "vue-cookies";
import Toastify from "@/components/Toastify.vue";
import EditBranch from "@/views/Modals/InternalUserModals/EditBranch.vue";
import { GetBranchList } from "@/api/APIs";

export default {
  name: "ViewBranches",
  components: {
    Toastify,
    EditBranch,
  },
  props: {
    bankDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isMobile: window.innerWidth < 1023,
      mobile: window.innerWidth <= 767,
      branchList: null,
      showEditBranch: false,
      selectedBranch: null,
      addBranch: false,
    };
  },
  computed: {
    tabelColumns(){ 
      return [
        this.$t("branch-name"),
        this.$t("location"),
        this.$t("address"),
        this.$t("actions"),
      ]
    },
  },
  mounted() {
    this.BranchesList();
    window.addEventListener("resize", this.handleResponsive);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResponsive);
  },
  methods: {
    async BranchesList() {
      try {
        const response = await GetBranchList(this.bankDetails.id);
        if (response) {
          this.branchList = response?.allBranches;
        } else {
          this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    handleResponsive() {
      this.isMobile = window.innerWidth < 1023;
    },
    editBranch(branchDetails) {
      this.selectedBranch = branchDetails;
      this.addBranch = false;
      this.showEditBranch = true;
    },
    hideEditBranch() {
      this.BranchesList();
      this.selectedBranch = null;
      this.addBranch = false;
      this.showEditBranch = false;
    },
    AddNewBranch() {
      this.addBranch = true;
      this.showEditBranch = true;
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  z-index: 9999;
}
.tables-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 70%;
  padding: 40px;
  border-radius: 16px;
  background-color: white;
  gap: 2rem;
  /* align-self: stretch; */
}
.table-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1.5rem;
}
.table-title-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.table-title {
  color: #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.28px;
}
.view-all-button {
  border-radius: 13px;
  border: 2px solid #11365a;
  padding: 8px 36px;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  color: #11365a;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
#searchResultTable {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  font-family: "Montserrat-Regular", sans-serif;
}
#searchResultTable th {
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  color: #11365a;
  padding: 10px 16px;
  min-width: 30px;
}
.no-data {
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  color: #11365a;
  font-size: 16px;
  font-weight: 500;
}
#searchResultTable td {
  text-align: left;
  padding: 10px 16px;
  color: #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: -0.16px;
}
.action-buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}
.button {
  cursor: pointer;
}
.separation-line {
  width: 100%;
  height: 2px;
  opacity: 0.2;
  background: #3e4855;
}
.no-data-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30vh;
}
.table-container {
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

@media only screen and (max-width: 767px) {
  /* .table-container {
    padding-top: 1rem;
  } */
  .tables-wrapper {
    padding: 2rem 1rem;
    width: 85%;
  }
  .table-wrapper {
    gap: 1rem;
  }
  #searchResultDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    width: 100%;
    gap: 20px;
  }
  .result-wrapper {
    display: flex;
    padding: 21.634px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    align-self: stretch;
    border-radius: 21.634px;
    border: 1.352px solid rgba(0, 0, 0, 0.15);
    background: #fff;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    color: #11365a;
    gap: 20px;
  }
  .label {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    color: #11365a;
    font-family: "Montserrat-Regular", sans-serif;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 37.859px; */
    letter-spacing: -0.216px;
    width: auto;
  }
  .value {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    color: #11365a;
    font-family: "Montserrat-Regular", sans-serif;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    /* font-weight: 400; */
    letter-spacing: -0.216px;
    flex: 1;
  }
  .check-status {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 10.817px;
    border: 1.352px solid #11365a;
    color: #11365a;
    font-family: "Montserrat-Regular", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: -0.216px;
  }
  .view-all-button {
    padding: 2px 15px;
    order: 1;
  }
  .table-title {
    font-size: 18px;
    text-align: left;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
}
@media only screen and (min-width: 1156px) {
}
</style>
