<template>
  <div class="bg-b-lue" v-if="isLoading">
    <div class="loader-wrapper">
      <LoaderAnimation />
      <div
        style="
          font-size: 14px;
          margin-top: 30px;
          color: #2e5ba7;
          font-weight: 600;
        "
      >
        {{$t("please-wait")}} <br />
        {{$t("redirected-to")}}...
      </div>
    </div>
  </div>
  <div v-if="invalidPaymentSecret" class="invalid-link-content-wrapper">
    <PaymentFailure id="completed-animation" />
    <div class="invalid-link-text">{{$t("payment-link-expired")}}</div>
    <div class="go-to-homepage-button" @click="goToHomePage">
      {{$t("goto-home-page")}}
    </div>
  </div>
</template>
<script>
import PaymentFailure from "@/assets/Animation/PaymentFailure/PaymentFailure.vue";
import { PaymentRetry } from "@/api/APIs";
import LoaderAnimation from "@/assets/Animation/LoaderAnimation.vue";

export default {
  name: "PaymentRetry",
  components: {
    PaymentFailure,
    LoaderAnimation,
  },
  data() {
    return {
      paymentLink: "",
      isLoading: false,
      invalidPaymentSecret: false,
    };
  },
  async mounted() {
    this.isLoading = true;
    this.redirectToRepaymentLink();
  },
  methods: {
    async redirectToRepaymentLink() {
      const urlParams = new URLSearchParams(window.location.search);
      const orderEmail = urlParams.get("orderEmail");
      const paymentSecret = urlParams.get("paymentSecret");
      const doNewPayment = urlParams.get("doNewPayment")
        ? true
        : false;
      const response = await PaymentRetry(orderEmail, paymentSecret, doNewPayment);
      if (response && response?.result?.paymentRequired === true) {
        window.location.href = response?.result?.paymentLink;
        this.isLoading = false;
      } else if (response && response?.message == this.$t("invalid-secret")) {
        this.isLoading = false;
        this.invalidPaymentSecret = true;
      }
    },
    goToHomePage() {
      this.$router.push("/");
    },
  },
};
</script>
<style scoped>
.invalid-link-content-wrapper,
.invalid-link-content-wrapper * {
  box-sizing: border-box;
}
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  z-index: 9999;
}
.loader-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.invalid-link-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  position: relative;
  width: 100%;
  height: 100vh;
  gap: 20px;
}
.invalid-link-text {
  font-size: 20px;
  font-weight: 600;
  font-family: "Montserrat-Medium", sans-serif;
  text-align: center;
}
.go-to-homepage-button {
  background: #11365a;
  color: white;
  font-size: 16px;
  font-family: "Montserrat-Medium", sans-serif;
  font-weight: 600;
  border-radius: 13px;
  padding: 10px 20px;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  #completed-animation {
    width: 75px;
    height: 75px;
  }
  .invalid-link-text {
    font-size: 16px;
  }
  .go-to-homepage-button {
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
