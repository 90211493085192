<template>
  <div class="overlay">
    <div class="modal">
      <h2 style="margin-bottom: 2rem;">{{$t("change-password")}}</h2>
      <form @submit.prevent="saveNewPassword('Change')">
        <div class="input-group">
          <label for="oldPassword">{{$t("current-password")}}</label>
          <div class="password-input">
            <input
              :type="showOldPassword ? 'text' : 'password'"
              id="oldPassword"
              v-model="oldPassword"
              required
              :placeholder="$t('enter-current-password')"
            />
            <i
              @click="togglePasswordVisibility('old')"
              :class="passwordIcon(showOldPassword)"
              :style="passwordIconStyle(showOldPassword)"
            ></i>
          </div>
        </div>

        <div class="input-group">
          <label for="newPassword">{{$t("new-password")}}</label>
          <div class="password-input">
            <input
              :type="showNewPassword ? 'text' : 'password'"
              id="newPassword"
              v-model="newPassword"
              required
              :placeholder="$t('enter-new-password')"
            />
            <div v-if="!passwordValidation.valid">
              <i
                class="fa-solid fa-circle-exclamation"
                :style="{
                  color: password !== '' ? 'red' : 'green',
                  right: '45px',
                }"
              >
              </i>
              <div
                v-if="!passwordValidation.valid && newPassword !== ''"
                class="tooltip-bubble"
              >
                <span class="tooltip-title">{{$t("pass-must-contain")}}</span>
                <div v-for="(rule, index) in invalidRules" :key="index">
                  • {{ rule.message }}
                </div>
              </div>
              <div
                v-if="!passwordValidation.valid && newPassword !== ''"
                class="tooltip-bubble-arrrow"
              >
                <i class="fa-solid fa-sort-down" style="color: #e0f2ff"></i>
              </div>
            </div>
            <div v-if="passwordValidation.valid">
              <i
                class="fa-solid fa-check"
                style="color: green; font-size: large; right: 45px"
              ></i>
            </div>
            <i
              @click="togglePasswordVisibility('new')"
              :class="passwordIcon(showNewPassword)"
              :style="passwordIconStyle(showNewPassword)"
            ></i>
          </div>
        </div>

        <div class="input-group">
          <label for="confirmNewPassword">{{$t("confirm-password")}}</label>
          <div class="password-input">
            <input
              :type="showConfirmPassword ? 'text' : 'password'"
              id="confirmNewPassword"
              v-model="confirmNewPassword"
              required
              :placeholder="$t('confirm-password')"
              @input="validatePasswords"
            />
            <i
              @click="togglePasswordVisibility('confirm')"
              :class="passwordIcon(showConfirmPassword)"
              :style="passwordIconStyle(showConfirmPassword)"
            ></i>
            <small v-if="confirmPasswordError" class="error">{{
              confirmPasswordError
            }}</small>
          </div>
        </div>

        <div class="button-group">
          <button type="submit" :disabled="isSubmitDisabled || saving">
            {{ saving ? $t("saving")+"..." : $t("save") }}
          </button>
          <button type="button" class="cancel" @click="cancelPasswordChange">
            {{$t("cancel")}}
          </button>
        </div>
      </form>
    </div>
  </div>
  <Toastify ref="toastContainer" />
</template>

<script>
import { PasswordAction } from "@/api/APIs";
import Toastify from "@/components/Toastify.vue";

export default {
  name: "ChangePasswordModal",
  components: {
    Toastify,
  },
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      confirmPasswordError: "",
      saving: false,
    };
  },
  computed: {
    rule() {
      return [
        {
          message: this.$t("max-pass-Char"),
          regex: /^.{8,}$/,
          isValid: false,
        },
        {
          message: this.$t("uppercase-letter"),
          regex: /^(?=.*[A-Z]).+$/,
          isValid: false,
        },
        {
          message: this.$t("number"),
          regex: /^(?=.*\d).+$/,
          isValid: false,
        },
        {
          message: this.$t("special-character"),
          regex: /^(?=.*[^a-zA-Z0-9]).+$/,
          isValid: false,
        },
      ]
    },
    isSubmitDisabled() {
      return (
        !this.oldPassword ||
        !this.newPassword ||
        !this.confirmNewPassword ||
        !this.passwordValidation.valid ||
        this.confirmPasswordError !== ""
      );
    },
    passwordValidation() {
      let errors = [];

      this.rule.forEach((rule) => {
        rule.isValid = rule.regex.test(this.newPassword);
        if (!rule.isValid) {
          errors.push(rule.message);
        }
      });

      return { valid: errors.length === 0 };
    },
    invalidRules() {
      return this.rule.filter((rule) => !rule.isValid);
    },
  },
  methods: {
    togglePasswordVisibility(field) {
      if (field === "old") this.showOldPassword = !this.showOldPassword;
      if (field === "new") this.showNewPassword = !this.showNewPassword;
      if (field === "confirm")
        this.showConfirmPassword = !this.showConfirmPassword;
    },
    passwordIcon(isVisible) {
      return isVisible ? "fa-regular fa-eye" : "fa-regular fa-eye-slash";
    },
    passwordIconStyle(isVisible) {
      return {
        color: isVisible ? "#11365A" : "gray",
      };
    },
    validatePasswords() {
      this.confirmPasswordError =
        this.newPassword !== "" &&
        this.confirmNewPassword !== "" &&
        this.newPassword !== this.confirmNewPassword
          ? this.$t("pass-doesnot-match")
          : "";
    },
    async saveNewPassword(action) {
      if (this.saving) return;
      this.saving = true;
      try {
        const payload = {
          email: this.email,
          action,
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
          secret: this.$root.userToken,
        };
        const response = await PasswordAction(payload);
        if (response && response.message === "SUCCESS") {
          this.$emit("showStatus");
        } else {
          this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch {
        this.$refs.toastContainer.addToast(
          this.$t("please-try-again"),
          {
            type: "error",
          }
        );
      } finally {
        this.saving = false;
      }
    },
    cancelPasswordChange() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.overlay {
  background: rgba(0, 0, 0, 8%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 12px;
  width: 100%;
  max-width: 400px;
}

.input-group {
  margin-bottom: 20px;
  text-align: left;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.password-input {
  position: relative;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: none;
  outline: none;
  margin: 0;
  height: auto;
}

.password-input i {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2em;
}

.error {
  color: red;
  font-size: 12px;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 20px;
}

button {
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  width: -webkit-fill-available;
}

button:not([disabled]) {
  background: #11365a;
  color: white;
  border: none;
}

button.cancel {
  background: white;
  color: #11365a;
  border: 1px solid #11365a;
}

button[disabled] {
  background: #ccc;
  cursor: not-allowed;
  border: none;
}
.tooltip-bubble {
  display: flexbox;
  background: #e0f2ff;
  color: #000000;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: small;
  text-align: left;
  line-height: 22px;
  border-radius: 6px;
  padding: 15px 20px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  right: -15px;
  box-shadow: 0px 2px 15px 0px;
}
.tooltip-title {
  font-weight: 600;
  margin-bottom: 10px;
}
.tooltip-bubble-arrrow {
  width: 65%;
  font-size: large;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 82%;
  right: -120px;
}
</style>
