<template>
  <div
    class="bg-b-lue"
    v-on:click.self="close()"
    v-if="showEditBranch == false"
  >
    <div
      class="tables-wrapper"
      :style="{
        justifyContent:
          banksList === null || banksList?.length == 0
            ? 'center'
            : 'flex-start',
      }"
    >
      <div class="table-wrapper">
        <div class="table-title-wrapper">
          <div class="table-title">{{ $t('bulk_user_upload.title') }}</div>
          <button
            v-if="selectFile"
            class="download-button"
            @click="downloadFile()"
          >
            {{ $t('bulk_user_upload.download_template') }}
          </button>
        </div>
        <div class="search-bank-wrapper" v-if="!selectFile">
          <input
            type="email"
            :placeholder="`${ $t('bulk_user_upload.search_entity') }`"
            class="invitee-email"
            style="width: 70%"
            autocomplete="off"
            id="bankName"
            v-model="bankName"
            for="bankName"
            @input="debouncedSearch"
          />
          <button
            type="submit"
            class="save-button"
            :style="{
              opacity: searchButton ? '0.5' : '1',
              cursor: searchButton ? 'not-allowed' : 'pointer',
              width: '30%',
              alignSelf: 'center',
            }"
            :disabled="searchButton"
            @click="searchBank()"
          >
            {{ $t('bulk_user_upload.search_button') }}
          </button>
        </div>
        <!-- Desktop -->
        <div class="table-container" v-if="!selectFile && !mobile">
          <table
            id="searchResultTable"
            v-if="banksList && banksList?.length > 0"
          >
            <thead style="background: #ecf4ff">
              <tr>
                <th v-for="col in tabelColumns" :key="col">
                  {{ col }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="bankDetails in banksList"
                :key="bankDetails?.id"
                @click="selectBank(bankDetails)"
                :class="{ selected: selectedBank?.id === bankDetails.id }"
              >
                <td>
                  <input
                    type="radio"
                    :checked="selectedBank?.id === bankDetails.id"
                    @change="selectBank(bankDetails)"
                  />
                </td>
                <td>
                  {{ bankDetails?.name }}
                </td>
                <td>
                  {{ bankDetails?.type }}
                </td>
                <td>
                  {{
                    bankDetails?.countryName !== null &&
                    bankDetails?.countryName !== ""
                      ? bankDetails?.countryName
                      : "-"
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="no-results" v-else>{{ $t('bulk_user_upload.no_results') }}</div>
        </div>

        <div class="add-members-wrapper" v-if="selectFile">
          <div
            class="field-wrapper"
            @dragover="handleDragOver"
            @drop="handleDrop"
          >
            <!-- excel file upload and drag and drop box  -->
            <input
              type="file"
              id="fileInput"
              @change="handleFileUpload"
              accept=".xlsx, .xls, .csv"
              class="file-input"
            />
            <label for="fileInput" class="upload-label">
              <div class="upload-area">
                <p class="upload-area-text">
                  {{
                    file
                      ? `${file.name}`
                      : $t('bulk_user_upload.click_to_upload')
                  }}
                </p>
              </div>
            </label>
          </div>
        </div>
        <div class="buttons">
          <button class="cancel-button" @click="close()">
            {{ $t('bulk_user_upload.close') }}
          </button>
          <button
            type="submit"
            class="save-button"
            :style="{
              opacity: loading ? '0.5' : '1',
              cursor: loading ? 'not-allowed' : 'pointer',
            }"
            :disabled="loading"
            @click="selectFile === true ? bulkUsersUpload() : addFile()"
          >
            {{
              banksList?.length > 0 && selectFile === true
                ? $t('bulk_user_upload.upload')
                : $t('bulk_user_upload.next')
            }}
          </button>
        </div>
        <!-- Mobile -->
        <div
          id="searchResultDiv"
          v-if="mobile && banksList && banksList?.length > 0 && !selectFile"
        >
          <div
            v-for="(bankDetails, index) in banksList"
            :key="index"
            class="result-wrapper"
            @click="selectBank(bankDetails)"
          >
            <div class="row-wrapper">
              <div class="row">
                <div class="label">{{ $t('bulk_user_upload.branch_name') }}:</div>
                <div class="value">
                  {{ bankDetails?.name }}
                </div>
              </div>
              <div class="row">
                <div class="label">{{ $t('bulk_user_upload.branch_type') }}:</div>
                <div class="value">
                  {{ bankDetails?.type }}
                </div>
              </div>
              <div class="row">
                <div class="label">{{ $t('bulk_user_upload.country') }}:</div>
                <div class="value">
                  {{
                    bankDetails?.countryName !== null &&
                    bankDetails?.countryName !== ""
                      ? bankDetails?.countryName
                      : "-"
                  }}
                </div>
              </div>
            </div>
            <input
              type="radio"
              :checked="selectedBank?.id === bankDetails.id"
              @change="selectBank(bankDetails)"
            />
          </div>
        </div>
      </div>
      <div
        v-if="banksList === null || banksList?.length == 0"
        class="no-data-wrapper"
      >
        <p class="no-data">{{ $t('bulk_user_upload.no_data') }}</p>
      </div>
    </div>
  </div>
  <Toastify ref="toastContainer" />
</template>
<script>
import debounce from "lodash/debounce";
import Toastify from "@/components/Toastify.vue";
import { GetBanksList, BulkUserUpload } from "@/api/APIs";
import sampleXLSX from "@/assets/BulkUserSampleFile/BulkUserUpload.xlsx";

export default {
  name: "BulkUserUpload",
  components: {
    Toastify,
  },
  data() {
    return {
      isMobile: window.innerWidth < 1023,
      mobile: window.innerWidth <= 767,
      banksList: null,
      showEditBranch: false,
      selectedBank: null,
      addBranch: false,
      selectFile: false,
      loading: true,
      bankName: "",
      searchButton: false,
      file: null,
      sampleXLSX,
      debouncedSearch: debounce(this.handleKeyDown, 300),
    };
  },
  computed: {
    tabelColumns() {
      return [
        this.$t("select"),
        this.$t("entityNameFieldTitle"),
        this.$t("entityTypeFieldTitle"),
        this.$t("country"),
      ]
    },
  },
  mounted() {
    this.BanksList();
    this.selectFile = false;
    window.addEventListener("resize", this.handleResponsive);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResponsive);
  },
  methods: {
    handleKeyDown() {
      if (this.bankName.trim().length === 0) {
        this.searchBank();
      }
      if (this.bankName.trim().length >= 2) {
        this.searchBank();
      }
    },
    downloadFile() {
      const link = document.createElement("a");
      link.href = this.sampleXLSX;
      link.download = "BulkUserUpload.xlsx";
      link.click();
    },
    searchBank() {
      this.searchButton = true;
      this.BanksList();
      this.searchButton = false;
    },
    async BanksList() {
      try {
        const response = await GetBanksList(this.bankName);
        if (response) {
          this.banksList = response?.allEntitiesResults;
        } else {
          this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    handleResponsive() {
      this.isMobile = window.innerWidth < 1023;
    },
    AddNewBranch() {
      this.addBranch = true;
      this.showEditBranch = true;
    },
    close() {
      this.$emit("close");
    },
    selectBank(bankDetails) {
      this.loading = false;
      this.selectedBank = bankDetails;
    },
    addFile() {
      this.loading = true;
      this.selectFile = true;
    },
    handleDragOver(event) {
      event.preventDefault();
      event.dataTransfer.dropEffect = "copy";
    },
    handleDrop(event) {
      event.preventDefault();
      const droppedFile = event.dataTransfer.files[0];
      if (droppedFile) {
        this.file = droppedFile;
        this.loading = false;
      }
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      if (event.target.files[0]) {
        this.loading = false;
      }
    },
    async bulkUsersUpload() {
      if (!this.file) {
        this.$refs.toastContainer.addToast("Please select a file to upload.", {
          type: "error",
        });
        return;
      }

      this.loading = true;
      const formData = new FormData();
      formData.append("file", this.file);
      try {
        const response = await BulkUserUpload(
          formData,
          this.selectedBank && this.selectedBank.id
        );
        if (response.message === "FILE read succesfully") {
          this.selectedBank = null;
          this.close();
          this.$refs.toastContainer.addToast("File uploaded successfully.", {
            type: "success",
          });
        } else {
          this.$refs.toastContainer.addToast("Failed to upload file.", {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            "Failed to upload file. Please try again.",
            { type: "error" }
          );
        }
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  z-index: 9999;
}
.table-container {
  height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  border-radius: 17px;
  border: 1px solid #ecf4ff;
}
.tables-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 70%;
  padding: 40px;
  border-radius: 16px;
  background-color: white;
  gap: 2rem;
  /* align-self: stretch; */
}
.table-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1.5rem;
}
.table-title-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.table-title {
  color: #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.28px;
}
.view-all-button {
  border-radius: 13px;
  border: 2px solid #11365a;
  padding: 8px 36px;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  color: #11365a;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
#searchResultTable {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  font-family: "Montserrat-Regular", sans-serif;
}
#searchResultTable th {
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  color: #11365a;
  padding: 10px 16px;
  min-width: 30px;
}
.no-data {
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  color: #11365a;
  font-size: 16px;
  font-weight: 500;
}
#searchResultTable tr {
  color: #11365a;
}
#searchResultTable td {
  text-align: left;
  padding: 10px 16px;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: -0.16px;
}
.action-buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}
.button {
  cursor: pointer;
}
.separation-line {
  width: 100%;
  height: 2px;
  opacity: 0.2;
  background: #3e4855;
}
.no-data-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30vh;
}
.buttons {
  display: flex;
  align-items: flex-start;
  gap: 19px;
  width: 100%;
  box-shadow: none;
  padding: 0;
}
.save-button {
  display: flex;
  width: 50%;
  align-self: stretch;
  padding: 12px 26px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: var(--Dark-Blue, #11365a);
  color: #fff;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.16px;
  height: 52px;
  outline: none;
  border: none;
}
.cancel-button {
  display: flex;
  width: 50%;
  align-self: stretch;
  padding: 12px 26px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: white;
  border: 2px solid #11365a;
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.16px;
  height: 52px;
  outline: none;
  cursor: pointer;
}
.download-button {
  display: flex;
  width: auto;
  align-self: stretch;
  padding: 12px 26px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: white;
  border: 2px solid #11365a;
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.16px;
  height: 52px;
  outline: none;
  cursor: pointer;
}
.save-button:hover,
.cancel-button:hover {
  box-shadow: 0px 0px 20px 4px #cbe5ff;
}
.selected {
  background-color: #11365a;
  color: #ffffff !important;
}
.add-members-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  position: relative;
  gap: 8px;
}
.invitee-email {
  padding: 12px 24px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #11365a;
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
  outline: none;
}
.field-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}
.field-title {
  color: #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.2px;
}
.search-bank-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}
.no-results {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.file-input {
  display: none;
}
.upload-label {
  display: flex;
  align-items: center;
  align-self: stretch;
  height: 25vh;
  cursor: pointer;
}
.upload-area {
  border: 2px dashed #cbe5ff;
  padding: 40px;
  border-radius: 16px;
  color: #a0a0a0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  width: 100%;
}
.upload-area-text {
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.2px;
  color: #11365a;
}
.row-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .tables-wrapper {
    padding: 1.5rem 1rem;
    width: 85%;
  }
  .table-wrapper {
    gap: 1rem;
  }
  #searchResultDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    width: 100%;
    gap: 15px;
    height: 40vh;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .result-wrapper {
    display: flex;
    padding: 21.634px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    align-self: stretch;
    border-radius: 21.634px;
    border: 1.352px solid rgba(0, 0, 0, 0.15);
    background: #fff;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    color: #11365a;
    gap: 20px;
  }
  .label {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    color: #11365a;
    font-family: "Montserrat-Regular", sans-serif;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 37.859px; */
    letter-spacing: -0.216px;
    width: auto;
  }
  .value {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    /* align-self: stretch; */
    color: #11365a;
    font-family: "Montserrat-Regular", sans-serif;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    /* font-weight: 400; */
    letter-spacing: -0.216px;
    flex: 1;
  }
  .check-status {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 10.817px;
    border: 1.352px solid #11365a;
    color: #11365a;
    font-family: "Montserrat-Regular", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.859px;
    letter-spacing: -0.216px;
  }
  .add-members-wrapper {
    flex-direction: column;
    gap: 10px;
  }
  .invitee-email {
    padding: 10px 20px;
  }
  .invitee-email:first-of-type {
    line-height: 20.5px;
  }
  .buttons {
    order: 1;
  }
  .table-title {
    font-size: 18px;
  }
  .save-button,
  .cancel-button {
    height: 42px;
  }
  .field-wrapper {
    align-self: stretch;
  }
  .field-title {
    font-size: 16px;
    text-align: left;
  }
  .download-button {
    height: 42px;
    padding: 12px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
}
@media only screen and (min-width: 1156px) {
}
</style>
