<template>
  <div class="home-page">
    <HeroSectionProperty1Default
      class="hero-section-instance"
      :isbubbleClickedProduct="bubbleClicked"
    ></HeroSectionProperty1Default>
    <Section2FlagsProperty1Default
      class="section-2-flags-instance"
    ></Section2FlagsProperty1Default>
    <div class="frame-22">
      <div class="frame-92">
        <div class="unleashing-the-power-of-real-time-data">
          <span>
            <span class="unleashing-the-power-of-real-time-data-span">
              {{ $t('unleashing-the') }}
            </span>
            <span class="unleashing-the-power-of-real-time-data-span2">
              {{ $t('power-of-realtime') }}
            </span>
          </span>
        </div>
        <div
          class="at-credence-business-information-solutions-we-provide-fresh-and-real-time-business-intelligence-on-international-trade-counterparts-with-access-to-over-300-million-counterparts-and-1-billion-shipment-records-we-help-our-customers-make-informed-decisions"
        >
          {{ $t('power-of-realtime-description') }}
        </div>
      </div>
      <div class="frame-13">
        <div class="frame-10">
          <img
            class="image-9"
            v-lazy="this.$root.getCDNlink('assets/image 9.webp')"
            alt="Unleashing the Power of Real-Time Data"
          />
          <div class="focus-on-cross-border-trade-and-risk-assessment">
            <span>
              <span
                class="focus-on-cross-border-trade-and-risk-assessment-span"
              >
                {{ $t('focus-on') }}
              </span>
              <span
                class="focus-on-cross-border-trade-and-risk-assessment-span2"
              >
                {{ $t('cross-border-trade') }}
              </span>
              <span
                class="focus-on-cross-border-trade-and-risk-assessment-span3"
              >
                {{ $t('and-risk') }}
              </span>
            </span>
          </div>
          <div
            class="we-specialize-in-analyzing-cross-border-trade-and-transactional-risk-to-ensure-our-customers-have-the-information-they-need-to-mitigate-potential-challenges"
          >
            {{ $t('focus-on-description') }}
          </div>
        </div>
        <div class="frame-10">
          <img
            class="image-9"
            v-lazy="this.$root.getCDNlink('assets/image 10.webp')"
            alt="Focus on Cross-Border Trade and Risk Assessment"
          />
          <div class="focus-on-cross-border-trade-and-risk-assessment">
            <span>
              <span
                class="focus-on-cross-border-trade-and-risk-assessment-span2"
              >
                {{ $t('rapid-delivery') }}
              </span>
              <span
                class="focus-on-cross-border-trade-and-risk-assessment-span"
              >
                {{ $t('up-to-date') }}
              </span>
            </span>
          </div>
          <div
            class="we-specialize-in-analyzing-cross-border-trade-and-transactional-risk-to-ensure-our-customers-have-the-information-they-need-to-mitigate-potential-challenges"
          >
            {{ $t('rapid-delivery-description') }}
          </div>
        </div>
        <div class="frame-10">
          <img
            class="image-9"
            v-lazy="this.$root.getCDNlink('assets/image 11.webp')"
            alt="Rapid Delivery of Up-to-Date Fresh Data"
          />
          <div class="focus-on-cross-border-trade-and-risk-assessment">
            <span>
              <span
                class="focus-on-cross-border-trade-and-risk-assessment-span2"
              >
                {{ $t('empowering') }}
              </span>
              <span
                class="focus-on-cross-border-trade-and-risk-assessment-span"
              >
                {{ $t('financial-institutions') }}
              </span>
            </span>
          </div>
          <div
            class="we-specialize-in-analyzing-cross-border-trade-and-transactional-risk-to-ensure-our-customers-have-the-information-they-need-to-mitigate-potential-challenges"
          >
            {{ $t('empowering-description') }}
          </div>
        </div>
      </div>
    </div>
    <TabSectionProperty1Default
      class="tab-section-instance"
    ></TabSectionProperty1Default>
    <div class="frame-35574">
      <div class="our-data-coverage">{{  $t("data-coverage") }}</div>
      <div class="frame-35578">
        <div class="frame-16">
          <div class="_1-bn">{{ $t("value1") }}</div>
          <div class="shipment-records">{{ $t("data1") }}</div>
        </div>
        <div class="frame-16">
          <div class="_1-bn">{{ $t("value2") }}</div>
          <div class="shipment-records">{{ $t("data2") }}</div>
        </div>
        <div v-if="!show" class="frame-16">
          <div class="_1-bn">{{ $t("value3") }}</div>
          <div class="shipment-records">{{ $t("data3") }}</div>
        </div>
        <div v-if="!show" class="frame-16">
          <div class="_1-bn">{{ $t("value4") }}</div>
          <div class="shipment-records">{{ $t("data4") }}</div>
        </div>
      </div>
      <div v-if="show" class="frame-35578" style="top: 65%">
        <div class="frame-16">
          <div class="_1-bn">{{ $t("value3") }}</div>
          <div class="shipment-records">{{ $t("data3") }}</div>
        </div>
        <div class="frame-16">
          <div class="_1-bn">{{ $t("value4") }}</div>
          <div class="shipment-records">{{ $t("data4") }}</div>
        </div>
      </div>
      <AnimationComponent />
    </div>
    <div class="frame-23">
      <div class="frame-35611">
        <div class="frame-21">
          <div class="frame-162">
            <div class="partnerships">{{ $t("partnerships") }}</div>
            <div class="affiliations-and-membership">
              <span>
                <span class="affiliations-and-membership-span">
                  {{ $t("affiliations") }}
                </span>
                <span class="affiliations-and-membership-span2">{{ $t("and") }}</span>
                <span class="affiliations-and-membership-span3">
                  {{ $t("membership") }}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="frame-35583">
          <div class="frame-35586">
            <img
              class="image-12"
              v-lazy="this.$root.getCDNlink('assets/cyberport.webp')"
              alt="Cyberport - Hong Kong"
            />
            <div
              class="cyberport-is-hong-kong-s-digital-technology-flagship-managed-by-hong-kong-cyberport-management-company-limited-wholly-owned-by-the-hong-kong-sar-government-interlinkages-limited-is-a-part-of-cyberport-incubation-programme-run-by-cyberport"
            >
              {{ $t("cyberport") }}
            </div>
          </div>
          <div class="frame-35586">
            <img
              class="image-13"
              v-lazy="this.$root.getCDNlink('assets/hkccma.webp')"
              alt="Hong Kong Credit and Collection Management Association (HKCCMA)"
            />
            <div
              class="cyberport-is-hong-kong-s-digital-technology-flagship-managed-by-hong-kong-cyberport-management-company-limited-wholly-owned-by-the-hong-kong-sar-government-interlinkages-limited-is-a-part-of-cyberport-incubation-programme-run-by-cyberport"
            >
              {{ $t("hkccma") }}
            </div>
          </div>
        </div>
      </div>
      <div class="frame-35605">
        <div class="fresh-report-delivery-time-globally">
          <span>
            <span class="fresh-report-delivery-time-globally-span">
              {{ $t("find-your") }}
            </span>
            <span class="fresh-report-delivery-time-globally-span2">
              {{ $t("fresh-report") }}
            </span>
            <span class="fresh-report-delivery-time-globally-span">
              {{ $t("delivery-time") }}
            </span>
          </span>
        </div>
        <WorldMapProperty1MouseEnterVersion3
          property1="mouse-enter"
          version="3"
          class="world-map-instance"
        ></WorldMapProperty1MouseEnterVersion3>
      </div>
    </div>
    <div class="frame-212">
      <div class="frame-163">
        <div class="discover-real-time-business-intelligence">
          <span>
            <span class="discover-real-time-business-intelligence-span">
              {{ $t("discover") }}
            </span>
            <span class="discover-real-time-business-intelligence-span2">
              {{ $t("real-time") }}
              <br />
            </span>
            <span class="discover-real-time-business-intelligence-span3">
              {{ $t("business-intelligence") }}
            </span>
          </span>
        </div>
        <div
          class="unlock-valuable-insights-and-make-informed-decisions-with-credence-s-business-intelligence-solutions"
        >
          {{ $t("unknown") }}
        </div>
        <div class="frame-202">
          <ButtonProperty1SecondaryHoverFalse
            v-if="userToken == null"
            property1="secondary"
            class="button-instance"
            style="cursor: pointer"
            @click="redirectToLogin"
          ></ButtonProperty1SecondaryHoverFalse>
          <div
            style="
              display: flex;
              flex-direction: row;
              gap: 20px;
              margin-top: 2px;
              cursor: pointer;
            "
            @click="redirectToContactUs"
          >
            <span
              style="
                vertical-align: super;
                border-radius: 13px;
                background-color: #11365a;
                padding: 12px 20px;
                border: 2px solid white;
                color: white;
                text-align: center;
                font-family: Montserrat-Medium, sans-serif;
                font-size: 18px;
                font-weight: 600;
                position: relative;
              "
              >{{ $t("contact-us") }}</span
            >
          </div>
        </div>
      </div>
      <img
        v-if="!show"
        class="rectangle-3"
        v-lazy="this.$root.getCDNlink('assets/Discover.webp')"
        alt="Explore wide range of products for transactional and
            counterparty analysis."
      />
    </div>
    <div class="frame-24">
      <div class="products-wrapper">
        <div class="frame-164">
          <div class="discover">{{ $t("discover") }}</div>
          <div class="products">{{ $t("products") }}</div>
          <div
            class="explore-our-wide-range-of-products-for-transactional-and-counterparty-analysis"
          >
            {{ $t("explore-range") }}
          </div>
        </div>
        <div
          style="
            padding: 5px 10px;
            border-radius: 13px;
            border: 2px solid #142d4d;
            color: #142d4d;
            font-weight: 600;
            cursor: pointer;
          "
          @click="redirectToProduct"
        >
          {{ $t("view-all") }}
        </div>
      </div>
      <div class="frame-26">
        <div class="frame-2212" style="min-height: 542.28px; background: white">
          <div class="frame-25">
            <div class="frame-35588">
              <div class="frame-35617">
                <div class="report-as-a-service">
                  {{ $t("card1") }}
                </div>
              </div>
              <div class="line-22"></div>
              <div class="comprehensive-business-intelligence-reports height">
                {{ $t("card-title1") }}
              </div>
            </div>
            <div class="line-22"></div>
            <div class="frame-35595">
              <div class="frame-35590">
                <div class="cross-border-iq-score-summary">
                  {{ $t("desc1-1") }}
                </div>
              </div>
              <div class="frame-35590">
                <div class="cross-border-iq-score-summary">
                  {{ $t("desc1-2") }}
                </div>
              </div>
              <div class="frame-35590">
                <div class="cross-border-iq-score-summary">
                  {{ $t("desc1-3") }}
                </div>
              </div>
              <div class="frame-35590">
                <div class="cross-border-iq-score-summary">
                  {{ $t("desc1-4") }}
                </div>
              </div>
              <div class="frame-35590">
                <div class="cross-border-iq-score-summary">
                  {{ $t("desc1-5") }}
                </div>
              </div>
            </div>
            <div class="line-22"></div>
          </div>
          <div class="subscription-purchase" @click="handleScrollToTop">
            {{ $t("cta1") }}
          </div>
        </div>
        <div class="frame-2212" style="min-height: 542.28px">
          <div class="recomemded-label">{{ $t("recommended") }}</div>
          <div class="frame-25">
            <div class="frame-35588">
              <div class="frame-35617">
                <div class="report-as-a-service">
                  {{ $t("card2") }}
                </div>
              </div>
              <div class="line-22"></div>
              <div class="comprehensive-business-intelligence-reports height">
                {{ $t("card-title2") }}
              </div>
            </div>
            <div class="line-22"></div>
            <div class="frame-35595">
              <div class="frame-35590">
                <div class="cross-border-iq-score-summary">
                  {{ $t("desc2-1") }}
                </div>
              </div>
              <div class="frame-35590">
                <div class="cross-border-iq-score-summary">
                  {{ $t("desc2-2") }}
                </div>
              </div>
              <div class="frame-35590">
                <div class="cross-border-iq-score-summary">{{ $t("desc2-3") }}</div>
              </div>
              <div class="frame-35590">
                <div class="cross-border-iq-score-summary">
                  {{ $t("desc2-4") }}
                </div>
              </div>
              <div class="frame-35590">
                <div class="cross-border-iq-score-summary">
                  {{ $t("desc2-5") }}
                </div>
              </div>
            </div>
            <div class="line-22"></div>
          </div>
          <div class="subscription-purchase" @click="handleScrollToTop">
            {{ $t("cta2") }}
          </div>
        </div>
        <div class="frame-2212" style="min-height: 542.28px; background: white">
          <div class="frame-25">
            <div class="frame-35588">
              <div class="frame-35617">
                <div class="report-as-a-service">{{ $t("card3") }}</div>
              </div>
              <div class="line-22"></div>
              <div class="comprehensive-business-intelligence-reports height">
                {{ $t("card-title3") }}
              </div>
            </div>
            <div class="line-22"></div>
            <div class="frame-35595">
              <div class="frame-35590">
                <div class="cross-border-iq-score-summary">
                  {{ $t("desc3-1") }}
                </div>
              </div>
              <div class="frame-35590">
                <div class="cross-border-iq-score-summary">
                  {{ $t("desc3-2") }}
                </div>
              </div>
              <div class="frame-35590">
                <div class="cross-border-iq-score-summary">
                  {{ $t("desc3-3") }}
                </div>
              </div>
              <div class="frame-35590">
                <div class="cross-border-iq-score-summary">
                  {{ $t("desc3-4") }}
                </div>
              </div>
              <div class="frame-35590">
                <div class="cross-border-iq-score-summary">
                  {{ $t("desc3-5") }}
                </div>
              </div>
              <div class="frame-35590">
                <div class="cross-border-iq-score-summary">
                  {{ $t("desc3-6") }}
                </div>
              </div>
            </div>
            <div class="line-22"></div>
          </div>
          <div class="subscription-purchase">{{ $t("cta3") }}</div>
        </div>
      </div>
    </div>
    <div class="frame-266">
      <div class="testimonials-title">
        <div class="frame-165">
          <div class="discover">{{ $t("discover") }}</div>
          <div class="testimonials">{{ $t("testimonials") }}</div>
          <div
            class="explore-our-wide-range-of-products-for-transactional-and-counterparty-analysis2"
          >
            {{ $t("explore-range") }}
          </div>
        </div>
        <div v-if="!show" class="frame-265">
          <div
            class="vuesax-broken-arrow-left"
            style="cursor: pointer"
            @click="scrollLeft"
          >
            <svg
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M41.334 32H25.334"
                stroke="#11365A"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M30.666 24L22.666 32L30.666 40"
                stroke="#11365A"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.6673 16.0002C7.33398 20.4535 5.33398 26.0002 5.33398 32.0002C5.33398 46.7202 17.2807 58.6668 32.0007 58.6668C46.7207 58.6668 58.6673 46.7202 58.6673 32.0002C58.6673 17.2802 46.7207 5.3335 32.0007 5.3335C28.1873 5.3335 24.534 6.1335 21.254 7.60016"
                stroke="#11365A"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div
            class="vuesax-broken-arrow-right"
            style="cursor: pointer"
            @click="scrollRight"
          >
            <svg
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.666 32H38.666"
                stroke="#11365A"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M33.334 40L41.334 32L33.334 24"
                stroke="#11365A"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.6673 16.0002C7.33398 20.4535 5.33398 26.0002 5.33398 32.0002C5.33398 46.7202 17.2807 58.6668 32.0007 58.6668C46.7207 58.6668 58.6673 46.7202 58.6673 32.0002C58.6673 17.2802 46.7207 5.3335 32.0007 5.3335C28.1873 5.3335 24.534 6.1335 21.254 7.60016"
                stroke="#11365A"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="frame-264" ref="reviewsContainer">
        <div
          class="frame-262"
          @mouseover="changeClass(testimonial1, true)"
          @mouseleave="changeClass(testimonial1, false)"
          :class="{ 'frame-261': testimonial1.hovered }"
        >
          <div class="frame-260">
            <StarPurple500 class="star-purple-500-instance"></StarPurple500>
            <StarPurple500 class="star-purple-500-instance2"></StarPurple500>
            <StarPurple500 class="star-purple-500-instance3"></StarPurple500>
            <StarPurple500 class="star-purple-500-instance3"></StarPurple500>
            <StarPurple500 class="star-purple-500-instance9"></StarPurple500>
            <!-- <StarOutline class="star-outline-instance"></StarOutline> -->
          </div>
          <div class="amet">
            {{ $t("review1") }}
          </div>
          <div class="frame-263">
            <div class="frame-29">
              <!-- <img class="avatar" src="@/assets/Content1.jpeg" /> -->
              <div class="frame-27">
                <!-- <div class="lina-hicks">Mr. Mohammad Saifuddin Khan</div> -->
                <div class="lina-hicks">{{ $t("representative") }}</div>
                <div class="verified-writer">
                  {{ $t("bank1") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="frame-262"
          @mouseover="changeClass(testimonial2, true)"
          @mouseleave="changeClass(testimonial2, false)"
          :class="{ 'frame-261': testimonial2.hovered }"
        >
          <div class="frame-260">
            <StarPurple500 class="star-purple-500-instance4"></StarPurple500>
            <StarPurple500 class="star-purple-500-instance5"></StarPurple500>
            <StarPurple500 class="star-purple-500-instance6"></StarPurple500>
            <StarPurple500 class="star-purple-500-instance3"></StarPurple500>
            <StarPurple500 class="star-purple-500-instance9"></StarPurple500>
            <!-- <StarOutline class="star-outline-instance2"></StarOutline> -->
          </div>
          <div class="amet">
            {{ $t("review2") }}
          </div>
          <div class="frame-263">
            <div class="frame-29">
              <!-- <img class="avatar" src="@/assets/Content2.jpeg" /> -->
              <div class="frame-27">
                <!-- <div class="lina-hicks">Md. Abdul Monem</div> -->
                <div class="lina-hicks">{{ $t("representative") }}</div>
                <div class="verified-writer">
                  {{ $t("bank2") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="frame-262"
          @mouseover="changeClass(testimonial3, true)"
          @mouseleave="changeClass(testimonial3, false)"
          :class="{ 'frame-261': testimonial3.hovered }"
        >
          <div class="frame-260">
            <StarPurple500 class="star-purple-500-instance7"></StarPurple500>
            <StarPurple500 class="star-purple-500-instance8"></StarPurple500>
            <StarPurple500 class="star-purple-500-instance9"></StarPurple500>
            <StarPurple500 class="star-purple-500-instance9"></StarPurple500>
            <StarPurple500 class="star-purple-500-instance9"></StarPurple500>
            <!-- <StarOutline class="star-outline-instance3"></StarOutline> -->
          </div>
          <div class="amet">
            {{$t('invaluable-insights')}}
          </div>
          <div class="frame-263">
            <div class="frame-29">
              <!-- <img class="avatar3" style="border-radius: 50%; overflow: hidden;" src="@/assets/CorporateTestimonial.png" /> -->
              <div class="frame-27">
                <!-- <div class="lina-hicks">Tanvir Aslam</div> -->
                <div class="lina-hicks">{{$t('representative')}}</div>
                <div class="verified-writer">
                  {{$t("ceo-brockport")}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          class="frame-262"
          @mouseover="changeClass(testimonial4, true)"
          @mouseleave="changeClass(testimonial4, false)"
          :class="{ 'frame-261': testimonial4.hovered }"
        >
          <div class="frame-260">
            <StarPurple500 class="star-purple-500-instance10"></StarPurple500>
            <StarPurple500 class="star-purple-500-instance11"></StarPurple500>
            <StarPurple500 class="star-purple-500-instance12"></StarPurple500>
            <StarHalf class="star-half-instance4"></StarHalf>
            <StarOutline class="star-outline-instance4"></StarOutline>
          </div>
          <div class="amet">
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint. Velit officia consequat duis enim velit mollit.
            <br />
            Exercitation veniam consequat sunt nostrud amet. Amet minim mollit
            non deserunt ullamco est sit aliqua dolor do amet sint. Velit
            officia consequat duis enim velit mollit. Exercitation veniam
            consequat sunt nostrud amet.
          </div>
          <div class="frame-263">
            <div class="frame-29">
              <img class="avatar4" src="@/assets/Avatar.png" />
              <div class="frame-27">
                <div class="lina-hicks">Lina Hicks</div>
                <div class="verified-writer">Company ABC</div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <BlogsCarousel />
    <div class="frame-35554">
      <div class="frame-166">
        <div class="fa-qs">{{ $t("faq")}}</div>
        <div
          class="find-answers-to-common-questions-about-our-services-data-reliability-and-getting-started-with-credence"
        >
          {{ $t("find-anwers") }}
        </div>
      </div>
      <div class="frame-35551">
        <div class="frame-35552" @click="toggleDescription3">
          <div class="frame-35550">
            <div
              class="how-does-credence-differentiate-from-traditional-providers"
            >
              {{ $t("faq1") }}
            </div>
            <div v-if="showDescription3" class="faq-description">
              {{ $t("ans1") }}
            </div>
          </div>
          <VuesaxBrokenArrowUp
            :class="{
              'vuesax-broken-arrow-up-instance': showDescription3,
              'vuesax-broken-arrow-up-instance2': !showDescription3,
            }"
          ></VuesaxBrokenArrowUp>
        </div>
        <div class="frame-35552" @click="toggleDescription">
          <div class="frame-35550">
            <div
              class="how-does-credence-differentiate-from-traditional-providers"
            >
              {{ $t("faq2") }}
            </div>
            <div v-if="showDescription" class="faq-description">
              {{ $t("ans2") }}
            </div>
          </div>
          <VuesaxBrokenArrowUp
            :class="{
              'vuesax-broken-arrow-up-instance': showDescription,
              'vuesax-broken-arrow-up-instance2': !showDescription,
            }"
          ></VuesaxBrokenArrowUp>
        </div>
        <div class="frame-35552" @click="toggleDescription1">
          <div class="frame-35550">
            <div
              class="how-does-credence-differentiate-from-traditional-providers"
            >
              {{ $t("faq3") }}
            </div>
            <div v-if="showDescription1" class="faq-description">
              {{ $t("ans3") }}
            </div>
          </div>
          <VuesaxBrokenArrowUp
            :class="{
              'vuesax-broken-arrow-up-instance': showDescription1,
              'vuesax-broken-arrow-up-instance2': !showDescription1,
            }"
          ></VuesaxBrokenArrowUp>
        </div>
        <div class="frame-35552" @click="toggleDescription2">
          <div class="frame-35550">
            <div
              class="how-does-credence-differentiate-from-traditional-providers"
            >
              {{ $t("faq4") }}
            </div>
            <div v-if="showDescription2" class="faq-description">
              {{ $t("ans4") }}
            </div>
          </div>
          <VuesaxBrokenArrowUp
            :class="{
              'vuesax-broken-arrow-up-instance': showDescription2,
              'vuesax-broken-arrow-up-instance2': !showDescription2,
            }"
          ></VuesaxBrokenArrowUp>
        </div>
        <div class="frame-35552" @click="toggleDescription4">
          <div class="frame-35550">
            <div
              class="how-does-credence-differentiate-from-traditional-providers"
            >
              {{ $t("faq5") }}
            </div>
            <div v-if="showDescription4" class="faq-description">
              {{ $t("ans5") }}
            </div>
          </div>
          <VuesaxBrokenArrowUp
            :class="{
              'vuesax-broken-arrow-up-instance': showDescription4,
              'vuesax-broken-arrow-up-instance2': !showDescription4,
            }"
          ></VuesaxBrokenArrowUp>
        </div>
      </div>
    </div>
    <Footer class="footer-instance"></Footer>
    <div v-show="showScrollToTop" class="scroll-to-top" @click="scrollToTop">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.0306 9.53073C16.9609 9.60046 16.8782 9.65578 16.7871 9.69352C16.6961 9.73127 16.5985 9.75069 16.4999 9.75069C16.4014 9.75069 16.3038 9.73127 16.2127 9.69352C16.1217 9.65578 16.039 9.60046 15.9693 9.53073L8.99993 2.56041L2.03055 9.53073C1.88982 9.67146 1.69895 9.75052 1.49993 9.75052C1.30091 9.75052 1.11003 9.67146 0.969304 9.53073C0.828573 9.39 0.749512 9.19912 0.749512 9.0001C0.749512 8.80108 0.828573 8.61021 0.969304 8.46948L8.4693 0.969476C8.53896 0.899744 8.62168 0.844425 8.71272 0.806682C8.80377 0.768939 8.90137 0.749512 8.99993 0.749512C9.09849 0.749512 9.19608 0.768939 9.28713 0.806682C9.37818 0.844425 9.4609 0.899744 9.53055 0.969476L17.0306 8.46948C17.1003 8.53913 17.1556 8.62185 17.1933 8.7129C17.2311 8.80395 17.2505 8.90154 17.2505 9.0001C17.2505 9.09866 17.2311 9.19626 17.1933 9.28731C17.1556 9.37836 17.1003 9.46107 17.0306 9.53073Z"
          fill="white"
        />
      </svg>
    </div>
  </div>
  <!-- <LoginModal v-if="showLoginPopup" :type="loginType" /> -->
</template>
<script>
import { defineComponent } from "vue";
import ButtonProperty1SecondaryHoverFalse from "../ButtonProperty1SecondaryHoverFalse/ButtonProperty1SecondaryHoverFalse.vue";
import HeroSectionProperty1Default from "../HeroSectionProperty1Default/HeroSectionProperty1Default.vue";
import Section2FlagsProperty1Default from "../Section2FlagsProperty1Default/Section2FlagsProperty1Default.vue";
import TabSectionProperty1Default from "../TabSectionProperty1Default/TabSectionProperty1Default.vue";
import WorldMapProperty1MouseEnterVersion3 from "../WorldMapProperty1MouseEnterVersion3/WorldMapProperty1MouseEnterVersion3.vue";
import StarPurple500 from "../StarPurple500/StarPurple500.vue";
import AnimationComponent from "../../../assets/Animation/AnimationComponent.vue";
import VuesaxBrokenArrowUp from "../VuesaxBrokenArrowUp/VuesaxBrokenArrowUp.vue";
import Footer from "../Footer/Footer.vue";
import VueCookies from "vue-cookies";
import { useHead } from "@unhead/vue";
import BlogsCarousel from "../BlogsCarousel/BlogsCarousel.vue";

export default defineComponent({
  name: "HomePage",
  setup() {
    useHead({
      title: "Comprehensive Company Due Diligence Reports - Credencedata",
      meta: [
        {
          name: "description",
          content:
            "Credencedata provides comprehensive company due diligence reports to help you make informed business decisions.",
        },
        {
          name: "keywords",
          content:
            "company due diligence, business reports, Credencedata, company reports, business analysis, company analysis, business intelligence, risk assessment",
        },
        {
          property: "og:title",
          content: "Credencedata - Comprehensive Company Due Diligence Reports",
        },
        {
          property: "og:description",
          content:
            "Access detailed company due diligence reports from Credencedata to make well-informed business decisions. Our comprehensive analysis ensures reliability and accuracy.",
        },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://www.credencedata.com" },
        {
          property: "og:image",
          content: "https://www.credencedata.com/img/logo.1e1e1666.webp",
        },
        { property: "og:image:width", content: "1200" },
        { property: "og:image:height", content: "630" },
        {
          name: "twitter:card",
          content: "https://www.credencedata.com/img/logo.1e1e1666.webp",
        },
        {
          name: "twitter:title",
          content: "Credencedata - Comprehensive Company Due Diligence Reports",
        },
        {
          name: "twitter:description",
          content:
            "Credencedata provides comprehensive company due diligence reports to help you make informed business decisions.",
        },
        {
          name: "twitter:image",
          content: "https://www.credencedata.com/img/logo.1e1e1666.webp",
        },
      ],
    });
  },
  components: {
    ButtonProperty1SecondaryHoverFalse,
    HeroSectionProperty1Default,
    Section2FlagsProperty1Default,
    TabSectionProperty1Default,
    WorldMapProperty1MouseEnterVersion3,
    StarPurple500,
    AnimationComponent,
    VuesaxBrokenArrowUp,
    Footer,
    BlogsCarousel,
  },
  props: {},
  data() {
    return {
      showScrollToTop: false,
      scrollPosition: 0,
      scrollStep: 300,
      showDescription: false,
      showDescription1: false,
      showDescription2: false,
      showDescription3: false,
      showDescription4: false,
      showLoginPopup: false,
      userToken: null,
      loginType: "",
      show: window.innerWidth < 1023,
      testimonial1: { hovered: false },
      testimonial2: { hovered: false },
      testimonial3: { hovered: false },
      testimonial4: { hovered: false },
      bubbleClicked: false,
    };
  },
  computed: {
    userTokenAvailable() {
      return this.userToken !== null;
    },
  },
  methods: {
    handleScroll() {
      this.showScrollToTop = window.scrollY > 200;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    scrollLeft() {
      this.$refs.reviewsContainer.scrollBy({
        left: -300,
        behavior: "smooth",
      });
    },
    scrollRight() {
      this.$refs.reviewsContainer.scrollBy({
        left: 300,
        behavior: "smooth",
      });
    },
    handleScrollToTop() {
      this.$router.push({ path: "/search" });
    },
    redirectToProduct() {
      this.$router.push("/product");
    },
    redirectToLogin() {
      this.$router.push({ path: "/login", query: { type: "SignUp" } });
    },
    redirectToContactUs() {
      this.$router.push("/contact");
    },
    changeClass(testimonial, isHovered) {
      testimonial.hovered = isHovered;
    },
    handleResize() {
      this.show = window.innerWidth < 1023;
    },
    toggleDescription() {
      this.showDescription = !this.showDescription;
      this.resetOtherDescriptions("showDescription");
    },
    toggleDescription1() {
      this.showDescription1 = !this.showDescription1;
      this.resetOtherDescriptions("showDescription1");
    },
    toggleDescription2() {
      this.showDescription2 = !this.showDescription2;
      this.resetOtherDescriptions("showDescription2");
    },
    toggleDescription3() {
      this.showDescription3 = !this.showDescription3;
      this.resetOtherDescriptions("showDescription3");
    },
    toggleDescription4() {
      this.showDescription4 = !this.showDescription4;
      this.resetOtherDescriptions("showDescription4");
    },
    resetOtherDescriptions(current) {
      const keys = [
        "showDescription",
        "showDescription1",
        "showDescription2",
        "showDescription3",
        "showDescription4",
      ];
      keys.forEach((key) => {
        if (key !== current) this[key] = false;
      });
    },
  },
  mounted() {
    this.userToken = VueCookies.get("token");
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
});
</script>
<style scoped>
.home-page,
.home-page * {
  box-sizing: border-box;
}
.home-page {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
.hero-section-instance {
  flex-shrink: 0 !important;
  width: 1440px !important;
}
.section-2-flags-instance {
  flex-shrink: 0 !important;
  width: 100% !important;
}
.frame-22 {
  flex-shrink: 0;
  width: 85%;
  height: 774px;
  position: relative;
}
.frame-92 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  top: 80px;
}
.innovative {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}
.unleashing-the-power-of-real-time-data {
  text-align: center;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 40px;
  font-weight: 700;
  position: relative;
}
.unleashing-the-power-of-real-time-data-span {
  color: #11365a;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 40px;
  font-weight: 700;
}
.unleashing-the-power-of-real-time-data-span2 {
  color: #e4004c;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 40px;
  font-weight: 700;
}
.at-credence-business-information-solutions-we-provide-fresh-and-real-time-business-intelligence-on-international-trade-counterparts-with-access-to-over-300-million-counterparts-and-1-billion-shipment-records-we-help-our-customers-make-informed-decisions {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
  width: 922.83px;
}
.frame-13 {
  /* padding: 0px 160px 0px 160px; */
  display: flex;
  flex-direction: row;
  gap: 48px;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  position: relative;
  /* left: calc(50% - 721px); */
  top: 321px;
}
.frame-10 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
}
.image-9 {
  flex-shrink: 0;
  width: 64px;
  height: 64px;
  position: relative;
  object-fit: cover;
}
.focus-on-cross-border-trade-and-risk-assessment {
  text-align: center;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  position: relative;
  align-self: stretch;
  flex-shrink: 0;
  min-height: 110px;
}
.focus-on-cross-border-trade-and-risk-assessment-span {
  color: #11365a;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
}
.focus-on-cross-border-trade-and-risk-assessment-span2 {
  color: #e4004c;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
}
.focus-on-cross-border-trade-and-risk-assessment-span3 {
  color: #11365a;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
}
.we-specialize-in-analyzing-cross-border-trade-and-transactional-risk-to-ensure-our-customers-have-the-information-they-need-to-mitigate-potential-challenges {
  color: #3e4855;
  text-align: justify;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
  align-self: auto;
}
.tab-section-instance {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.frame-35574 {
  background: linear-gradient(to left, #11365a, #11365a);
  padding: 50px 0;
  flex-shrink: 0;
  width: 100%;
  height: 277px;
  position: relative;
  overflow: hidden;
}
.our-data-coverage {
  color: #ffffff;
  text-align: center;
  font-family: "Montserrat-ExtraBold", sans-serif;
  font-size: 40px;
  font-weight: 800;
  position: absolute;
  width: 100%;
  text-align: center;
  top: 13%;
}
.frame-35578 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  padding: 0 7.55%;
  top: 52%;
}
.frame-16 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
._1-bn {
  color: #26bbc2;
  text-align: center;
  font-family: "Montserrat-Black", sans-serif;
  font-size: 36px;
  font-weight: 900;
  position: relative;
}
.shipment-records {
  color: #ffffff;
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 20px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
}
.frame-23 {
  flex-shrink: 0;
  width: 100%;
  gap: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.frame-35605 {
  background: #ffffff;
  border-radius: 24px;
  padding: 24px 0px 24px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  margin-bottom: 40px;
  position: relative;
  /* top: 536px; */
  box-shadow: 0px 28px 36px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.fresh-report-delivery-time-globally {
  text-align: center;
  font-family: "Montserrat-ExtraBold", sans-serif;
  font-size: 40px;
  font-weight: 800;
  position: relative;
}
.fresh-report-delivery-time-globally-span {
  color: #26bbc2;
  font-family: "Montserrat-ExtraBold", sans-serif;
  font-size: 40px;
  font-weight: 800;
}
.fresh-report-delivery-time-globally-span2 {
  color: #11365a;
  font-family: "Montserrat-ExtraBold", sans-serif;
  font-size: 40px;
  font-weight: 800;
}
.world-map-instance {
  flex-shrink: 0;
  width: 100%;
  top: 0;
}
.frame-35611 {
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: center;
  justify-content: center;
  position: relative;
  /* left: 91px; */
  width: 100%;
  margin-top: 58px;
}
.frame-21 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;
  position: relative;
}
.frame-162 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.partnerships {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}
.affiliations-and-membership {
  text-align: center;
  font-family: "Montserrat-ExtraBold", sans-serif;
  font-size: 40px;
  font-weight: 800;
  position: relative;
}
.affiliations-and-membership-span {
  color: #26bbc2;
  font-family: "Montserrat-ExtraBold", sans-serif;
  font-size: 40px;
  font-weight: 800;
}
.affiliations-and-membership-span2 {
  color: #11365a;
  font-family: "Montserrat-ExtraBold", sans-serif;
  font-size: 40px;
  font-weight: 800;
}
.affiliations-and-membership-span3 {
  color: #26bbc2;
  font-family: "Montserrat-ExtraBold", sans-serif;
  font-size: 40px;
  font-weight: 800;
}
.frame-35583 {
  display: flex;
  flex-direction: row;
  gap: 160px;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  flex-shrink: 0;
  position: relative;
}
.frame-35586 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  width: 100%;
  position: relative;
}
.image-12 {
  width: 40%;
  /* height: 100px; */
  /* position: absolute; */
  /* left: calc(50% - 121.99px); */
  /* top: 0px; */
  object-fit: cover;
}
.cyberport-is-hong-kong-s-digital-technology-flagship-managed-by-hong-kong-cyberport-management-company-limited-wholly-owned-by-the-hong-kong-sar-government-interlinkages-limited-is-a-part-of-cyberport-incubation-programme-run-by-cyberport {
  color: #3e4855;
  text-align: justify;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
  align-self: stretch;
}
.image-13 {
  width: 40%;
  /* height: 100px;
  position: absolute;
  left: calc(50% - 196.08px);
  top: 0px; */
  object-fit: cover;
}
.frame-212 {
  background: #11365a;
  padding: 60px 7.5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  position: relative;
}
.frame-163 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  width: 45%;
}
.discover-real-time-business-intelligence {
  text-align: left;
  font-family: "Montserrat-ExtraBold", sans-serif;
  font-size: 40px;
  font-weight: 800;
  position: relative;
}
.discover-real-time-business-intelligence-span {
  color: #ffffff;
  font-family: "Montserrat-ExtraBold", sans-serif;
  font-size: 40px;
  font-weight: 800;
}
.discover-real-time-business-intelligence-span2 {
  color: #26bbc2;
  font-family: "Montserrat-ExtraBold", sans-serif;
  font-size: 40px;
  font-weight: 800;
}
.discover-real-time-business-intelligence-span3 {
  color: #ffffff;
  font-family: "Montserrat-ExtraBold", sans-serif;
  font-size: 40px;
  font-weight: 800;
}
.unlock-valuable-insights-and-make-informed-decisions-with-credence-s-business-intelligence-solutions {
  color: #ffffff;
  text-align: left;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
  width: 60%;
}
.frame-202 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.rectangle-3 {
  border-radius: 16px;
  flex-shrink: 0;
  width: 536px;
  height: 378px;
  position: relative;
  object-fit: cover;
}
.frame-24 {
  flex-shrink: 0;
  width: 85%;
  /* height: 1318px; */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
}
.frame-164 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  /* left: 80px; */
  /* top: 60px; */
}
.discover {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}
.products {
  color: #26bbc2;
  text-align: left;
  font-family: "Montserrat-ExtraBold", sans-serif;
  font-size: 40px;
  font-weight: 800;
  position: relative;
}
.explore-our-wide-range-of-products-for-transactional-and-counterparty-analysis {
  color: #3e4855;
  text-align: left;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
}
.height {
  min-height: 60px;
}
.frame-25 {
  padding: 0px 16px 0px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35588 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.line-2 {
  align-self: stretch;
  flex-shrink: 0;
  height: 0px;
  position: relative;
  overflow: visible;
}
.report-as-a-service {
  color: #000000;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  line-height: 180%;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  align-self: stretch;
  flex-shrink: 0;
}
.line-23 {
  align-self: stretch;
  flex-shrink: 0;
  height: 0px;
  position: relative;
  overflow: visible;
}
.comprehensive-business-intelligence-reports {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 26px;
  font-weight: 700;
  position: relative;
  align-self: stretch;
}
.frame-35620 {
  border-radius: 12px;
  border-style: solid;
  border-color: #11365a;
  border-width: 1px;
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
}
.fresh-data-everytime {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  line-height: 180%;
  font-weight: 600;
  position: relative;
  flex: 1;
}
.frame-35621 {
  border-radius: 12px;
  border-style: solid;
  border-color: #11365a;
  border-width: 1px;
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
}
.frame-356172 {
  border-radius: 12px;
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 5%;
  bottom: -12%;
}
.vector-8 {
  flex-shrink: 0;
  width: 56.83px;
  height: 37.15px;
  position: relative;
  transform: translate(-4.56px, 0px);
  overflow: visible;
}
.we-recommend-fresh-data-everytime {
  color: #000000;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  line-height: 180%;
  font-weight: 600;
  position: relative;
}
.we-recommend-fresh-data-everytime-span {
  color: #000000;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  line-height: 180%;
  font-weight: 600;
}
.we-recommend-fresh-data-everytime-span2 {
  color: #000000;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  line-height: 180%;
  font-weight: 600;
  text-transform: uppercase;
}
.vector-9 {
  flex-shrink: 0;
  width: 56.83px;
  height: 37.15px;
  position: relative;
  transform: translate(5px, 0px);
  overflow: visible;
}
.frame-266 {
  /* flex-shrink: 0; */
  width: 100%;
  margin-top: 3rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.frame-264 {
  /* padding: 0 7.5%; */
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  width: 85%;
  /* position: absolute; */
  /* left: 0px; */
  /* top: 263px; */
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.frame-261 {
  background: linear-gradient(
      133deg,
      rgba(38, 187, 194, 0.2) 27.88%,
      rgb(24 207 215 / 0%) 79.91%
    ),
    #3673ae14;
  border-radius: 16px;
}
.frame-262 {
  border-radius: 16px;
  padding: 30px 30px 30px 60px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: center;
  /* flex-shrink: 0;
  width: 580px; */
  position: relative;
  cursor: pointer;
}
.frame-260 {
  display: flex;
  flex-direction: row;
  gap: 5.35px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.star-purple-500-instance {
  flex-shrink: 0 !important;
  width: 32.08px !important;
  height: 32.08px !important;
}
.star-purple-500-instance2 {
  flex-shrink: 0 !important;
  width: 32.08px !important;
  height: 32.08px !important;
}
.star-purple-500-instance3 {
  flex-shrink: 0 !important;
  width: 32.08px !important;
  height: 32.08px !important;
}
.star-half-instance {
  flex-shrink: 0 !important;
  width: 32.08px !important;
  height: 32.08px !important;
}
.star-outline-instance {
  flex-shrink: 0 !important;
  width: 32.08px !important;
  height: 32.08px !important;
}
.amet {
  color: #3e4855;
  text-align: left;
  font-family: "Montserrat-Medium", sans-serif;
  min-height: 12rem;
  font-size: 18px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
  text-align: justify;
  align-self: stretch;
  text-overflow: ellipsis;
  overflow: hidden;
}
.frame-263 {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 478px;
  position: relative;
}
.frame-29 {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 160px;
  position: relative;
}
.avatar {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  position: relative;
  /* overflow: visible; */
  object-fit: cover;
  border: 0.5px solid #dfdfdf;
}
.frame-27 {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.lina-hicks {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 18px;
  font-weight: 700;
  position: relative;
}
.verified-writer {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-LightItalic", sans-serif;
  font-size: 14px;
  line-height: 180%;
  font-weight: 300;
  font-style: italic;
  position: relative;
}
.star-purple-500-instance4 {
  flex-shrink: 0 !important;
  width: 32.08px !important;
  height: 32.08px !important;
}
.star-purple-500-instance5 {
  flex-shrink: 0 !important;
  width: 32.08px !important;
  height: 32.08px !important;
}
.star-purple-500-instance6 {
  flex-shrink: 0 !important;
  width: 32.08px !important;
  height: 32.08px !important;
}
.star-half-instance2 {
  flex-shrink: 0 !important;
  width: 32.08px !important;
  height: 32.08px !important;
}
.star-outline-instance2 {
  flex-shrink: 0 !important;
  width: 32.08px !important;
  height: 32.08px !important;
}
.star-purple-500-instance7 {
  flex-shrink: 0 !important;
  width: 32.08px !important;
  height: 32.08px !important;
}
.star-purple-500-instance8 {
  flex-shrink: 0 !important;
  width: 32.08px !important;
  height: 32.08px !important;
}
.star-purple-500-instance9 {
  flex-shrink: 0 !important;
  width: 32.08px !important;
  height: 32.08px !important;
}
.star-half-instance3 {
  flex-shrink: 0 !important;
  width: 32.08px !important;
  height: 32.08px !important;
}
.star-outline-instance3 {
  flex-shrink: 0 !important;
  width: 32.08px !important;
  height: 32.08px !important;
}
.avatar3 {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  position: relative;
  overflow: visible;
  object-fit: cover;
}
.star-purple-500-instance10 {
  flex-shrink: 0 !important;
  width: 32.08px !important;
  height: 32.08px !important;
}
.star-purple-500-instance11 {
  flex-shrink: 0 !important;
  width: 32.08px !important;
  height: 32.08px !important;
}
.star-purple-500-instance12 {
  flex-shrink: 0 !important;
  width: 32.08px !important;
  height: 32.08px !important;
}
.star-half-instance4 {
  flex-shrink: 0 !important;
  width: 32.08px !important;
  height: 32.08px !important;
}
.star-outline-instance4 {
  flex-shrink: 0 !important;
  width: 32.08px !important;
  height: 32.08px !important;
}
.avatar4 {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  position: relative;
  overflow: visible;
  object-fit: cover;
}
.frame-165 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  /* left: 79px; */
  /* top: 80px; */
}
.testimonials {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-ExtraBold", sans-serif;
  font-size: 40px;
  font-weight: 800;
  position: relative;
}
.explore-our-wide-range-of-products-for-transactional-and-counterparty-analysis2 {
  color: #3e4855;
  text-align: left;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
  width: 600px;
}
.frame-265 {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  /* left: 1211px; */
  /* top: 159px; */
  bottom: 0;
}
.testimonials-title {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 5% 7.5% 0 7.5%;
  width: 85%;
}
.vuesax-broken-arrow-right-instance {
  flex-shrink: 0 !important;
  width: 64px !important;
  height: 64px !important;
}
.frame-33 {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 85%;
  gap: 5rem;
  position: relative;
}
.frame-32 {
  display: flex;
  flex-direction: column;
  gap: 9px;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  margin-top: 7rem;
}
.latest {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  align-self: stretch;
}
.recent-articles {
  text-align: center;
  font-family: "Montserrat-ExtraBold", sans-serif;
  font-size: 40px;
  font-weight: 800;
  position: relative;
  align-self: stretch;
}
.recent-articles-span {
  color: #11365a;
  font-family: "Montserrat-ExtraBold", sans-serif;
  font-size: 40px;
  font-weight: 800;
}
.recent-articles-span2 {
  color: #26bbc2;
  font-family: "Montserrat-ExtraBold", sans-serif;
  font-size: 40px;
  font-weight: 800;
}
.here-s-what-we-ve-be {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
  align-self: stretch;
}
.frame-31 {
  display: flex;
  flex-direction: row;
  /* gap: 34px; */
  align-items: center;
  justify-content: space-between;
  position: relative;
  /* left: 79px; */
  /* top: 140px; */
  width: 100%;
}
.item {
  flex-shrink: 0;
  width: 30%;
  border-radius: 16px;
  height: 500px;
  position: relative;
  box-shadow: 0px 20px 50px 0px rgba(15, 24, 44, 0.15);
  overflow: hidden;
}
.image {
  position: absolute;
  inset: 0;
}
.base {
  border-radius: 16px;
  position: absolute;
  right: 0.05%;
  left: 0%;
  width: 99.95%;
  bottom: 0%;
  top: 0%;
  height: 100%;
  object-fit: cover;
}
.user {
  position: absolute;
  right: 52.25%;
  left: 7.42%;
  width: 40.33%;
  bottom: 8%;
  top: 83%;
  height: 9%;
  overflow: hidden;
}
.frame-30 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  /* width: 364px; */
  width: 100%;
  position: absolute;
  /* left: calc(50% - 182.11px); */
  bottom: 0;
  padding: 5rem 3rem 2rem 3rem;
  /* padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 5rem; */
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 18.42226934432983%
  );
}
.future-of-work {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 24px;
  font-weight: 700;
  position: relative;
  align-self: stretch;
}
.majority-of-peole-wi {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
  align-self: stretch;
}
.frame-292 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-28 {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.avatar5 {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  position: relative;
  overflow: visible;
  object-fit: cover;
}
._02-may {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 14px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
}
.avatar6 {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  position: relative;
  overflow: visible;
  object-fit: cover;
}
.avatar7 {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  position: relative;
  overflow: visible;
  object-fit: cover;
}
.button-instance5 {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* left: calc(50% - 85px) !important; */
  /* bottom: 80px !important; */
  /* flex-shrink: unset !important; */
}
.frame-35554 {
  flex-shrink: 0;
  width: 85%;
  /* height: 1081px; */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.frame-35551 {
  display: flex;
  flex-direction: column;
  gap: 21px;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  margin-bottom: 4rem;
  margin-top: 4rem;
}
.frame-35550 {
  padding: 0px 40px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;
  position: relative;
}
.vuesax-broken-arrow-up-instance {
  flex-shrink: 0 !important;
  width: 32px !important;
  height: 32px !important;
}
.vuesax-broken-arrow-up-instance2 {
  flex-shrink: 0 !important;
  width: 32px !important;
  height: 32px !important;
  transform-origin: 0 0 !important;
  transform: rotate(0deg) scale(1, -1) !important;
}
.frame-35552 {
  background: #ffffff;
  border-radius: 14px;
  padding: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  box-shadow: 0px 6px 16px 0px rgba(20, 45, 77, 0.1);
  cursor: pointer;
}
.how-does-credence-differentiate-from-traditional-providers {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 24px;
  font-weight: 700;
  position: relative;
  align-self: stretch;
}
.frame-166 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  /* left: 80px; */
  width: 100%;
  margin-top: 40px;
}
.fa-qs {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-ExtraBold", sans-serif;
  font-size: 40px;
  font-weight: 800;
  position: relative;
}
.find-answers-to-common-questions-about-our-services-data-reliability-and-getting-started-with-credence {
  color: #3e4855;
  text-align: left;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
}
.faq-description {
  color: #3e4855;
  text-align: justify;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
  align-self: stretch;
}
.products-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 4rem;
  margin: 4rem 0 4rem 0;
  position: relative;
}

.frame-26 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
}
.frame-2212 {
  background: linear-gradient(
      133deg,
      rgba(38, 187, 194, 0.2) 27.88%,
      rgb(24 207 215 / 0%) 79.91%
    ),
    #3673ae14;
  border-radius: 24px;
  padding: 24px 0px 24px 0px;
  display: flex;
  flex-direction: column;
  gap: 36px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  width: 32%;
  position: relative;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.18),
    -4px -4px 8px 0px rgb(232 232 232);
}
.frame-25 {
  padding: 0px 16px 0px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35588 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35617 {
  background: #26bbc2a8;
  border-radius: 24px;
  padding: 6px 36px 6px 36px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  position: relative;
}
.line-2 {
  align-self: stretch;
  flex-shrink: 0;
  height: 0px;
  position: relative;
  overflow: visible;
}
.comprehensive-business-intelligence-reports {
  color: #11365a;
  text-align: center;
  align-content: center;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 26px;
  font-weight: 700;
  position: relative;
  align-self: stretch;
}
.line-22 {
  border-width: 1px 0 0 0;
  border-style: solid;
  border-image: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(17, 54, 90, 1) 49.50000047683716%,
    rgba(0, 0, 0, 0) 100%
  );
  border-image-slice: 1;
  flex-shrink: 0;
  width: 95%;
  height: 0px;
  position: relative;
}
.frame-35613 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35620 {
  background: #ffffff;
  border-radius: 12px;
  border-style: solid;
  border-color: #11365a;
  border-width: 1px;
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
}
.fresh-data-everytime {
  color: #e4004c;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 24px;
  line-height: 180%;
  font-weight: 600;
  position: relative;
  flex: 1;
}
.frame-35595 {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  min-height: 202px;
}
.frame-35590 {
  padding: 0px 0px 0px 8px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.cross-border-iq-score-summary {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
  align-self: stretch;
  flex-shrink: 0;
  white-space: normal;
  word-wrap: break-word;
}
.bulk-credit-purchase {
  border-radius: 13px;
  border: 2px solid #e4004c;
  background: #fff;
  padding: 14px 22px;
  width: 95%;
  color: #e4004c;
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.24px;
}
.subscription-purchase {
  border-radius: 13px;
  background: #e4004c;
  padding: 16px 24px;
  width: 95%;
  color: #fff;
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.24px;
  cursor: pointer;
  box-shadow: 4px 4px 8px 0px rgb(0 0 0 / 20%);
}
.line-23 {
  align-self: stretch;
  flex-shrink: 0;
  height: 0px;
  position: relative;
  overflow: visible;
}
.frame-356132 {
  background: #ffffff;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35621 {
  border-radius: 12px;
  border-style: solid;
  border-color: #11365a;
  border-width: 1px;
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
}
.recomemded-label {
  color: white;
  font-weight: 600;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  background: #11365a;
  padding: 5px 20px;
  position: absolute;
  top: -10px;
  align-self: center;
  border-radius: 6px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.18),
    -4px -4px 8px 0px rgb(232 232 232);
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  background-color: #11365abd;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.18);
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  z-index: 1000;
}
.scroll-to-top:hover {
  background-color: #11365a;
}
.scroll-to-top.show {
  display: block;
  gap: 20px;
}

/* Media query */
@media only screen and (max-width: 767px) {
  .frame-92 {
    top: 50px;
    gap: 12px;
  }
  .innovative {
    font-size: 16px;
  }
  .unleashing-the-power-of-real-time-data span {
    font-size: 20px;
    font-weight: 500;
  }
  .frame-22 {
    flex-shrink: 0;
    width: 85%;
    height: 900px;
  }
  .at-credence-business-information-solutions-we-provide-fresh-and-real-time-business-intelligence-on-international-trade-counterparts-with-access-to-over-300-million-counterparts-and-1-billion-shipment-records-we-help-our-customers-make-informed-decisions {
    line-height: 180%;
    width: 100%;
    font-size: 12px;
  }
  .frame-13 {
    gap: 28px;
    top: 280px;
    flex-direction: column;
    padding: 0;
  }
  .frame-10 {
    gap: 15px;
  }
  .image-9 {
    width: 35px;
    height: 35px;
  }
  .focus-on-cross-border-trade-and-risk-assessment {
    width: auto;
    line-height: 24px;
    min-height: auto !important;
    align-self: auto;
  }
  .focus-on-cross-border-trade-and-risk-assessment-span,
  .focus-on-cross-border-trade-and-risk-assessment-span2,
  .focus-on-cross-border-trade-and-risk-assessment-span3 {
    font-size: 16px;
    line-height: 24px;
  }
  .we-specialize-in-analyzing-cross-border-trade-and-transactional-risk-to-ensure-our-customers-have-the-information-they-need-to-mitigate-potential-challenges {
    font-size: 12px;
    line-height: 165%;
  }
  .frame-35574 {
    height: 330px;
  }
  .our-data-coverage {
    font-size: 16px;
    font-weight: 700;
  }
  ._1-bn {
    font-size: 22px;
    font-weight: 600;
  }
  .shipment-records {
    font-size: 14px;
  }
  .frame-35578 {
    top: 35%;
  }
  .partnerships {
    font-size: 14px;
  }
  .affiliations-and-membership-span,
  .affiliations-and-membership-span2,
  .affiliations-and-membership-span3 {
    font-size: 20px;
  }
  .frame-35611 {
    gap: 30px;
  }
  .frame-35583 {
    flex-direction: column;
    gap: 30px;
  }
  .frame-35605 {
    box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.1);
    padding: 0;
  }
  .frame-35586 {
    padding: 0 15%;
  }
  .cyberport-is-hong-kong-s-digital-technology-flagship-managed-by-hong-kong-cyberport-management-company-limited-wholly-owned-by-the-hong-kong-sar-government-interlinkages-limited-is-a-part-of-cyberport-incubation-programme-run-by-cyberport {
    font-size: 12px;
  }
  .fresh-report-delivery-time-globally-span,
  .fresh-report-delivery-time-globally-span2 {
    font-size: 18px;
  }
  .frame-212 {
    padding: 30px 0;
  }
  .discover-real-time-business-intelligence-span,
  .discover-real-time-business-intelligence-span2,
  .discover-real-time-business-intelligence-span3 {
    font-size: 18px;
  }
  .unlock-valuable-insights-and-make-informed-decisions-with-credence-s-business-intelligence-solutions {
    font-size: 12px !important;
    text-align: center;
    width: 80%;
  }
  .frame-163 {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .discover-real-time-business-intelligence {
    text-align: center;
    font-size: 25px;
  }
  .products-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }
  .frame-164 {
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .explore-our-wide-range-of-products-for-transactional-and-counterparty-analysis {
    text-align: center;
    font-size: 14px;
  }
  .button-instance3 {
    margin-right: 0 !important;
  }
  .button-instance3 span {
    padding: 8px 15px;
    font-size: 12px;
  }
  .frame-26 {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
  }
  .frame-356172 {
    width: 100%;
    left: 0;
  }
  .frame-2212 {
    width: 100%;
    min-height: auto !important;
  }
  .frame-165 {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 14%;
  }
  .discover {
    font-size: 16px;
  }
  .products {
    font-size: 20px;
  }
  .products-wrapper div:nth-child(2) {
    font-size: 12px;
  }
  .frame-25 {
    gap: 15px;
  }
  .explore-our-wide-range-of-products-for-transactional-and-counterparty-analysis2 {
    width: auto;
    font-size: 12px;
    text-align: center;
  }
  .testimonials {
    font-size: 18px;
  }
  .frame-266 {
    height: auto;
  }
  .frame-264 {
    top: 0;
    margin-top: 3rem;
    width: 100%;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .frame-261 {
    width: 85%;
    padding: 5% 7% 5% 7%;
  }
  .frame-262 {
    width: 85%;
    padding: 5% 7% 5% 7%;
  }
  .frame-260 svg {
    width: 20px !important;
    height: 20px !important;
  }
  .amet {
    font-size: 12px;
    min-height: auto;
  }
  .frame-263 {
    width: auto;
  }
  .frame-29 {
    width: auto;
  }
  .avatar {
    height: 25px;
    width: 25px;
  }
  .lina-hicks {
    font-size: 14px;
  }
  .verified-writer {
    font-size: 10px;
  }
  .frame-33 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
  }
  .latest {
    font-size: 16px;
  }
  .recent-articles-span,
  .recent-articles-span2 {
    font-size: 28px;
  }
  .here-s-what-we-ve-be {
    font-size: 14px;
  }
  .frame-31 {
    flex-direction: column;
    gap: 2rem;
  }
  .item {
    width: 85%;
  }
  .report-as-a-service {
    font-size: 12px;
  }
  .frame-35620 {
    padding: 5px 10px;
  }
  .comprehensive-business-intelligence-reports,
  .fresh-data-everytime {
    font-size: 16px;
  }
  .cross-border-iq-score-summary {
    font-size: 12px;
  }
  .bulk-credit-purchase {
    border: 1.5px solid #e4004c;
    padding: 10px 11px;
    font-size: 16px;
  }
  .subscription-purchase {
    padding: 12px 12px;
    font-size: 16px;
  }
  .frame-356172 {
    bottom: -4%;
  }
  .frame-356172 svg {
    height: 22px;
  }
  .we-recommend-fresh-data-everytime {
    line-height: 130%;
  }
  .we-recommend-fresh-data-everytime-span,
  .we-recommend-fresh-data-everytime-span2 {
    font-size: 12px;
  }
  .fa-qs {
    font-size: 18px;
  }
  .find-answers-to-common-questions-about-our-services-data-reliability-and-getting-started-with-credence {
    font-size: 12px;
  }
  .button-instance5 span {
    padding: 7px 11px !important;
    font-size: 14px !important;
  }
  .frame-35551 {
    width: 100%;
  }
  .frame-35552 {
    padding: 7%;
  }
  .how-does-credence-differentiate-from-traditional-providers {
    font-size: 14px;
  }
  .faq-description {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .frame-22 {
    height: auto;
  }
  .frame-92 {
    top: 0;
    margin-top: 10%;
    gap: 12px;
    position: relative;
  }
  .innovative {
    font-size: 22px;
  }
  .unleashing-the-power-of-real-time-data {
    margin-top: 5%;
  }
  .unleashing-the-power-of-real-time-data span {
    font-size: 34px;
    font-weight: 500;
    margin-top: 10%;
  }
  .at-credence-business-information-solutions-we-provide-fresh-and-real-time-business-intelligence-on-international-trade-counterparts-with-access-to-over-300-million-counterparts-and-1-billion-shipment-records-we-help-our-customers-make-informed-decisions {
    line-height: 180%;
    width: 80%;
    font-size: 16px;
  }
  .frame-13 {
    flex-direction: column;
    top: 0;
    margin: 10% 0;
  }
  .frame-10 {
    gap: 15px;
  }
  .image-9 {
    width: 35px;
    height: 35px;
  }
  .focus-on-cross-border-trade-and-risk-assessment {
    width: auto;
    line-height: 24px;
    min-height: auto !important;
    align-self: auto;
  }
  .focus-on-cross-border-trade-and-risk-assessment-span,
  .focus-on-cross-border-trade-and-risk-assessment-span2,
  .focus-on-cross-border-trade-and-risk-assessment-span3 {
    font-size: 22px;
    line-height: 24px;
  }
  .we-specialize-in-analyzing-cross-border-trade-and-transactional-risk-to-ensure-our-customers-have-the-information-they-need-to-mitigate-potential-challenges {
    font-size: 16px;
    line-height: 165%;
    text-align: center;
  }
  .frame-35574 {
    height: 250px;
  }
  .our-data-coverage {
    font-size: 28px;
    font-weight: 700;
    top: 16%;
  }
  ._1-bn {
    font-size: 28px;
    font-weight: 600;
  }
  .shipment-records {
    font-size: 16px;
  }
  .frame-35578 {
    top: 35%;
  }
  .partnerships {
    font-size: 20px;
  }
  .affiliations-and-membership-span,
  .affiliations-and-membership-span2,
  .affiliations-and-membership-span3 {
    font-size: 32px;
  }
  .frame-35583 {
    flex-direction: column;
    gap: 30px;
  }
  .frame-35605 {
    box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.1);
  }
  .image-12,
  .image-13 {
    width: 30%;
  }
  .cyberport-is-hong-kong-s-digital-technology-flagship-managed-by-hong-kong-cyberport-management-company-limited-wholly-owned-by-the-hong-kong-sar-government-interlinkages-limited-is-a-part-of-cyberport-incubation-programme-run-by-cyberport {
    font-size: 16px;
    text-align: center;
  }
  .fresh-report-delivery-time-globally-span,
  .fresh-report-delivery-time-globally-span2 {
    font-size: 28px;
  }
  .frame-212 {
    padding: 30px 0;
  }
  .discover-real-time-business-intelligence-span,
  .discover-real-time-business-intelligence-span2,
  .discover-real-time-business-intelligence-span3 {
    font-size: 28px;
  }
  .unlock-valuable-insights-and-make-informed-decisions-with-credence-s-business-intelligence-solutions {
    font-size: 16px !important;
    text-align: center;
  }
  .frame-163 {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .discover-real-time-business-intelligence {
    text-align: center;
  }
  .products-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }
  .frame-164 {
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .explore-our-wide-range-of-products-for-transactional-and-counterparty-analysis {
    text-align: center;
    font-size: 16px;
  }
  .button-instance3 {
    margin-right: 0 !important;
  }
  .button-instance3 span {
    padding: 8px 15px;
    font-size: 12px;
  }
  .frame-26 {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4rem;
  }
  .frame-356172 {
    width: 100%;
    left: 0;
  }
  .frame-2212 {
    width: 100%;
  }
  .frame-165 {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 0%;
  }
  .explore-our-wide-range-of-products-for-transactional-and-counterparty-analysis2 {
    width: auto;
    font-size: 14px;
    text-align: center;
  }
  .testimonials {
    font-size: 28px;
  }
  .frame-266 {
    height: auto;
  }
  .frame-264 {
    top: 0;
    margin-top: 3rem;
    width: 100%;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .frame-261 {
    width: 85%;
    padding: 5% 7% 5% 7%;
  }
  .frame-262 {
    width: 85%;
    padding: 5% 7% 5% 7%;
  }
  .frame-260 svg {
    width: 20px !important;
    height: 20px !important;
  }
  .frame-32 {
    margin-top: 5%;
  }
  .amet {
    font-size: 14px;
    min-height: auto;
  }
  .frame-263 {
    width: auto;
  }
  .frame-29 {
    width: auto;
  }
  .avatar {
    height: 25px;
    width: 25px;
  }
  .lina-hicks {
    font-size: 14px;
  }
  .verified-writer {
    font-size: 10px;
  }
  .frame-33 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
  }
  .latest {
    font-size: 16px;
  }
  .recent-articles-span,
  .recent-articles-span2 {
    font-size: 28px;
  }
  .here-s-what-we-ve-be {
    font-size: 14px;
  }
  .frame-31 {
    flex-direction: column;
    gap: 3rem;
  }
  .item {
    width: 100%;
  }
  .report-as-a-service {
    font-size: 12px;
  }
  .frame-35620 {
    padding: 5px 10px;
  }
  .comprehensive-business-intelligence-reports,
  .fresh-data-everytime {
    font-size: 16px;
  }
  .cross-border-iq-score-summary {
    font-size: 12px;
  }
  .bulk-credit-purchase {
    border: 1.5px solid #e4004c;
    padding: 10px 11px;
    font-size: 16px;
  }
  .subscription-purchase {
    padding: 12px 12px;
    font-size: 16px;
  }
  .frame-356172 {
    bottom: -4%;
  }
  .frame-356172 svg {
    height: 22px;
  }
  .we-recommend-fresh-data-everytime {
    line-height: 130%;
  }
  .we-recommend-fresh-data-everytime-span,
  .we-recommend-fresh-data-everytime-span2 {
    font-size: 12px;
  }
  .fa-qs {
    font-size: 32px;
  }
  .find-answers-to-common-questions-about-our-services-data-reliability-and-getting-started-with-credence {
    font-size: 18px;
  }
  .button-instance5 span {
    padding: 10.5px 16.5px !important;
    font-size: 18px !important;
  }
  .frame-35551 {
    width: 100%;
  }
  .frame-35552 {
    padding: 5%;
  }
  .how-does-credence-differentiate-from-traditional-providers {
    font-size: 20px;
  }
  .faq-description {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1023px) and (max-width: 1155px) {
  .report-as-a-service {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1156px) {
  .frame-35586 {
    width: 40%;
  }
}
</style>
