<template>
  <div class="tab-section">
    <div class="intro-section">
      <h2 class="section-title">{{$t("whyChoose-heading")}}</h2>
      <p class="section-description">
        {{$t("whyChoose-subheading")}}
      </p>
    </div>

    <div class="feature-grid">
      <div class="feature-card">
        <img
          loading="lazy"
          :src="this.$root.getCDNlink('assets/Institutions/img1.webp')"
          alt="Pre-Onboarding Due Diligence"
          class="feature-image"
        />
        <h3 class="feature-title">{{$t("reason1-heading")}}</h3>
        <p class="feature-description">
          {{$t("reason1-data")}}        </p>
      </div>

      <div class="feature-card">
        <img
          loading="lazy"
          :src="this.$root.getCDNlink('assets/Institutions/img2.webp')"
          alt="Real-Time Monitoring"
          class="feature-image"
        />
        <h3 class="feature-title">{{$t("reason2-heading")}}</h3>
        <p class="feature-description">
          {{$t("reason2-data")}}
        </p>
      </div>

      <div class="feature-card">
        <img
          loading="lazy"
          :src="this.$root.getCDNlink('assets/Institutions/img3.webp')"
          alt="Actionable Reports"
          class="feature-image"
        />
        <h3 class="feature-title">{{$t("reason3-heading")}}</h3>
        <p class="feature-description">
          {{$t("reason3-data")}}
        </p>
      </div>

      <div class="feature-card">
        <img
          loading="lazy"
          :src="this.$root.getCDNlink('assets/Institutions/img4.webp')"
          alt="Global Coverage"
          class="feature-image"
        />
        <h3 class="feature-title">{{$t("reason4-heading")}}</h3>
        <p class="feature-description">
          {{$t("reason4-data")}}
        </p>
      </div>

      <div class="feature-card last">
        <img
          loading="lazy"
          :src="this.$root.getCDNlink('assets/Institutions/img5.webp')"
          alt="Compliance & Transparency"
          class="feature-image"
        />
        <h3 class="feature-title">{{$t("reason5-heading")}}</h3>
        <p class="feature-description">
          {{$t("reason5-data")}}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.tab-section {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  font-family: Montserrat, sans-serif;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
}

@media (max-width: 991px) {
  .tab-section {
    padding: 20px;
  }
}

.intro-section {
  margin-bottom: 40px;
  width: 90%;
  gap: 16px;
}

.section-title {
  color: #11365a;
  font-size: 40px;
  font-weight: 700;
  line-height: 1;
  margin: 0;
}

.section-description {
  color: #3e4855;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 16px;
  max-width: 861px;
  margin: 0 auto;
}

.feature-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  width: 90%;
}

.feature-card {
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0px 1px 11px rgba(17, 54, 90, 0.2);
  flex: 1;
  min-width: 28%;
  /* max-width: 28%; */
  padding: 36px 24px;
  text-align: center;
  align-self: stretch;
}

.feature-card:hover {
  background-color: #11365a;
  color: #fff;
}

.last {
  max-width: 46%;
}

.feature-image {
  width: 64px;
  height: 64px;
  object-fit: contain;
  margin: 0 auto;
}

.feature-title {
  font-size: 24px;
  font-weight: 700;
  color: inherit;
  margin-top: 16px;
}

.feature-description {
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  color: inherit;
  margin-top: 16px;
}

@media only screen and (max-width: 767px) {
  .intro-section {
    width: 100%;
  }
  .section-title {
    font-size: 24px;
  }
  .section-description {
    font-size: 16px;
    margin-top: 20px;
  }
  .feature-title {
    font-size: 20px;
  }
  .feature-image {
    width: 50px;
    height: 50px;
  }
  .feature-grid {
    width: -webkit-fill-available;
  }
  .feature-card {
    padding: 25px 20px;
    flex: auto;
  }
  .feature-description {
    font-size: 16px;
  }
  .last {
    max-width: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
  .section-title {
    font-size: 36px;
  }
  .section-description {
    font-size: 16px;
  }
  .feature-title {
    font-size: 22px;
  }
  .feature-image {
    width: 50px;
    height: 50px;
  }
  .feature-card {
    padding: 25px 20px;
  }
}
@media only screen and (min-width: 1156px) {
}
</style>
