<template>
  <section class="who-we-serve-section">
    <h2 class="section-title2">{{ $t("whoWeServe-heading") }}</h2>
    <div class="service-grid">
      <div class="institutions-container">
        <div
          v-for="(service, index) in services"
          :key="index"
          class="institution-item"
          :class="{ active: index === selectedIndex }"
          @click="selectService(index, service)"
          tabindex="0"
        >
          <div class="extra-wrapper-1">
            <div v-html="getServiceIcon(service.icon, index)"></div>
            <div class="institution-name">
              <strong>{{ service.title }}</strong>
              <p>{{ service.description }}</p>
            </div>
            <i
              v-if="this.$root.mobile"
              class="fa-solid"
              :class="index === selectedIndex ? 'fa-angle-up' : 'fa-angle-down'"
              :style="{
                color: index === selectedIndex ? 'white' : '#11365a',
                right: '15px',
                position: 'absolute',
              }"
            ></i>
            <div v-if="index === selectedIndex" class="main-progress-bar">
              <div
                class="main-progress"
                :style="{ width: `${mainProgress}%` }"
              ></div>
            </div>
          </div>
          <section
            class="onboarding-container"
            v-if="this.$root.mobile && index === selectedIndex"
          >
            <div class="onboarding-content">
              <div
                class="primary-section"
                v-for="(data, index) in selectedEntry"
                :key="index"
                :class="{ expanded: index === expandedIndex }"
              >
                <div
                  class="section-header"
                  @click="toggleSection(index, $event)"
                >
                  <h2 class="section-title">{{ data.title }}</h2>
                  <i
                    class="fa-solid"
                    :class="
                      index === expandedIndex ? 'fa-angle-up' : 'fa-angle-down'
                    "
                    style="color: #11365a"
                  ></i>
                </div>
                <template v-if="index === expandedIndex">
                  <template v-if="Array.isArray(data.description)">
                    <ul class="ul">
                      <li
                        class="sub-title"
                        v-for="(desc, i) in data.description"
                        :key="i"
                      >
                        <strong v-if="desc.includes(':')"
                          >{{ desc.split(":")[0] }}:</strong
                        >
                        <p>
                          {{
                            desc.includes(":")
                              ? desc.split(":")[1].trim()
                              : desc
                          }}
                        </p>
                      </li>
                    </ul>
                  </template>
                  <p v-else class="feature-description">
                    {{ data.description }}
                  </p>
                </template>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          height: -webkit-fill-available;
        "
      >
        <section
          class="onboarding-container sticky-card"
          v-if="!this.$root.mobile"
        >
          <div class="onboarding-content">
            <div
              class="primary-section"
              v-for="(data, index) in selectedEntry"
              :key="index"
            >
              <div class="section-header" @click="toggleSection(index)">
                <h2 class="section-title">{{ data.title }}</h2>
              </div>
              <template v-if="Array.isArray(data.description)">
                <ul class="ul">
                  <li
                    class="sub-title"
                    v-for="(desc, i) in data.description"
                    :key="i"
                  >
                    <p>
                      <strong v-if="desc.includes(':')"
                        >{{ desc.split(":")[0] }}:</strong
                      >
                      {{
                        desc.includes(":") ? desc.split(":")[1].trim() : desc
                      }}
                    </p>
                  </li>
                </ul>
              </template>
              <p v-else class="feature-description">
                {{ data.description }}
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import { useI18n } from "vue-i18n";
import config from '@/config.js';

export default {
  name: "WhoWeServe",
  components: {},
  data() {
    return {
      selectedIndex: 0,
      selectedEntry: null,
      expandedIndex: 0,
      mainProgress: 0,
      featureProgress: 0,
      intervalId: null,
      isPaused: false,
      services: [],
    };
  },
  setup() {
    const { locale, t } = useI18n();
    return { locale, t };
  },
  watch: {
    "$i18n.locale": {
      immediate: true,
      handler(newLang) {
        this.updateTranslatedProducts(newLang);
      },
    },
  },

  mounted() {
    this.selectedEntry = this.services?.[0]?.features;
    this.startProgress();
    // this.updateTranslatedProducts();
  },
  methods: {
   async updateTranslatedProducts(lang) {
      try {
      const jsonUrl = `${config.jsonStgCdn}Institutions/${lang}.json`;      
      const response = await fetch(jsonUrl);
      const data = await response.json();
      this.services = data.services || [];
    } catch (error) {
      console.error("Error loading JSON:", error);
      this.fallbackEnglish();
    }
    this.updateSelectedEntry();

    },
    async fallbackEnglish(){
      // Fallback to local English JSON
    try {
      const localEN = await import(`../../../../locales/Institutions/en.json`);            
      this.services = localEN.services || [];
    } catch (localError) {
      console.error("Failed to load local English JSON:", localError);
      this.services = [];
    }
    this.updateSelectedEntry();
    },
    updateSelectedEntry() {
      this.selectedEntry = this.services?.[this.selectedIndex]?.features || [];
    },
    selectService(index, data) {
      this.selectedIndex = index;
      this.selectedEntry = data.features;
      this.expandedIndex = 0;
      this.resetProgress();
    },
    getServiceIcon(icon, index) {
      const strokeColor = index === this.selectedIndex ? "#FFFFFF" : "#11365A";
      return icon.replace(/stroke="#[A-Fa-f0-9]+"/g, `stroke="${strokeColor}"`);
    },
    toggleSection(index, event) {
      if (this.$root.mobile && event) {
        event.stopPropagation();
      }
      if (this.expandedIndex === index) {
        this.expandedIndex = 0;
      } else {
        this.expandedIndex = index;
      }
      this.resetProgress(true);
    },
    resetProgress(isFeatureOnly = false) {
      if (!isFeatureOnly) this.mainProgress = 0;
      this.featureProgress = 0;
      clearInterval(this.intervalId);
      this.startProgress();
    },
    startProgress() {
      const featureDuration = 10000;
      clearInterval(this.intervalId);
      this.intervalId = setInterval(() => {
        if (this.isPaused) return;
        const mainDuration = this.selectedEntry
          ? this.selectedEntry.length * featureDuration
          : 0;
        if (mainDuration > 0) {
          this.mainProgress += (100 / mainDuration) * 50;
          if (this.mainProgress >= 100) {
            this.mainProgress = 0;

            this.selectedIndex =
              (this.selectedIndex + 1) % this.services.length;
            this.selectedEntry = this.services[this.selectedIndex].features;

            this.featureProgress = 0;
            this.expandedIndex = 0;
          }
        }
        this.featureProgress += (100 / featureDuration) * 50;
        if (this.featureProgress >= 100) {
          this.featureProgress = 0;
          if (this.selectedEntry) {
            this.expandedIndex =
              (this.expandedIndex + 1) % this.selectedEntry.length;
          }
        }
      }, 50);
    },
    pauseProgress() {
      this.isPaused = true;
    },
    resumeProgress() {
      this.isPaused = false;
    },
  },
};
</script>

<style scoped>
.main-progress-bar {
  background-color: #11365a;
  width: 100%;
  height: 8px;
  position: absolute;
  left: 0;
  bottom: 0;
}
.progress-bar {
  height: 4px;
  background-color: #e0e0e0;
  width: 100%;
  position: relative;
  margin-top: 10px;
}
.progress {
  height: 100%;
  background-color: #11365a;
  width: 0%;
  transition: width 0.05s linear;
}
.main-progress {
  height: 100%;
  background-color: var(--Other-Yellow, #fcd600);
  width: 0%;
  transition: width 0.05s linear;
}
.extra-wrapper-1 {
  display: flex;
  gap: 20px;
  align-items: center;
  width: -webkit-fill-available;
}
.who-we-serve-section {
  background-color: #d0f3f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Montserrat, sans-serif;
  padding: 40px 0;
  width: 100%;
}

.service-grid {
  display: grid;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 1280px;
  width: 90%;
  margin-top: 60px;
  grid-template-columns: repeat(2, 1fr);
}

.institutions-container {
  display: flex;
  /* max-width: 628px; */
  flex-direction: column;
  color: rgba(17, 54, 90, 1);
  justify-content: center;
  justify-content: flex-start;
  gap: 15px;
}

.institution-name {
  font: 20px Montserrat, sans-serif;
  text-align: left;
}

.institution-name p {
  font-size: 16px;
  margin: 0;
  margin-top: 8px;
}

.institution-item {
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  width: -webkit-fill-available;
  align-items: center;
  gap: 24px;
  overflow: hidden;
  padding: 18px 24px;
  position: relative;
  cursor: pointer;
  outline: none !important;
}

.institution-item.active {
  background-color: rgba(17, 54, 90, 1);
  color: #fff;
}

.institution-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 28px;
}

.active-indicator {
  background: var(--Other-Yellow, #fcd600);
  width: -webkit-fill-available;
  height: 8px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.institution-item:first-child {
  margin-top: 0;
}

.institution-item:focus {
  outline: 2px solid #4a90e2;
  outline-offset: 2px;
}

.institution-item:hover {
  background-color: rgb(242, 242, 242);
}

.institution-item.active:hover {
  background-color: rgba(17, 54, 90, 0.9);
}

.onboarding-container {
  border-radius: 8px;
  background: #fff;
  display: flex;
  max-width: 628px;
  flex-direction: column;
  color: rgb(17, 54, 90);
  justify-content: center;
  padding: 24px;
  font: 700 24px/43px Montserrat, sans-serif;
  justify-content: flex-start;
}
.sticky-card {
  position: sticky;
  top: 115px;
}
.onboarding-content {
  display: flex;
  width: -webkit-fill-available;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}

.primary-section {
  border-radius: 8px;
  background-color: rgba(17, 54, 90, 0.1);
  display: flex;
  width: -webkit-fill-available;
  flex-direction: column;
  overflow: hidden;
  font-size: 16px;
  line-height: 29px;
  text-align: left;
}

.primary-section.expanded {
  background-color: rgba(17, 54, 90, 0.2);
}

.fa-angle-up,
.fa-angle-down {
  transition: transform 0.3s;
}

.section-header {
  display: flex;
  width: -webkit-fill-available;
  align-items: center;
  gap: 8px;
  font-size: 24px;
  line-height: 43px;
  flex-wrap: wrap;
  cursor: pointer;
  padding: 16px;
}

.section-title {
  flex: 1;
  margin: auto 0;
  font-size: inherit;
  line-height: inherit;
  text-align: left;
  font-size: 20px;
}

.section-title2 {
  flex: 1;
  margin: auto 0;
  font-size: inherit;
  line-height: inherit;
  font-size: 40px;
}

.section-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: stretch;
  margin: auto 0;
}

.feature-title {
  margin-top: 8px;
  font-size: 16px;
  line-height: 29px;
}

.feature-description {
  font-weight: 400;
  margin-top: 0;
  font-size: 16px;
  line-height: 29px;
  padding: 0 16px;
}

.sub-title p {
  font-weight: 400;
  margin-top: 0;
}

.ul {
  padding: 0 16px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .service-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .institution-item {
    padding: 18px 12px;
  }
  .institution-item.active {
    flex-direction: column;
  }
  .ul {
    background: transparent;
  }
  .section-title2 {
    font-size: 24px;
  }
  .institution-name {
    font-size: 18px;
    margin-right: 18px;
  }
  .institution-name p {
    font-size: 14px;
  }
  .onboarding-container {
    padding: 8px;
  }
  .section-title {
    font-size: 16px;
    line-height: 20px;
  }
  .fa-angle-up,
  .fa-angle-down {
    font-size: 16px;
  }
  .feature-description,
  .sub-title p {
    line-height: 20px;
    font-size: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .sticky-card {
    top: 100px;
  }
}
@media only screen and (min-width: 1156px) {
}
@media only screen and (min-width: 1023px) and (max-width: 1220px) {
  .sticky-card {
    top: 95px;
  }
}
</style>
